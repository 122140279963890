import { Modal, Form, FloatingLabel, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { SpinnerButton } from 'src/components/base/Elements';
import { useModalDialog, ConfirmModalDialog, withModalDialog } from 'src/components/base/ModalDialog';
import { WizardDialog, useWizardDialog } from 'src/components/base/WizardDialog';
import { validatorResolver } from 'src/utils/validator';
import { useEffectState } from 'src/utils/hooks';
import { toastSuccess } from 'src/utils/toast';

import { GroupSelector } from 'src/components/Groups/GroupSelector';
import { useUpdateWorkspaceUser, useDeleteWorkspaceUser, useCurrentWorkspace } from 'src/model/workspaces';

import classes from './UserSettings.module.scss';

const PAGE_SETTINGS = 'SETTINGS';
const PAGE_GROUPS = 'GROUPS';

export const UserSettings = withModalDialog(({ user, onClose }) => {
  const { workspace: { workspace_id = 0 } } = useCurrentWorkspace();
  const deleteUserModal = useModalDialog();

  const [updateWorkspaceUserAction, progressUpdate] = useUpdateWorkspaceUser();
  const [deleteWorkspaceUserAction, progressDelete] = useDeleteWorkspaceUser();

  const progres = progressUpdate || progressDelete;

  const { currentPage, options: { title, nextButton }, nextPage } = useWizardDialog(async function* (page) {
    const settings = yield page(PAGE_SETTINGS, { user }, { title: __('User Information') });
    const groups = yield page(PAGE_GROUPS, { workspace_id, user: user || { user_id: -1 } }, { title: __('Select Trap Groups to share'), nextButton: __('Save Changes') });

    const { error } = await updateWorkspaceUserAction({ ...settings, workspace_id, user_id: user?.user_id, groups });
    if (!error) {
      toastSuccess(!!user ? __('Changes were saved successfully.') : __('The invitation was sent successfully.'));
    }
    onClose({ mutate: true });
  });

  async function handleOnDelete() {
    const text = <>{__('Are you sure you want to delete')} <b>{user.user_name}</b>?</>;
    if (await deleteUserModal.show({ text })) {
      const { error } = await deleteWorkspaceUserAction({ user_id: user.user_id });
      if (!error) {
        toastSuccess(__('The user has been removed from the Trapping Group'));
        onClose({ mutate: true });
      }
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WizardDialog.Page pageKey={PAGE_SETTINGS} component={SettingsPage} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_GROUPS} component={GroupSelectorPage} {...currentPage} />
      </Modal.Body>

      <Modal.Footer>
        <div className={classes.footer}>
          {!!user && <Button disabled={progres} variant="outline-danger" onClick={handleOnDelete}>{__('Delete User')}</Button>}
          <div className={classes.right}>
            <SpinnerButton animated={progres} onClick={nextPage}>{nextButton}</SpinnerButton>
          </div>
        </div>
      </Modal.Footer>

      <ConfirmModalDialog {...deleteUserModal.register()} />
    </>
  )
}, { size: 'lg', contentClassName: classes.root });

const SettingsPage = ({ registerNext, data: { user } }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({ user_name: 'required', user_email: ['required', 'email'], role: 'string' }),
    defaultValues: user,
  });

  registerNext(submit => handleSubmit(submit)());

  return (
    <Form autoComplete="on">
      <Form.Group className="mb-3" controlId="user_name">
        <FloatingLabel label={__('User Name')}>
          <Form.Control type="text" name="user_name" placeholder={__('User Name')}
            {...register('user_name', { required: true })}
            isInvalid={!!errors.user_name}
          />
          <Form.Control.Feedback type="invalid">{__('Name is required')}</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3" controlId="user_email">
        <FloatingLabel label={__('Email')}>
          <Form.Control type="text" name="user_email" placeholder={__('Email')}
            {...register('user_email')}
            isInvalid={!!errors.user_email}
          />
          <Form.Control.Feedback type="invalid">{__('Email is required')}</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3" controlId="role">
        <FloatingLabel label={__('Role')}>
          <Form.Select aria-label="Role" {...register('role')}>
            {config.roles.map(role => <option key={role.role} value={role.role}>{role.title}</option>)}
          </Form.Select>
        </FloatingLabel>
      </Form.Group>
    </Form>
  )
}

const GroupSelectorPage = ({ registerNext, data: { workspace_id, user } }) => {
  const filter = user?.user_id ? { key: 'filter[user_id]', value: user.user_id } : undefined;

  const modal = useModalDialog();
  const [selectedRowIds, setSelectedRowIds] = useEffectState(Object.fromEntries((user?.groups ?? []).map(item => [item, true])), [user]);

  registerNext(async (submit) => {
    const groups = Object.keys(selectedRowIds).map(item => +item);
    if (!user?.user_id && !groups.length && !await modal.show()) { return }

    submit(groups);
  })

  return (
    <>
      <GroupSelector workspace_id={workspace_id} filter={filter} pageSize={15}
        selectedRowIds={selectedRowIds} onSelectedRowsChange={setSelectedRowIds}
      />
      <ConfirmModalDialog size="lg" variant="primary" actionTitle={__('Proceed')}
        title={__('You have not invited this Co-Trapper to any Trap Group!')}
        text={__('They will not see any of your MinkPolice trap alarms, until you give them access by adding them to a Trap Group in the “Groups” menu item. You can either do this now or proceed with the invitation and add the Co-Trappers to your Trap Groups later on. You can set up new Trap Groups in the “Traps & Groups” tab and then add your Co-Trappers to the Trap Groups by clicking on their name in the “Co-Trappers” tab.')}
        {...modal.register()}
      />
    </>
  )
}

import { Col, Row } from 'react-bootstrap';
import { LargeContainer, SimpleHeader } from 'src/components/base/Elements';

const videos = [
  {
    title: __('Get started'),
    id: 'EWpsrh5ulxo',
    description: 'See how to get started with using your TrapSensor device.',
  },
  {
    title: __('Register Administrator'),
    id: 'JoqMn0JvtW4',
    description: 'See how to register an administrator account on trapsensor.com.',
  },
  {
    title: __('Register a new trap'),
    id: 'ZsaKumydlHg',
    description: 'How to register a new trap in your TrapSensor account. This can be done from both the web app and the mobile app.',
  },
  {
    title: __('Group functionality basics'),
    id: 'F2l2vHCvS68',
    description: 'Here we show how to register a technician account in the TrapSensor system, and we explain the basics of the group functionality.',
  },
  {
    title: __('Group functionality elaborated'),
    id: '97BTD0Tfyqc',
    description: 'Here we give an elaborate explanation of the group functionality.',
  },
  {
    title: __('Swap traps'),
    id: '0AF2v3-SCjU',
    description: 'How to swap two traps. If one trap is taken out of service, e.g. to charge the battery, you can easily swap it with another trap without losing history, name, catches, or position of the trap. You can simply swap with another trap and change the serial number to the new trap.',
  },
]

export const Help = () => (
  <LargeContainer className="mt-4 mb-4">
    <SimpleHeader className="mb-4">{__('Help')}</SimpleHeader>
    <Row>
    {
      videos.map(i => (
        <Col key={i.id} md={6}>
          <div className="thumbnail">
            <h5>{i.title}</h5>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${i.id}?controls=1`}
                frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen="allowfullscreen"></iframe>
            </div>
            <p className="m-t-1">{i.description}</p>
          </div>
        </Col>
      ))
    }
    </Row>
  </LargeContainer>
)

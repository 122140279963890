import { observer } from 'mobx-react-lite';
import { useMemo, useCallback } from 'react';
import { Row, Col, Button, ButtonToolbar } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { SearchField, Pane } from 'src/components/base/Elements';
import { UserSettings } from './UserSettings';
import { useFetchLocation } from 'src/utils/hooks';
import { useCurrentWorkspace, useFetchWorkspaceUsers } from 'src/model/workspaces';

import { ITEMS_PER_PAGE } from 'src/constants';

const ROLES = Object.fromEntries(config.roles.map(({ role, title }) => [role.toLowerCase(), title]));

export const Users = observer(() => {
  const { workspace: { workspace_id } } = useCurrentWorkspace();

  const userSettingsDialog = useModalDialog();

  const { pageIndex, sortBy, search, setFetchLocation } = useFetchLocation();
  const { data, mutate } = useFetchWorkspaceUsers({ workspace_id, pageIndex, sortBy, search });

  function handleFetchData({ pageIndex, sortBy }) {
    setFetchLocation({ pageIndex, sortBy });
  }

  function handleOnSearch(value) {
    setFetchLocation({ pageIndex: 0, sortBy, search: value });
  }

  async function handleClickUser(user) {
    const result = await userSettingsDialog.show({ user });
    result?.mutate && mutate();
  }

  const columns = useMemo(
    () => [
      {
        Header: __('Name'),
        accessor: 'user_name',
      },
      {
        Header: __('Email'),
        accessor: 'user_email',
      },
      {
        Header: __('Role'),
        accessor: 'role',
        disableSortBy: true,
        Cell: ({ value }) => ROLES[value] ?? value,
      },
      {
        Header: __('Status'),
        accessor: 'is_confirmed',
        disableSortBy: true,
        Cell: ({ value }) => value ? __('Confirmed') : __('Pending'),
      },
    ],
    []
  );

  const getRowProps = useCallback(({ original }) => (
    {
      onClick: () => handleClickUser(original),
      className: 'cursor-pointer',
    }
  ), []);

  return (
    <Row>
      <Col cx={'auto'}>

        <ButtonToolbar className="justify-content-between mb-4">
          <SearchField style={{ width: 350 }} value={search} onSearch={handleOnSearch} placeholder={__('Search (name, email)')} />
          <Button onClick={() => handleClickUser()}>{__('Invite User')}</Button>
        </ButtonToolbar>

        <Pane className="mb-3">{__('Below is a list of fellow trappers who you can invite to see or edit your trap groups.')}</Pane>

        <Table columns={columns} data={data.items} getRowProps={getRowProps}
          pageIndex={pageIndex} pageCount={data.pageCount} pageSize={ITEMS_PER_PAGE} sortBy={sortBy}
          fetchData={handleFetchData} a
        />
      </Col>
      <UserSettings {...userSettingsDialog.register()} />
    </Row>
  )
})
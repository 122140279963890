import { useMemo, useCallback } from 'react';
import cls from 'classnames';
import { useTable, usePagination, useSortBy, useRowSelect } from 'react-table';
import { Pagination } from 'react-bootstrap';
import { useUpdateEffect } from 'src/utils/hooks';
import classes from './Table.module.scss';

const defaultPropGetter = () => ({});

export const Table = ({ columns, data, fetchData, onSelectedRowsChange, tableOptions, ...props }) => {
  const table = useTable({
    columns, data,
    initialState: { pageIndex: props.pageIndex, pageSize: props.pageSize, sortBy: [props.sortBy ?? {}] },
    manualPagination: true,
    manualSortBy: true, disableMultiSort: true,
    pageCount: props.pageCount,
    ...tableOptions,
    useControlledState: (state) => useMemo(() => ({
      ...state, selectedRowIds: props.selectedRowIds ?? {}
    }), [state, props.selectedRowIds]),
  }, useSortBy, usePagination, useRowSelect);

  const { getColumnProps = defaultPropGetter, getCellProps = defaultPropGetter, getRowProps = defaultPropGetter, selectRow } = props;

  const { pageIndex, pageSize, sortBy: sortByArray = [], selectedRowIds } = table.state;
  const sortBy = sortByArray.length ? sortByArray[0] : {};

  const getSelectedRowProps = useCallback((row) => {
    return !onSelectedRowsChange ? {} : {
      onClick: () => {
        row.toggleRowSelected();
        if (!row.isSelected) { selectedRowIds[row.id] = selectRow ? selectRow(row.original) : true }
        else { delete selectedRowIds[row.id] }

        onSelectedRowsChange && onSelectedRowsChange({ ...selectedRowIds });
      }
    }
  }, [onSelectedRowsChange, selectedRowIds]);

  useUpdateEffect(() => { props.pageIndex === 0 && pageIndex !== 0 && table.gotoPage(0) }, [props.pageIndex]);
  useUpdateEffect(() => { fetchData({ pageIndex, pageSize, sortBy }) }, [pageIndex, pageSize, sortBy.id, sortBy.desc]);

  return (
    <div className={classes.root}>

      <table {...table.getTableProps()} className="table table-hover">
        <thead>
          {table.headerGroups.map(headerGroup => {
            const { key, ...props } = headerGroup.getHeaderGroupProps();
            return (
              <tr key={key} {...props}>
                {headerGroup.headers.map(column => {
                  const { key, ...props } = column.getHeaderProps([
                    { className: column.headerClassName, style: column.headerStyle },
                    column.getSortByToggleProps({ title: undefined }),
                  ]);
                  return (
                    <th key={key} {...props} >
                      {column.render('Header')}
                      {column.isSorted && <i className={cls('bi', { 'bi-caret-down-fill': column.isSortedDesc, 'bi-caret-up-fill': !column.isSortedDesc })}></i>}
                    </th>
                  )
                })}
              </tr>
            )
          })}
        </thead>

        <tbody {...table.getTableBodyProps()}>
          {table.page.map(row => {
            table.prepareRow(row);
            const { key, ...props } = row.getRowProps([getRowProps(row), getSelectedRowProps(row)]);
            return (
              <tr key={key} {...props}>
                {row.cells.map(cell => {
                  const { key, ...props } = cell.getCellProps([
                    { className: cell.column.className, style: cell.column.style },
                    getColumnProps(cell.column), getCellProps(cell)
                  ]);
                  return (
                    <td key={key} {...props}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>

      <TablePagination {...table} pageIndex={props.pageIndex} />
    </div>
  )
}

const TablePagination = ({ pageIndex, pageCount, gotoPage, canNextPage, canPreviousPage, previousPage, nextPage }) => (
  <>
    {pageCount > 1 &&
      <Pagination>
        <Pagination.First disabled={pageIndex === 0} onClick={() => gotoPage(0)} />
        <Pagination.Prev disabled={!canPreviousPage} onClick={previousPage} />

        {Array(pageCount).fill(1).map((_, index) => <Pagination.Item key={index} active={pageIndex === index} onClick={() => gotoPage(index)}>{index + 1}</Pagination.Item>)}

        <Pagination.Next disabled={!canNextPage} onClick={nextPage} />
        <Pagination.Last disabled={(pageIndex + 1) === pageCount} onClick={() => gotoPage(pageCount - 1)} />
      </Pagination >
    }
  </>
)

import { useEffect, useState } from 'react';
import { signIn, signUp, resetPassword, changePassword, resendUserActivation, confirmUser, confirmUserEmail, loginAs, updateUser, deleteUserAccount } from 'src/api';
import { useSWRConfig } from 'swr';
import { useAction } from 'src/hooks/action';
import { reflectAction } from 'src/utils/helpers';
import { useSystemStore } from 'src/stores';

export function useSignIn() {
  const systemStore = useSystemStore();
  return useAction(async (data) => {
    const { token } = await signIn(data);
    await systemStore.initilize(token);
  });
}

export function useSignOut() {
  const systemStore = useSystemStore();
  const { cache } = useSWRConfig();

  return useAction(() => {
    systemStore.clean();
    cache.clear();
  });
}

export function useSignUp() {
  return useAction(data => signUp(data));
}

export function useResetPassword() {
  return useAction(data => resetPassword(data));
}

export function useResendUserActivation() {
  return useAction(data => resendUserActivation(data));
}

export function useConfirmUser() {
  const systemStore = useSystemStore();

  return useAction(async (data) => {
    const { token } = await confirmUser(data);
    await systemStore.initilize(token);
  });
}

export function useChangePassword() {
  return useAction(data => changePassword(data));
}

export function useActivateUser(key, pathname) {
  const [done, setDone] = useState(false);
  const systemStore = useSystemStore();

  useEffect(() => {
    key && reflectAction(async () => {
      const actions = { '/activate': confirmUser, '/activate-email': confirmUserEmail, '/loginas': loginAs };
      const { token } = await actions[pathname.toLowerCase()]({ key });
      token && await systemStore.initilize(token);
    }).finally(() => setDone(true));
  }, [key, pathname]);

  return done;
}

export function useFetchUser() {
  const { user } = useSystemStore();
  return user;
}

export function useUpdateUser() {
  const systemStore = useSystemStore();
  return useAction(async (data) => {
    const { token, text } = await updateUser(data, systemStore.token);

    token && await systemStore.initilize(token);
    if (!!data.user_name) { systemStore.user.user_name = data.user_name; }
    if (!token && !!data.email) { systemStore.clean() }

    return { text };
  });
}

export function useDeleteAccount() {
  const { token } = useSystemStore();
  return useAction(data => deleteUserAccount(data, token));
}

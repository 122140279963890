import qs from 'qs';
import { TRAP_MODULE } from 'src/constants';
import { toastError } from 'src/utils/toast';

export function formatBearer(token) {
  return `Bearer ${token}`;
}

export function parseQueryString(query) {
  return qs.parse(query, { ignoreQueryPrefix: true });
}

export function tryAction(action, callback) {
  return action
    .then(result => {
      callback && callback({ success: result || true });
      return result;
    }).catch(error => {
      callback && callback({ fail: error });
      toastError(error.text || error.message);
      return error;
    })
}


export async function reflectAction(action) {
  try {
    const data = await action();
    return { data };
  }
  catch (error) {
    toastError(error.text || error.message);
    return { error };
  }
}

export function runAction(action) {
  return tryAction(action());
}

export function toBoolean(string) {
  switch (string.toString().toLowerCase().trim()) {
    case "true": case "yes": case "1": return true;
    case "false": case "no": case "0": case null: return false;
    default: return Boolean(string);
  }
}

export function isTrapModule(type) {
  const res = config.trap.types.find(i => i.type === type);
  return res && res.kind === TRAP_MODULE;
}

export function isValidPosition({ lat, lng } = {}) {
  return lat !== '' && lng !== '' && lat !== null && lng !== null && !isNaN(lat) && !isNaN(lng);
}

export async function ensureGoogleMap() {
  const check = () => new Promise(resolve => setTimeout(() => resolve()));
  const isGoogleMapAvailable = () => {
    return typeof google === 'object' && google.maps;
  }

  for (let i = 0; i < 50; ++i) {
    if (isGoogleMapAvailable()) { break }
    await check();
  }

  if (!isGoogleMapAvailable()) {
    throw new Error('Failed to load google maps');
  }
}

export function substitute(text, value) {
  const replacer = (values) => (_, text) => values[text];
  return text.replace(/\{([^}]+)\}/g, replacer(value));
}

/**
 * isInteractOrOutsideElement: returns true is element has date-interact of click outside
 */
export function isInteractOrOutsideElement(event) {
  let current = event.target;
  while (current) {
    if (current.dataset?.interact) { return true }
    if (current === event.currentTarget) { break }
    current = current.parentNode;
  }
  return !current;
}

export async function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

import { useLocation, Link, Switch, Route, Redirect } from 'react-router-dom';
import { Tabs } from 'src/components/base/Elements';
import { uniqueId } from 'src/utils/utils';

export const TabPages = ({ items }) => {
  const { pathname } = useLocation();
  const active = (to) => pathname.startsWith(to);

  return (
    <div className="pt-5 pb-5">
      <Tabs className="pb-5" items={items.map(({ title, to }) => ({ title, to, as: Link, active: active(to), }))} />

      <Switch>
        {items.map(({ component, to }) => (
          <Route key={uniqueId()} path={to} component={component} />
        ))}
        {items.length && <Redirect to={items[0].to} />}
      </Switch>
    </div>
  )
}

import cls from 'classnames';
import { Row, Col } from 'react-bootstrap';
import { LargeContainer } from 'src/components/base/Elements';
import { LanguageSelector } from 'src/components/base/LanguageSelector';
import { ExternalLink, Copyright } from 'src/components/base/Elements';

import classes from './Footer.module.scss';

export const Footer = () => (
  <LargeContainer className={classes.root}>
    <Row>
      <Col md={3} sm={6} xs={12} className={cls(classes.column1, 'mt-4')}>
        <div className={classes.column1Logo} />
      </Col>

      <Col md={2} sm={6} xs={12} className={cls(classes.column2, 'mt-4')}>
        <h6>{__('Explore our website')}</h6>
        <div className="d-flex flex-column flex-nowrap gap-row-1 mt-1">
          <ExternalLink title={__('Home')} href={'https://www.trapsensor.com/{lang}/'} />
          <ExternalLink title={__('Product Information')} href={'https://www.trapsensor.com/{lang}/products/'} />
          <ExternalLink title={__('How it works')} href={'https://www.trapsensor.com/{lang}/how-it-works/'} />
          <ExternalLink title={__('Testimonials')} href={'https://www.trapsensor.com/{lang}/#reviews'} />
        </div>
      </Col>

      <Col md={3} sm={6} xs={12} className={cls(classes.column3, 'mt-4')}>
        <h6>{__('Need help with your TrapSensor?')}</h6>
        <div className="d-flex flex-column flex-nowrap gap-row-1 mt-1">
          <ExternalLink title={__('FAQ')} href={'https://www.trapsensor.com/{lang}/contact-us/'} />
          <span className={classes.footerText}>{__('write to')} <ExternalLink href="mailto:support@trapsensor.com" title={'support@trapsensor.com'} /></span>
          <span className={classes.footerText}>{__('give us a call at')} <ExternalLink href="tel:+45 2195 4858" title={'+45 2195 4858'} /></span>
        </div>
      </Col>

      <Col md={4} sm={6} xs={12} className={cls(classes.column4, 'mt-4')}>
        <a href="http://alerthouse.dk/" target="_blank"><div className={classes.column4Logo} /></a>
        <h6>{__('TrapSensor is a product by Alert House ApS')}</h6>
      </Col>
    </Row>

    <Row className={cls(classes.footer, 'mb-1')}>
      <Col className="d-flex flex-row flex-wrap justify-content-start gap-row-3 mt-3 mb-3">
        <Copyright className={cls(classes.footerText, 'pe-3')} />

        <div className="d-flex flex-grow-1 flex-row gap-col-3">
          <ExternalLink title={__('Privacy Policy')} href={'https://www.trapsensor.com/{lang}/policy/'} />
          <ExternalLink title={__('Imprint')} href={'https://www.trapsensor.com/{lang}/imprint/'} />
          <ExternalLink title={__('Disclaimer')} href={'https://www.trapsensor.com/{lang}/disclaimer/'} />
          <ExternalLink title={__('Terms and Conditions')} href={'https://www.trapsensor.com/{lang}/terms/'} />
        </div>

        <LanguageSelector />
      </Col>
    </Row>

  </LargeContainer>
)

import { useMemo } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { WorkspaceBanner } from 'src/components/base/WorkspaceBanner';
import { LargeContainer } from 'src/components/base/Elements';
import { TabPages } from 'src/components/base/TabPages';

import { Users } from './Users';
import { UserSettings } from './UserSettings';
import { Workspaces } from './Workspaces';

export default () => {
  const { pathname } = useLocation();

  return (
    <div>
      <WorkspaceBanner />

      <Switch>
        <Route path={`/users/user/:id`} component={UserSettings} />
        <Route path={pathname} component={HomePage} />
      </Switch>
    </div>
  )
}

const HomePage = () => {
  const items = useMemo(() => [
    { title: __('Following me'), to: '/users/users', component: Users },
    { title: __(`I'm following`), to: '/users/workspaces', component: Workspaces },
  ], []);

  return (
    <Switch>
      <LargeContainer>
        <TabPages items={items} />
      </LargeContainer>
    </Switch>
  )
}
import { add, format as _format, parseISO, formatISO, subDays, addDays } from 'date-fns';
import locale from 'date-fns/locale/LANG';

export function format(date, format) {
  return _format(date, format, { locale });
}

export function formatFromISO(str, fmt) {
  if (str === undefined || str === null) {
    return '-';
  }
  const date = parseISO(str);
  return date != 'Invalid Date' ? format(date, fmt) : '-';
}

export { add, parseISO, formatISO, addDays, subDays };
import { LargeContainer, SimpleHeader } from 'src/components/base/Elements';

const messages = {
  404: __('Page was not found'),
}

export const ErrorPage = ({ code, message }) => {
  const text = code ? messages[code] || __('Unknown error') : message;
  return (
    <LargeContainer>
      <SimpleHeader className='mt-4'>{text}</SimpleHeader>
    </LargeContainer>
  )
}

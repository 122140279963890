import { useMemo } from 'react';
import { fetchWorkspaceUsers, updateWorkspaceUser, inviteWorkspaceUser, deleteWorkspaceUser, confirtmWorkspace, leaveWorkspace, updateWorkspace } from 'src/api';
import { useSystemStore } from 'src/stores';
import { useAction } from 'src/hooks/action';
import { useSWR } from 'src/utils/swr';

import { ITEMS_PER_PAGE } from 'src/constants';

export function useFetchWorkspaces() {
  const { workspaces } = useSystemStore();
  return workspaces;
}

export function useWorkspace() {
  const { workspace } = useSystemStore();
  return workspace;
}

export function useUpdateWorkspace() {
  const systemStore = useSystemStore();
  return useAction(data => systemStore.updateWorkspace(data));
}

/**
 * @param {number} override
 */
export function useCurrentWorkspace(override) {
  const systemStore = useSystemStore();
  const { user: { email, permissions }, currentWorkspace, workspaces } = systemStore;

  const workspace_id = override ?? currentWorkspace;
  const workspace = workspaces.find(item => item.workspace_id === workspace_id);

  return {
    workspace: {
      workspace_id,
      owner_email: workspace?.owner_email ?? email,
      permissions: workspace?.permissions ?? permissions,
    },
    setCurrentWorkspace: id => systemStore.setCurrentWorkspace(id)
  }
}

export function useFetchWorkspaceUsers({ workspace_id, pageIndex, pageSize = ITEMS_PER_PAGE, sortBy, search }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [], pageCount: 0 }), []);

  return useSWR(['/users', workspace_id, pageIndex, pageSize, sortBy.id, sortBy.desc, search],
    () => fetchWorkspaceUsers({ workspace_id, pageIndex, pageSize, sortBy, search }, token),
    { defaultValue });
}

export function useFetchWorkspaceUser({ workspace_id, user_id }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ([]), []);

  const fetcher = async () => {
    const { items } = await fetchWorkspaceUsers({ workspace_id, user_id }, token);
    return items[0] ?? defaultValue;
  }

  return useSWR(user_id !== undefined ? ['/user', workspace_id, user_id] : null, fetcher, { defaultValue });
}

export function useUpdateWorkspaceUser(mutate) {
  const { token } = useSystemStore();
  return useAction(data => data.user_id ? updateWorkspaceUser(data, token) : inviteWorkspaceUser(data, token), mutate);
}

export function useDeleteWorkspaceUser(mutate) {
  const { token } = useSystemStore();
  return useAction(data => deleteWorkspaceUser(data, token), mutate);
}

export function useConfirmWorkspace() {
  const systemStore = useSystemStore();
  return useAction(async (data) => {
    await confirtmWorkspace(data, systemStore.token);
    await systemStore.refreshWorkspaces();
  });
}

export function useLeaveWorkspace() {
  const systemStore = useSystemStore();
  return useAction(async (data) => {
    await leaveWorkspace(data, systemStore.token);
    await systemStore.refreshWorkspaces();
  });
}

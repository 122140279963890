import superagent from 'superagent';
import { strings } from './strings';
import { systemStore } from 'src/stores';
import { toastError } from 'src/utils/toast';

function request(method, uri, data, token) {
  const task = superagent(method, `${config.api.serverUrl}${uri}`)
    .set('Content-Type', 'application/json')
    .set('Accept-Language', config.lang)
    .set('DomainID', config.api.domainId)
    .set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1,private')
    .set('Pragma', 'no-cache');

  if (token) {
    task.set('Authorization', `Bearer ${token}`);
  }

  if (data) {
    method === 'get' ? task.query(data) : task.send(data);
  }

  systemStore.startRequest();

  return task.then(result => {
    systemStore.stopRequest();
    return result.body || result;
  }).catch(error => {
    systemStore.stopRequest();

    error = parseErrorResponse(error);
    if (error.statusCode === 401) {
      toastError(error.text || error.message);
      systemStore.clean();
     }
    throw error;
  })
}

const parseErrorResponse = (error) => {
  const statusCode = error?.response?.statusCode ?? 500;
  const code = error?.response?.body?.error?.code ?? 'unknown_error';
  let text = error?.response?.body?.error?.text ?? strings[code] ?? code;

  if (statusCode === 401 && code === 'unknown_error') {
    text = strings['invalid_credentials'];
  }
  return { statusCode, code, text };
}

// User

export function signIn(data) {
  return request('post', '/auth', data);
}

export function signUp(data) {
  return request('post', '/user', data);
}

export function fetchUser(token) {
  return request('get', '/user', null, token);
}

export function updateUser(data, token) {
  return request('put', '/user', data, token);
}

export function resetPassword(data) {
  return request('post', '/resetPassword', data);
}

export function changePassword(data) {
  return request('post', '/changePassword', data);
}

export function resendUserActivation(data) {
  return request('post', '/newactivationlink', data);
}

export function confirmUser(data) {
  return request('post', '/confirmUser', data);
}

export function deleteUserAccount(data, token) {
  return request('delete', '/user', data, token);
}

export function confirmUserEmail(data) {
  return request('post', '/confirmEmail', data);
}

export function resendEmailConfirmation(data, token) {
  return request('post', '/resendEmailActivationLink', data, token);
}

export function fetchAdvancedSettings(token) {
  return request('get', '/advancedNotifications', null, token);
}

export function saveAdvancedSettings(data, token) {
  return request('put', '/advancedNotifications', data, token);
}

export async function fetchTimeZones(token) {
  const { available_timezones } = await request('get', '/timezone', null, token);
  return Object.keys(available_timezones).map(key => ({ key, title: available_timezones[key] }));
}

export function fetchUserSettings(token) {
  return request('get', '/settings', null, token);
}

export function saveUserSettings(data, token) {
  return request('put', '/settings', data, token);
}

export function loginAs(data) {
  return request('post', '/loginas', data);
}

export function fetchWebhook(token) {
  return request('get', '/webhook', null, token);
}

export function saveWebhook(data, token) {
  return request('put', '/webhook', data, token);
}

// Workspaces

export function fetchWorkspace(data, token) {
  return request('get', '/workspace', data, token);
}

export function updateWorkspace(data, token) {
  return request('put', '/workspace', data, token);
}

export async function fetchWorkspaces(data, token) {
  const { workspaces } = await request('get', '/workspaces', data, token);
  return workspaces;
}

export function confirtmWorkspace(data, token) {
  return request('put', '/workspaces', data, token);
}

export function leaveWorkspace(data, token) {
  return request('delete', '/workspaces', data, token);
}

// Workspace Users

export async function fetchWorkspaceUsers(data, token) {
  const { user_id, workspace_id, pageIndex = 0, pageSize = 0, sortBy = {}, search } = data;
  const params = {
    user_id, workspace_id, offset: pageIndex * pageSize, count: pageSize,
    sort_by: sortBy.id, order_by: sortBy.desc ? 'desc' : 'asc', search,
  };

  const { users, total_count } = await request('get', '/workspaceusers', params, token);
  return { items: users, pageCount: Math.ceil(total_count / pageSize) };
}

export function updateWorkspaceUser(data, token) {
  return request('put', '/workspaceusers', data, token);
}

export function deleteWorkspaceUser(data, token) {
  return request('delete', '/workspaceusers', data, token);
}

export function inviteWorkspaceUser(data, token) {
  return request('post', '/workspaceinvite', data, token);
}

// Groups

export async function fetchGroups(data, token) {
  const { group_id, workspace_id, pageIndex = 0, pageSize = 0, sortBy = {}, search, filter } = data;
  const params = {
    group_id, workspace_id, offset: pageIndex * pageSize, count: pageSize,
    sort_by: sortBy.id, order_by: sortBy.desc ? 'desc' : 'asc', search,
  };
  if (filter) { Object.assign(params, { [filter.key]: filter.value }) }

  const result = await request('get', '/groups', params, token);
  const { groups, total_count, traps_active_count, traps_count, my_traps_active_count, my_traps_count, my_traps_group } = result;

  return {
    items: groups, pageCount: Math.ceil(total_count / pageSize),
    traps_active_count, traps_count, my_traps_active_count, my_traps_count, my_traps_group,
  };
}

export function createGroup(data, token) {
  return request('post', '/groups', data, token);
}

export function updateGroup(data, token) {
  return request('put', '/groups', data, token);
}

export function deleteGroup(data, token) {
  return request('delete', '/groups', data, token);
}

// Traps

export async function fetchTraps(data, token) {
  const { workspace_id, trap_id, group_id, pageIndex = 0, pageSize = 0, sortBy = {}, search, active, filter } = data;
  const params = {
    workspace_id, trap_id, group_id, offset: pageIndex * pageSize, count: pageSize,
    sort_by: sortBy.id, order_by: sortBy.desc ? 'desc' : 'asc', search, is_active: active
  };
  if (filter) { Object.assign(params, { [filter.key]: filter.value }) }

  const { traps, total_count } = await request('get', '/trapsoverview', params, token);
  return { items: traps, pageCount: Math.ceil(total_count / pageSize) };
}

export function createTrap(data, token) {
  return request('post', '/traps', data, token);
}

export function editTraps(data, token) {
  return request('put', '/traps', data, token);
}

export function resetTrapSim(data, token) {
  return request('put', '/resettrapsimcard', data, token);
}

export function deleteTrap(data, token) {
  return request('delete', '/traps', data, token);
}

export function createTrapEvent(data, token) {
  return request('post', '/event', data, token);
}

export function updateTrapEvent(data, token) {
  return request('put', '/event', data, token);
}

export function deleteTrapEvent(data, token) {
  return request('delete', '/event', data, token);
}

export async function fetchHistory(data, token) {
  const { history = [], total_count = 0 } = await request('get', '/history', data, token);
  return { items: history ?? [], total_count, offset: data.offset ?? 0 };
}

export async function fetchStatsAlarms(query, token) {
  const result = await request('get', '/statsAlarms', query, token);
  return Array.isArray(result) ? result : [];
}

export async function fetchMaptraps(data, token) {
  const { traps } = await request('get', '/maptraps', data, token);
  return { items: traps };
}

// Catch

export async function fetchCatches({ pageIndex, sortBy }, token) {
  const { catches = [], total_pages = 0 } = await request('get', '/catches', { page: pageIndex + 1, sort_by: sortBy.id, order_by: sortBy.desc ? 'desc' : 'asc' }, token);
  return { items: catches, pageCount: total_pages };
}

export function createCatch(data, token) {
  return request('post', '/catches', data, token);
}

export function saveCatch(data, token) {
  return request('put', '/catches', data, token);
}

export function deleteCatch(data, token) {
  return request('delete', '/catches', data, token);
}

export async function fetchCatchStats(query, token) {
  const result = await request('get', '/statscatches', query, token);
  return Array.isArray(result) ? result : [];
}

// SIM Cards

//TODO: Refactored!
export async function fetchSimCards({ is_payment_required, pageIndex, pageSize, sortBy }, token) {
  const { sim_cards = [], total_count = 0, payment = {} } = await request('get', '/simcards', {
    is_payment_required, offset: pageIndex * pageSize, count: pageSize,
    sort_by: sortBy.id, order_by: sortBy.desc ? 'desc' : 'asc',
  }, token);
  return { items: sim_cards, pageCount: Math.ceil(total_count / pageSize), payment };
}

export function updateSimCard(data, token) {
  return request('put', '/simcards', data, token);
}

export function simcardsPay(data, token) {
  return request('post', '/simcardspay', data, token);
}

export function smsPay(data, token) {
  return request('post', '/smspay', data, token);
}

export function fetchSMS(token) {
  return request('get', '/sms', {}, token);
}

export function updateSMS(data, token) {
  return request('post', '/sms', data, token);
}

export function deleteSMS(data, token) {
  return request('delete', '/sms', data, token);
}

/**
 * Calculate price for sims.
 * @param {number} data.quantity
 * @param {string} data.country
 * @param {string} data.customer_type
 * @param {string} token - JWT token
*/
export function simcardsCalculate(data, token) {
  return request('post', '/simcardscalculate', data, token);
}

// Invoices

export async function fetchInvoices({ pageIndex, pageSize }, token) {
  const { invoices = [], total_count = 0 } = await request('get', '/invoices', {
    offset: pageIndex * pageSize, pageSize
  }, token);
  return { items: invoices, pageCount: Math.ceil(total_count / pageSize) };
}

export function requestInvoice(query, token) {
  return request('get', '/requestInvoice', query, token);
}
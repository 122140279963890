import cls from 'classnames';
import { observer } from 'mobx-react-lite';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { Navbar, Nav, ProgressBar } from 'react-bootstrap';
import { LargeContainer } from 'src/components/base/Elements';
import { useSystemStore } from 'src/stores';
import { useLastVisitedGroup } from 'src/model/groups';
import { useSignOut } from 'src/model/user';
import { useFormatLocalization } from 'src/utils/hooks';
import classes from './Header.module.scss';

export const Header = observer(() => {
  const systemStore = useSystemStore();
  const group_id = useLastVisitedGroup();
  const [signOutAction] = useSignOut();

  const promoSiteUrl = useFormatLocalization(config.general.promoSite, { lang: config.lang });
  const isAuthorised = systemStore.isAuthorised;
  const email = systemStore.user?.email;

  const { pathname } = useLocation();
  const active = (to) => to.some(name => pathname.startsWith(name));

  const match = useRouteMatch('/traps/:id');
  const homeLink = match?.params?.id == group_id ? '/traps' : `/traps/${group_id}`;
  const progressVisible = systemStore.isRequest ? 'visible' : 'invisible';

  async function handleLogoutClick() {
    await signOutAction();
  }

  return (
    <div className={classes.root}>
      <LargeContainer>

        <Navbar expand="lg" variant="dark" collapseOnSelect className="pt-0 pb-0 align-items-end">
          <Navbar.Brand href={promoSiteUrl}><div className={classes.logo} /></Navbar.Brand>

          <Navbar.Toggle aria-controls="header-nav" />
          <Navbar.Collapse id="header-nav" className="justify-content-between align-items-end">

            {!isAuthorised &&
              <Nav variant="pills">
                <NavItem to="/" title={__('Home')} active={true} />
              </Nav>
            }

            {isAuthorised &&
              <>
                <Nav variant="pills">
                  <NavItem to={homeLink} active={active(['/trap', '/groups'])} title={__('Traps & Groups')} />
                  <NavItem to="/users" active={active(['/users'])} title={__('UsersMenu')} />
                  <NavItem to="/map" active={active(['/map'])} title={__('Trap Map')} />
                  <NavItem to="/catches" active={active(['/catches'])} title={__('Catches')} />
                  <NavItem to="/settings" active={active(['/settings'])} title={__('Settings')} />
                </Nav>

                <Nav>
                  <Nav.Item className="d-none d-lg-flex"><Navbar.Text className={cls('text-truncate mr-2 pb-0', classes.userName)}>{email}</Navbar.Text></Nav.Item>
                  <NavItem to="#" onClick={handleLogoutClick} title={__('Log out')} />
                </Nav>
              </>
            }

          </Navbar.Collapse>
        </Navbar>

      </LargeContainer>

      <ProgressBar className={cls(classes.progress, progressVisible)} animated variant="primary" now={100} />
    </div>
  )
})

const NavItem = ({ to, title, active, onClick }) => (
  <Nav.Item className="d-flex">
    <Nav.Link as={Link} to={to} active={active} onClick={onClick}>{title}</Nav.Link>
  </Nav.Item>
)
import { useState } from 'react';
import { reflectAction } from 'src/utils/helpers';

export function useAction(action, mutate) {
  const [progress, setProgress] = useState(false);

  return [
    async (data) => {
      setProgress(true);
      const result = await reflectAction(() => action(data));
      setProgress(false);

      if (mutate && !result.error) { mutate() }
      return result;
    },
    progress,
  ]
}

export function useAtom() {
  const [progress, setProgress] = useState(false);

  return [
    async (action) => {
      setProgress(true);
      const result = await reflectAction(action);
      setProgress(false);

      return result;
    },
    progress,
  ]
}
import { forwardRef } from 'react';
import { enGB } from 'date-fns/locale';
import { Form, FloatingLabel } from 'react-bootstrap';
import DatePickerBase from 'react-datepicker';
import { useEffectState } from 'src/utils/hooks';

import 'react-datepicker/dist/react-datepicker.css';


export const DatePicker = props => <DatePickerBase locale={enGB} {...props} />

export const DatePickerControl = forwardRef(({ label, value, onChange, ...props }, ref) => {
  const CustomControl = forwardRef((props, ref) => (
    <FloatingLabel label={label}>
      <Form.Control type="text" placeholder={label} {...props} ref={ref} autoComplete="off" />
    </FloatingLabel>
  ));

  return (
    <DatePickerBase ref={ref} customInput={<CustomControl />} selected={value} onChange={onChange} locale={enGB} {...props} />
  )
})

export const DateRangePicker = ({ label, value: { start, end }, onChange }) => {
  const [range, setRange] = useEffectState({ start, end }, [start, end]);

  function handleRangeChange([start, end]) {
    setRange({ start, end });
    if (start && end) { onChange({ start, end }) }
  }

  return (
    <DatePickerControl label={label}
      selectsRange startDate={range.start} endDate={range.end} onChange={handleRangeChange}
    />
  )
}
import LIVR from 'livr';
import isURL from 'validator/es/lib/isURL';
import { isEmpty, isString } from 'src/utils/utils';

import { SERIAL_MIN_LENGTH, PASSWORD_MIN_LENGTH } from 'src/constants';

export function validateSerialNumber(data) {
  return isString(data) && data.trim().length >= SERIAL_MIN_LENGTH;
}

LIVR.Validator.registerAliasedDefaultRule({
  name: 'password',
  rules: { min_length: PASSWORD_MIN_LENGTH },
  error: 'WEAK_PASSWORD'
});

LIVR.Validator.registerDefaultRules({
  phone: function () {
    return function (value, params, outputArr) {
      // We already have "required" rule to check that the value is present
      if (value === undefined || value === null || value === '' || value === '+') {
        outputArr.push('');
        return;
      }
      if (isURL(value)) { return }
      const phone = value.replace(/[(, ), -]/g, '');
      if (/^\+?[0-9]{7,20}$/.test(phone)) {
        outputArr.push(phone);
        return;
      }
      return 'INVALID_PHONE';
    }
  },
  url: function () {
    return function (value, params = { require_protocol: true }, outputArr) {
      // We already have "required" rule to check that the value is present
      if (value === undefined || value === null || value === '') {
        outputArr.push('');
        return;
      }
      if (isURL(value, params)) { return }
      return 'INVALID_URL';
    }
  }
});

LIVR.Validator.registerDefaultRules({
  latitude: function (arg1) {
    return function (value, allValues, outputArr) {
      if (!isEmpty(allValues[arg1]) && isEmpty(value)) {
        return 'INVALID_LATITUDE';
      }
      // We already have "required" rule to check that the value is present
      if (isEmpty(value)) {
        return;
      }
      const latitude = +value;
      if (latitude >= -90 && latitude <= 90) {
        return;
      }
      return 'INVALID_LATITUDE';
    }
  }
});

LIVR.Validator.registerDefaultRules({
  longitude: function (arg1) {
    return function (value, allValues, outputArr) {
      if (!isEmpty(allValues[arg1]) && isEmpty(value)) {
        return 'INVALID_LONGITUDE';
      }
      // We already have "required" rule to check that the value is present
      if (isEmpty(value)) {
        return;
      }
      const longitude = +value;
      if (longitude >= -180 && longitude <= 180) {
        return;
      }
      return 'INVALID_LONGITUDE';
    }
  }
});

LIVR.Validator.registerDefaultRules({
  predicate: function (arg1) {
    return function (value, allValues) {
      return arg1(value, allValues);
    }
  }
});

LIVR.Validator.registerDefaultRules({
  required_if: function (arg1) {
    let queryKey = Object.keys(arg1)[0];
    let queryValue = arg1[queryKey];

    if (!queryValue) {
      throw new Error('LIVR: the target value of the "require_if" rule is missed or incomparable');
    }

    return function (value, allValues, outputArr) {
      if (!isEmpty(value) || !queryKey) { return }

      const valueToCheck = allValues[queryKey];
      if (valueToCheck == queryValue && isEmpty(value)) { return 'REQUIRED' }
    }
  }
});

export const Validator = LIVR.Validator;

export function validatorResolver(schema) {
  const validator = new Validator(schema);

  return (data) => {
    const values = validator.validate(data);
    const errors = !values ? validator.getErrors() : {};

    return { values, errors };
  }
}
import { useState, useEffect } from "react";

export function useCountries() {
  const [countries, setCountries] = useState({ allCountries: [], iso2Lookup: {} });

  useEffect(() => {
    import(/* webpackChunkName: "countries" */ 'country-telephone-data').then(({ allCountries, iso2Lookup }) => setCountries({ allCountries, iso2Lookup }));
  }, []);

  return countries;
}
import { useMemo } from 'react';
import { Col, Row, Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useFormatLocalization } from 'src/utils/hooks';
import { useCreateOrUpdateCatch, useDeleteCatch } from 'src/model/catches';
import { useWorkspace } from 'src/model/workspaces';
import { useModalDialog, withModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { DatePickerControl } from 'src/components/base/DatePicker';
import { LocationDialog } from './LocationDialog';
import { useUpdateEffect } from 'src/utils/hooks';
import { validatorResolver } from 'src/utils/validator';
import { parseISO, formatISO } from 'src/utils/datefns';
import { isValidPosition } from 'src/utils/helpers';
import { OTHER_ANIMAL } from 'src/constants';

export const EventCatchDialog = withModalDialog(({ trap_id, event, mutate, onClose }) => {
  const { animals = [] } = useWorkspace();
  const animalTypes = useMemo(() => [...animals, { type: OTHER_ANIMAL, title: __('Other') }], [animals]);

  const defaultValues = useMemo(() => ({
    ...event,
    animal: !!event?.animal_other ? OTHER_ANIMAL : (event?.animal ?? animalTypes[0].type),
    time: event?.time ? parseISO(event?.time) : new Date(),
  }), [event]);

  const { handleSubmit, register, control, getValues, watch, setValue, formState: { errors } } = useForm({
    resolver: validatorResolver({
      notes: 'string', time: 'required',
      animal: 'required', animal_other: { predicate: (value, { animal }) => (animal === OTHER_ANIMAL && !value) ? 'INVALID_ANIMAL' : '' },
      age: 'required', sex: 'required', bait: 'string',
      latitude: { latitude: 'longitude' }, longitude: { longitude: 'latitude' },
    }),
    defaultValues,
  });

  const [createOrUpdateCatchAction] = useCreateOrUpdateCatch();
  const [deleteCatchAction] = useDeleteCatch();

  const locationModal = useModalDialog();
  const deleteModal = useModalDialog();

  const history_id = event?.history_id;
  const { ages, genders } = config.catch;

  const errorLatitude = useFormatLocalization(__('Invalid value. Should be number in the range {min}–{max}.'), { min: '-90', max: '+90' });
  const errorLongitude = useFormatLocalization(__('Invalid value. Should be number in the range {min}–{max}.'), { min: '-180', max: '+180' });

  const animalType = watch('animal');
  useUpdateEffect(() => { if (animalType !== OTHER_ANIMAL) { setValue('animal_other', '') } }, [animalType]);

  async function handleChangeLocation() {
    const [lat, lng] = getValues(['latitude', 'longitude']);
    const location = isValidPosition({ lat, lng }) && { lat: +lat, lng: +lng };

    const result = await locationModal.show({ title: __('Catch'), location });
    if (result) {
      setValue('latitude', result.lat);
      setValue('longitude', result.lng);
    }
  }

  async function handleDeleteClick() {
    if (await deleteModal.show({ text: __('Do you want to delete this Event?')})) {
      await deleteCatchAction({ history_id });
      mutate();
      onClose();
    }
  }

  async function handleSubmitForm(data) {
    await createOrUpdateCatchAction({ trap_id, history_id, ...data, time: formatISO(data.time) });
    mutate();
    onClose();
  }

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} role="form">
      <Modal.Header closeButton>
        <Modal.Title>{history_id ? __('Edit Catch') : __('Register Catch')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="animal">
              <FloatingLabel label={__('Animal')}>
                <Form.Select aria-label={__('Animal')} {...register('animal')}>
                  {animalTypes.map(({ type, title }) => <option key={type} value={type}>{title}</option>)}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="animal_other">
              <FloatingLabel label={__('Other Animal')}>
                <Form.Control type="text" name="animal_other" placeholder={__('Other Animal')}
                  {...register('animal_other')} disabled={animalType !== OTHER_ANIMAL} isInvalid={!!errors.animal_other}
                />
                <Form.Control.Feedback type="invalid">{__('Other Animal is incorrect')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="age">
              <FloatingLabel label={__('Age')}>
                <Form.Select aria-label={__('Age')} {...register('age')}>
                  {ages.map(({ type, title }) => <option key={type} value={type}>{title}</option>)}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="sex">
              <FloatingLabel label={__('Sex')}>
                <Form.Select aria-label={__('Sex')} {...register('sex')}>
                  {genders.map(({ type, title }) => <option key={type} value={type}>{title}</option>)}
                </Form.Select>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="bait">
              <FloatingLabel label={__('Bait')}>
                <Form.Control type="text" name="bait" placeholder={__('Bait')} {...register('bait')} />
              </FloatingLabel>
            </Form.Group>

          </Col>

          <Col md={6}>

            <Form.Group className="mb-3" controlId="time">
              <Controller name="time" control={control} isInvalid={!!errors.time}
                render={({ field }) => <DatePickerControl {...field} label={__('Time')} showTimeInput dateFormat="Pp" disabled={!!history_id} />}
              />
              <Form.Control.Feedback type="invalid">{__('Wrong date')}</Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="latitude">
              <FloatingLabel label={__('Latitude')}>
                <Form.Control type="text" name="latitude" placeholder={__('Latitude')}
                  {...register('latitude')}
                  isInvalid={!!errors.latitude}
                />
                <Form.Control.Feedback type="invalid">{errorLatitude}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="longitude">
              <FloatingLabel label={__('Longitude')}>
                <Form.Control type="text" name="longitude" placeholder={__('Longitude')}
                  {...register('longitude')}
                  isInvalid={!!errors.longitude}
                />
                <Form.Control.Feedback type="invalid">{errorLongitude}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3">
              <Button onClick={handleChangeLocation}>{__('Change Catch Location on Map')}</Button>
            </Form.Group>

            <Form.Group className="mb-3" controlId="notes">
              <FloatingLabel label={__('Notes')}>
                <Form.Control as="textarea" style={{ height: '6rem' }} name="notes" placeholder={__('Notes')} {...register('notes')} />
              </FloatingLabel>
            </Form.Group>

          </Col>

        </Row>
      </Modal.Body>

      <Modal.Footer>
        {history_id && <Button variant="danger" onClick={handleDeleteClick}>{__('Delete Alarm')}</Button>}
        <div className="flex-fill" />
        <Button variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>
        <Button type="submit">{history_id ? __('Save Changes') : __('Register new Catch')}</Button>
      </Modal.Footer>

      <LocationDialog {...locationModal.register()} />
      <ConfirmModalDialog {...deleteModal.register()} />

    </Form >
  )
}, { size: 'lg' })

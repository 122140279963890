import { useMemo } from 'react';
import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { useEffectState } from 'src/utils/hooks';
import { useWorkspace } from 'src/model/workspaces';
import { formatISO } from 'src/utils/datefns';
import { useCreateOrUpdateTrapEvent } from 'src/model/traps';
import { BATTERY_DEFAULT } from 'src/constants';

export const EventBatteryDialog = withModalDialog(({ trap_id, available_battery_types, battery_type, mutate, onClose }) => {
  const { battery_types = [] } = useWorkspace();
  const [type, setType] = useEffectState(battery_type, [battery_type]);
  const [createTrapEventAction] = useCreateOrUpdateTrapEvent();

  const batteryTypes = useMemo(() => {
    const titles = Object.fromEntries((battery_types ?? []).map(({ type, title }) => [type, title]));
    return (available_battery_types ?? []).map(type => ({ type, title: titles[type] ?? type })).filter(({ type }) => type !== BATTERY_DEFAULT);
  }, [available_battery_types, battery_types]);

  async function handleSubmitForm() {
    await createTrapEventAction({ type: 'change_battery', time: formatISO(new Date()), trap_id, data: { battery_type: type } });
    mutate();
    onClose();
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{__('Battery Change')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="battery_type">
          <FloatingLabel label={__('Battery Type')}>
            <Form.Select aria-label={__('Battery Type')} onChange={event => setType(event.target.value)}>
              {batteryTypes.map(({ type, title }) => <option key={type} value={type}>{title}</option>)}
            </Form.Select>
          </FloatingLabel>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>
        <Button variant="primary" onClick={handleSubmitForm}>{__('Save Changes')}</Button>
      </Modal.Footer>
    </>
  )
})

import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

export const LanguageSelector = () => {
  const re = /\/(en|de|da|fi|fr)\//i;

  const { pathname, search } = useLocation();
  const href = useMemo(() => window.location.href, [pathname, search]);

  const { languages, lang } = config;
  const title = (languages.find(item => item.code === lang) ?? languages[0]).title;

  return (
    <Dropdown drop="up">
      <Dropdown.Toggle as="a">{title}</Dropdown.Toggle>
      <Dropdown.Menu >
        {
          languages.map(i => <Dropdown.Item key={i.code} href={href.replace(re, `/${i.code}/`)} >{i.title}</Dropdown.Item>)
        }
      </Dropdown.Menu>
    </Dropdown>
  )
}
import { forwardRef } from 'react';
import { Form } from 'react-bootstrap';
import { useCountries } from 'src/hooks/countries';

export const CountrySelector = forwardRef((props, ref) => {
  const { allCountries, iso2Lookup } = useCountries();
  return (
    <Form.Select ref={ref} {...props}>
      <option value="" disabled>{__('Select country')}</option>
      <optgroup label={__('EU Countries')}>
        {config.general.preferredCountries.map(iso2 => <option key={iso2} value={iso2}>{allCountries[iso2Lookup[iso2]]?.name}</option>)}
      </optgroup>
      <optgroup label={__('All Countries')}>
        {allCountries.map(country => <option key={country.iso2} value={country.iso2}>{country.name}</option>)}
      </optgroup>
    </Form.Select>
  )
})

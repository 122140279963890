export const strings = {
  unknown_error: __('Unknown error'),
  bad_request: __('Incorrect / incomplete request data.'),
  invalid_credentials: __('Invalid user email or password.'),
  forbidden_access: __('No rights to view data / do chosen action.'),
  action_cannot_be_done: __('Action can not be done due logic reasons.'),
  entity_already_exists: __('Trying to save already existing entity.'),
  entity_is_unsuitable: __('ERROR! You have tried to change a device type from ”MinkPolice” (which works with a magnet) to ”Tilt Sensor”, but one or more of the devices is not capaple of measuring the tilt angle. Please contact MinkPolice support for more information.'),
  not_found: __('The URI requested is invalid or the resource requested, such as a user, does not exists.'),
  internal_server_error: __('Something is broken.'),
  error_find_user: __('User not found.'),
  error_invalid_data_set: __('Invalid data set.'),
  error_history_not_found: __('History not found.'),
  error_event_not_found: __('Event not found.'),
  error_invalid_device_type: __('Invalid device type.'),
  error_trap_names_must_be_unique: __('All names must be unique.'),
  can_not_switch_minkpolice_to_tilt_due_incompatible_firmware_version: __('Device is not capable of measuring the tilt angle.'),
  error_email_in_use: __('Email entered is already in use.'),
  error_email_the_same: __('The email entered is the same as your current/old email address.'),
  error_password_the_same: __('Entered password is the same as the old password.'),
  missing_parameters: __('Missing parameters.'),
  serial_number_does_not_exist: __('A module with this serial number does not exist in our database.'),
  serial_number_already_registered: __('A trap with this serial number has already been registered.'),
  trap_name_already_exists: __('A trap with this name already exists on your profile. Please choose another name.'),
  error_wrong_password: __('Wrong current password.'),
  trap_belongs_to_another_domain: __('This device belongs to another domain.'),
  activation_expired: __('The trap has not been checked since the alarm.'),
  first_com_attempt_failed: __('First communication attempt failed. Check again at %@'),
  invalid_trap_security: __('Signature validation failed.'),
  minkpolice_to_tilt: __('Your trap is now registered as a tilt sensor and will no longer send alarm messages because of events on the magnet switch. Please activate your device with the magnet in order to start monitoring the tilt angle. When the device is tilted at the angle you have chosen, you will receive an alarm message. You can reset the starting point for measurement by activating you device with a magnet. Please be aware that while the magnet is attached to the sensor on the red dot, your the device will ignore any tilting.'),
  module_switch_off: __('Module is switched OFF in the user interface.'),
  module_switch_on: __('Module is switched ON in the user interface.'),
  no_communication: __('No communication with module.'),
  not_connected: __('Module is not connected yet.'),
  ok: __('OK'),
  'ovr2_5 (?)': __('Battery is low!'),
  registration: __('Trap has been registered.'),
  sleep_state: __('Module is switched off.'),
  tilt_alarm: __('Trap is tilted.'),
  tilt_still: __('Trap is still tilted.'),
  tilt_to_minkpolice: __('You have changed a device type from ”Tilt Sensor” to ”MinkPolice”, and the device will now send an alarm if the magnet is not attached to the box. Please be aware that your device will continue to react on tilting until you have restarted it by taking out the batteries. Please remove the two middle batteries, then reinsert them in order to prevent your device to react on tilting.'),
  trap_activated: __('Trap is activated.'),
  trap_is_not_compatible: __('ERROR! You have tried to change a device type from ”MinkPolice” (which works with a magnet) to ”Tilt Sensor”, but one or more of the devices is not capaple of measuring the tilt angle. Please contact MinkPolice support for more information.'),
  user_is_already_confirmed: __('User account has already been confirmed'),
};

import { useState, useMemo, useRef, forwardRef } from 'react';
import { Button, Modal } from 'react-bootstrap';

export function useModalDialog() {
  const promise = useRef();
  const [show, setShow] = useState(false);
  const [props, setProps] = useState({});

  function handleOnClose(data) {
    setShow(false);

    promise.current && promise.current(data);
    promise.current = null;
  }

  return useMemo(() => ({
    register: () => ({ show, onClose: handleOnClose, ...props }),
    show: async (props) => {
      if (promise.current) { throw new Error('useModalDialog: unresolved promise') }

      return new Promise((resolve) => {
        promise.current = resolve;
        setShow(true);
        setProps(props);
      });
    }
  }));
}

export function withModalDialog(WrappedComponent, dialogPros) {
  return forwardRef(({ show, size, contentClassName, ...props }, ref) => (
    <Modal show={show} centered size={size} contentClassName={contentClassName} onHide={() => props.onClose()} {...dialogPros}>
      <WrappedComponent ref={ref} {...props} />
    </Modal>
  ))
}

export const ModalDialog = ({ onClose, ...props }) => (
  <Modal onHide={() => onClose()} {...props} />
)

export const ConfirmModalDialog = withModalDialog(({ title = __('Confirmation'), variant = 'danger', actionTitle = __('Delete'), text, onClose, hideCancel = false }) => (
  <>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <p>{text}</p>
    </Modal.Body>

    <Modal.Footer>
      {!hideCancel && <Button variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>}
      <Button variant={variant} onClick={() => onClose({})}>{actionTitle}</Button>
    </Modal.Footer>
  </>
))

export function pick(obj = {}, keys) {
  return Object.entries(obj)
    .filter(([key]) => keys.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {});
}

export function omit(obj = {}, keys) {
  return Object.entries(obj)
    .filter(([key]) => !keys.includes(key))
    .reduce((obj, [key, val]) => Object.assign(obj, { [key]: val }), {});
}

export function isEmpty(obj) {
  return [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length;
}

export function isString(str) {
  if (str != null && typeof str.valueOf() === "string") {
    return true
  }
  return false
}

const idCounter = {}

export function uniqueId(prefix = 'x') {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0
  }
  const id = ++idCounter[prefix]
  return `${prefix}${id}`
}

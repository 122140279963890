import cls from 'classnames';
import { useCallback, useRef, useEffect } from 'react';
import { Button, Overlay, Popover, CloseButton } from 'react-bootstrap';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';

import { EventPriority } from 'src/components/Trap/Elements';
import { useEffectState } from 'src/utils/hooks';
import { isValidPosition } from 'src/utils/helpers';

import classnames from './SidePanel.module.scss';

export const SidePanel = ({ items, selectedItem, editMode, onEditMode, onSubmit, onCancel, onSelectItem, onDropItem }) => {
  const listRef = useRef();
  const footerRef = useRef();
  const showPopover = useRef(true);
  const [popover, setPopover] = useEffectState(editMode);

  useEffect(() => { listRef.current && selectedItem && listRef.current.scrollToItem(selectedItem.index) }, [selectedItem, listRef.current]);

  function handleHidePopover() {
    showPopover.current = false;
    setPopover(false);
  }

  return (
    <div className="w-100 h-100 d-flex flex-column flex-nowrap">
      <div className="w-100 h-100">
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList ref={listRef} height={height} width={width} itemCount={items.length} itemSize={49}>
              {(({ index, style }) => (
                <ItemRow item={items[index]} isSelected={items[index] == selectedItem} editMode={editMode}
                  onSelect={onSelectItem} onDrop={onDropItem} style={style}
                />
              ))}
            </FixedSizeList>
          )}
        </AutoSizer>
      </div>

      <div ref={footerRef} className='d-flex flex-row gap-col-3 p-3 justify-content-end align-items-center' >
        {!editMode &&
          <Button onClick={() => onEditMode(!editMode)}>{__('Edit Trap Location')}</Button>
        }

        {editMode &&
          <>
            <Button variant='secondary' onClick={onCancel}>{__('Cancel')}</Button>
            <Button onClick={onSubmit}>{__('Save Changes')}</Button>
          </>
        }
      </div>

      <Overlay target={footerRef.current} show={popover && showPopover.current} placement='top' rootClose onHide={handleHidePopover} >
        {(props) => (
          <Popover id="popover-basic" {...props}>
            <Popover.Header><CloseButton onClick={handleHidePopover} /></Popover.Header>
            <Popover.Body>{__('In order to place a trap onto a map, click on Arrow Down icon next to the trap name in the list above. A needle indicating trap position will then be placed in the middle of the map, and you can now drag and drop the needle. Use the zoom buttons to define a more precise placement of a trap.')}</Popover.Body>
          </Popover>
        )}
      </Overlay>

    </div >
  )
}

const ItemRow = ({ item, isSelected, editMode, onSelect, onDrop, style }) => {
  const { trap_name, event_priority, changed } = item;

  const handleItemClick = useCallback(() => onSelect(item), [item]);
  const handleDropClick = useCallback(() => editMode && onDrop(item), [item]);

  return (
    <div className={cls(classnames.item, { [classnames.active]: isSelected })} style={style} onClick={handleItemClick} >
      <span className={classnames.icon}><EventPriority priority={event_priority} /></span>
      <span className={classnames.text}>{trap_name}</span>
      {!isValidPosition(changed) &&
        <div className={cls(classnames.location, { [classnames.active]: editMode })} onClick={handleDropClick} />
      }
    </div>
  )
}

import cls from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { ButtonToolbar, ButtonGroup, Breadcrumb, Button } from 'react-bootstrap';
import { LargeContainer, SimpleHeader, ButtonLink, SearchField } from 'src/components/base/Elements';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { Table } from 'src/components/base/Table';
import { useFetchLocation } from 'src/utils/hooks';
import { useFetchGroups, useLastVisitedGroup } from 'src/model/groups';
import { useCurrentWorkspace, useFetchWorkspaces } from 'src/model/workspaces';
import { GroupSettings } from './GroupSettings';

import { hasPermission, PERMISSION_TRAP_EDIT, PERMISSION_GROUP_EDIT } from 'src/api/permissions';
import { ITEMS_PER_PAGE } from 'src/constants';

export const Groups = observer(() => {
  const history = useHistory();
  const workspaces = useFetchWorkspaces();
  const { workspace: { workspace_id, permissions } } = useCurrentWorkspace();
  const groupSettingsDialog = useModalDialog();

  useLastVisitedGroup(false);

  const workspacesOwners = useMemo(() => Object.fromEntries(workspaces.map(({ workspace_id, owner_email }) => [workspace_id, owner_email])), [workspaces]);
  const { pageIndex, sortBy, search, setFetchLocation } = useFetchLocation();
  const { data } = useFetchGroups({ workspace_id, pageIndex, pageSize: ITEMS_PER_PAGE, sortBy, search });

  const items = useMemo(() => {
    const items = [{ group_id: 0, group_name: __('All Traps'), traps_active_count: data.traps_active_count ?? 0, traps_count: data.traps_count ?? 0 }];
    if (data.traps_count && data.my_traps_group && data.traps_count !== data.my_traps_group?.traps_count) {
      items.push({ group_id: -1, group_name: data.my_traps_group.group_name, traps_active_count: data.my_traps_group.traps_active_count ?? 0, traps_count: data.my_traps_group.traps_count ?? 0 });
    }
    return [...items, ...data.items];
  }, [data]);

  function handleFetchData({ pageIndex, sortBy }) {
    setFetchLocation({ pageIndex, sortBy, search });
  }

  function handleOnSearch(value) {
    setFetchLocation({ pageIndex, sortBy, search: value });
  }

  async function handleClickGroup(group) {
    const result = await groupSettingsDialog.show({ group });
    if (result?.group_id) {
      history.push(`/traps/${result?.group_id}`);
    }
  }

  const getGroupName = ({ group_id, group_name }) => <span className={cls('text-break', { 'fw-bold': group_id === 0 || group_id === -1 })}>{group_name}</span>;
  const getOwnerName = ({ workspace_id, group_id }) => workspacesOwners[workspace_id] ?? (group_id !== 0 ? __('Me') : '-');

  const columns = useMemo(
    () => [
      {
        Header: __('Group Name'),
        accessor: 'group_name',
        Cell: ({ row: { original } }) => getGroupName(original),
        style: { maxWidth: 250, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }
      },
      {
        Header: __('Group Owner'),
        accessor: 'workspace_id',
        Cell: ({ row: { original } }) => getOwnerName(original),
      },
      {
        Header: __('Trap Count'),
        headerClassName: 'text-end',
        accessor: 'traps_count',
        disableSortBy: true,
        style: { width: 150, textAlign: 'end' },
      },
      {
        Header: __('Alerts'),
        headerClassName: 'text-end',
        accessor: 'traps_active_count',
        disableSortBy: true,
        style: { width: 150, textAlign: 'end' },
      },
    ],
    []
  );

  const getRowProps = useCallback(({ original: { group_id } }) => (
    {
      onClick: () => history.push(`/traps/${group_id}`),
      className: 'cursor-pointer',
    }
  ), []);

  return (
    <LargeContainer className="mb-4">

      <SimpleHeader className="mt-4 mb-4">
        <Breadcrumb>
          <Breadcrumb.Item active>{__('Trap Groups')}</Breadcrumb.Item>
        </Breadcrumb>
      </SimpleHeader>

      <ButtonToolbar className="justify-content-between mb-5">
        <SearchField style={{ width: 350 }} value={search} onSearch={handleOnSearch} placeholder={__('Search (group name, note)')} />

        <ButtonGroup aria-label="Third group">
          {hasPermission(permissions, PERMISSION_GROUP_EDIT) && <Button variant="outline-primary" onClick={() => handleClickGroup()}>{__('New Group')}</Button>}
          {hasPermission(permissions, PERMISSION_TRAP_EDIT) && <ButtonLink variant="outline-primary" to={'/trap/new'}>{__('New Trap')}</ButtonLink>}
        </ButtonGroup>
      </ButtonToolbar>

      <Table columns={columns} data={items} getRowProps={getRowProps}
        pageIndex={pageIndex} pageCount={data.pageCount} pageSize={ITEMS_PER_PAGE} sortBy={sortBy}
        fetchData={handleFetchData}
      />

      <GroupSettings {...groupSettingsDialog.register()} />
    </LargeContainer>
  )
})
import cls from 'classnames';
import { observer } from 'mobx-react-lite';
import { useMemo, useCallback } from 'react';
import { Row, Col, ButtonGroup, Button } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';
import { Pane } from 'src/components/base/Elements';
import { useModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { SubscriptionsBilling } from './SubscriptionsBilling';
import { TooltipIcon, TooltipControl } from 'src/components/base/Tooltip';
import { PriceCell, SubscriptionStatus } from 'src/components/Payment/Elements';
import { useFetchLocation } from 'src/utils/hooks';
import { formatFromISO } from 'src/utils/datefns';
import { useFetchSubscriptions, useUpdateSubscription } from 'src/model/subscriptions';
import { toastError } from 'src/utils/toast';
import { useSystemStore } from 'src/stores';

import { ITEMS_PER_PAGE } from 'src/constants';

export const Subscriptions = observer(() => {
  const modal = useModalDialog();
  const billingDialog = useModalDialog();

  const systemStore = useSystemStore();
  const enable_sim_recurring_payments = systemStore.workspace?.enable_sim_recurring_payments;

  const { pageIndex, sortBy, setFetchLocation } = useFetchLocation();
  const { data, mutate } = useFetchSubscriptions({ pageIndex, pageSize: ITEMS_PER_PAGE, sortBy });
  const [updateSubscriptionAction] = useUpdateSubscription(mutate);

  function handleFetchData({ pageIndex, sortBy }) {
    setFetchLocation({ pageIndex, sortBy });
  }

  async function handleBillingClick() {
    const result = await billingDialog.show();
    if (result?.error) {
      console.error('Failed to procceed billing', result.error);
      toastError(result.error.text || result.error.message);
    }
    mutate();
  }

  async function handlePauseClick({ id, is_paused, paused_change_available_at, is_payment_required }) {
    if (paused_change_available_at || (is_payment_required && !is_paused)) {
      const text = paused_change_available_at
        ? (is_paused
          ? __('Unfortunately it is only possible to resume a SIM card 24 hours after it was paused. Please try again after {timestamp}.')
          : __('Unfortunately it is only possible to pause a SIM card again 24 hours after it was resumed for the last time. Please try again after {timestamp}.')).replace('{timestamp}', formatFromISO(paused_change_available_at, 'Pp'))
        : __('Please prolong the subscription of this SIM card via our online payment solution. After this, you may pause the SIM card.');

      await modal.show({
        variant: 'primary', actionTitle: __('Close'), hideCancel: true,
        title: is_payment_required ? __('SIM card will expire within next month') : __('Change not possible'), text,
      });
    }
    else {
      const text = is_paused
        ? __('Are you sure you want to end the pause of this SIM card? Please note that you can only make this change once in 24 hours. If you want to pause the SIM card again you will have to wait at least 24 hours.')
        : __('Are you sure you want to pause this SIM card? Please note that you can only make this change once in 24 hours. If you want to resume the SIM card again you will have to wait at least 24 hours.');

      const result = await modal.show({
        variant: 'primary', actionTitle: __('OK'),
        title: is_paused ? __('Resume SIM card') : __('Pause SIM card'), text,
      });

      if (result) {
        await updateSubscriptionAction({ id, is_paused: !is_paused });
      }
    }
  }

  async function handleActivateClick({ id, is_canceled }) {
    const text = is_canceled
      ? __('Are you sure you want to reactivate the subscription for this SIM card? The SIM card will be shown again in your reminder for prolongation of MinkPolice SIM cards before it expires.')
      : __('Are you sure you want to cancel the subscription for this SIM card? The SIM card will remain active until the expiry date and thereafter be automatically deactivated, without showing it again in your reminder for prolongation of MinkPolice SIM cards.');

    const result = await modal.show({
      variant: is_canceled ? 'primary' : 'danger', actionTitle: __('OK'),
      title: is_canceled ? __('Reactivate Subscription') : __('Cancel Subscription'), text,
    });

    if (result) {
      await updateSubscriptionAction({ id, is_canceled: !is_canceled });
    }
  }

  async function handleDisableRenewal() {
    if (await modal.show({ actionTitle: __('Disable'), text: __('Disable automatic renewal') })) {
      await systemStore.updateWorkspace({ enable_sim_recurring_payments: false });
    }
  }

  // Expiry Date -> expired_at; Status -> status; Trap Name -> trap_name; # -> sim_ccid, current_sim_ccid, serial_no; Price ex. VAT -> cost

  const columns = useMemo(
    () => [
      (MINKS_BRAND !== 'trapsensor') && {
        Header: __('Trap Name'),
        accessor: 'name',
        Cell: ({ value }) => value ?? '-',
      },
      {
        Header: __('#'),
        accessor: 'serial_no',
        disableSortBy: true,
        style: { width: 120 },
        Cell: ({ value }) => value ?? '-',
      },
      {
        Header: __('SIM card'),
        accessor: 'ccid',
        disableSortBy: true,
        style: { width: 190 },
        Cell: ({ row: { original } }) => <SimCardCell item={original} />,
      },
      {
        Header: __('Expiry Date'),
        headerClassName: 'text-end',
        accessor: 'expired_at',
        Cell: ({ value }) => formatFromISO(value, 'PPP'),
        style: { width: 150, textAlign: 'end' },
      },
      (MINKS_BRAND === 'minkpolice') && {
        Header: __('Remaining Pause'),
        headerClassName: 'text-end',
        accessor: 'paused_days_left',
        style: { width: 160, textAlign: 'end' },
      },
      {
        Header: __('Status'),
        accessor: 'status',
        Cell: ({ row: { original } }) => <StatusCell item={original} />,
        style: { width: 130 },
      },
      {
        Header: __('Price ex. VAT'),
        headerClassName: 'text-end',
        accessor: 'cost',
        Cell: ({ row: { original } }) => <PriceCell item={original} />,
        style: { width: 130, textAlign: 'end' },
      },
      {
        Header: __('Subscription'),
        headerClassName: 'text-end',
        disableSortBy: true,
        accessor: 'is_canceled',
        Cell: ({ row: { original } }) => <ActionCell item={original} onPause={handlePauseClick} onActivate={handleActivateClick} onRenew={handleBillingClick} />,
        style: { width: 100, textAlign: 'end' },
      },
    ].filter(Boolean),
    []
  )

  const getRowId = useCallback(({ id }) => id, [])

  return (
    <Row>
      <Col cx={'auto'}>
        {enable_sim_recurring_payments &&
          <Pane className="mb-4">
            <h5>{__('Recurring payment enabled')}</h5>
            <p>{__('You have enabled recurring payments and your existing subscriptions will automatically be renewed on the 1st day of the expiry month. You will receive a receipt via mail as a confirmation. You can cancel subscriptions for individual traps in the subscription column below.')}</p>
            <p>{__('If you want to disable automatic renewal for all your subscriptions, please click the button below.')}</p>

            <Button onClick={handleDisableRenewal}>{__('Disable automatic renewal')}</Button>
          </Pane>
        }

        <Pane className="mb-4"><Button variant='link' onClick={handleBillingClick} to='#'>{__('Click here to prolong MinkPolice SIM cards via our online payment solution.')}</Button></Pane>

        <Table columns={columns} data={data.items}
          pageIndex={pageIndex} pageCount={data.pageCount} pageSize={ITEMS_PER_PAGE} sortBy={sortBy}
          fetchData={handleFetchData}
          tableOptions={{ getRowId }}
        />
      </Col>
      <SubscriptionsBilling {...billingDialog.register()} />
      <ConfirmModalDialog {...modal.register()} />
    </Row>
  )
})

const SimCardCell = ({ item: { current_sim_ccid, sim_ccid, current_sim_subscription_type } }) => {
  if (!current_sim_ccid || current_sim_ccid === sim_ccid) { return sim_ccid }

  const tooltip = current_sim_subscription_type === 'MP5'
    ? __('This SIM card is not included in the MP10 trap subscription of your trap alarm. To see the expiry date, pause or prolong this SIM card, please connect it with a MP5 device or contact our customer support. To ensure that your trap alarm can connect with an active SIM card, please use the SIM card listed above, which comes with the MP10 trap subscription.')
    : __('This SIM card is included in a MP10 trap subscription. The period of use can be extended by prolonging the respective trap alarm.');

  return (
    <>
      {sim_ccid && <>{sim_ccid}<br /></>}
      <span className="small text-nowrap">Curent:&nbsp;{current_sim_ccid}&nbsp;<TooltipIcon>{tooltip}</TooltipIcon></span>
    </>
  )
}

const StatusCell = ({ item: { is_paused, status, is_canceled } }) => {
  const tooltip = status === 'blocked'
    ? __('This card is in blocked state because it has not been paid before expiry date. In blocked state, the card is not working. The card will remain in blocked state for 90 days and will then be finally closed. If you wish to activate this card again, please click to unblock and pay for the card. If a card is not reactivated within 90 days, it cannot be reactivated.')
    : (is_canceled
      ? (status === 'paused'
        ? __('This card is currently paused, and will not be prolonged automatically because you have selected that it is cancelled. If you click to reactive the card, then it will be prolonged when it expires.')
        : __('This card is currently active, but will not be be prolonged because you have selected that it is cancelled. If you click to reactivate the card, then it will be prolonged when it expires.'))
      : '');

  return <span><SubscriptionStatus status={status} is_paused={is_paused} />{tooltip && <>&nbsp;<TooltipIcon>{tooltip}</TooltipIcon></>}</span>;
}

const ActionCell = ({ item, onPause, onActivate, onRenew }) => {
  const { is_paused, is_canceled, status, paused_days_left } = item;
  if (status === 'blocked') { return <Button variant='link' onClick={onRenew} to={'#'}>{__('Renew')}</Button> }

  return (
    <ButtonGroup aria-label="Third group">
      {MINKS_BRAND === 'minkpolice' &&
        <TooltipControl tooltip={is_paused ? __('Resume SIM card') : __('Pause SIM card')}>
          <Button onClick={() => onPause(item)} variant="outline-primary" disabled={paused_days_left === 0}><i className={cls('bi', { 'bi-pause-circle': !is_paused, 'bi-play-circle': is_paused })}></i></Button>
        </TooltipControl>
      }
      <TooltipControl tooltip={is_canceled ? __('Reactivate subscription') : __('Cancel subscription')}>
        <Button onClick={() => onActivate(item)} variant="outline-primary"><i className={cls('bi', { 'bi-x-circle': !is_canceled, 'bi-currency-euro': is_canceled })}></i></Button>
      </TooltipControl>
    </ButtonGroup>
  )
}
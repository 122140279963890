import { Modal, Form, FloatingLabel, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';

import { useModalDialog, ConfirmModalDialog, withModalDialog } from 'src/components/base/ModalDialog';
import { WizardDialog, useWizardDialog } from 'src/components/base/WizardDialog';
import { SpinnerButton } from 'src/components/base/Elements';
import { validatorResolver } from 'src/utils/validator';
import { useEffectState } from 'src/utils/hooks';
import { toastSuccess } from 'src/utils/toast';

import { TrapSelector } from './TrapSelector';
import { useUpdateGroup, useDeleteGroup } from 'src/model/groups';
import { useCurrentWorkspace } from 'src/model/workspaces';

import classes from './GroupSettings.module.scss';

const PAGE_SETTINGS = 'SETTINGS';
const PAGE_TRAPS = 'TRAPS';

export const GroupSettings = withModalDialog(({ group, onClose }) => {
  const { workspace: { workspace_id = 0 } } = useCurrentWorkspace(group?.workspace_id);

  const deleteGroupModal = useModalDialog();

  const [updateGroupAction, progressUpdate] = useUpdateGroup();
  const [deleteGroupAction, progressDelete] = useDeleteGroup();

  const progres = progressUpdate || progressDelete;

  const { currentPage, options: { title, nextButton }, nextPage } = useWizardDialog(async function* (page) {
    const settings = yield page(PAGE_SETTINGS, { group }, { title: __('Group Information') });
    const traps = yield page(PAGE_TRAPS, { workspace_id, group }, { title: __('Select Traps'), nextButton: __('Save Changes') });

    const { error, data } = await updateGroupAction({ ...settings, workspace_id, group_id: group?.group_id, traps });
    if (!error) {
      toastSuccess(__('Changes were saved successfully.'));
      onClose({ group_id: data.group_id ?? group?.group_id });
    }
  });

  async function handleOnDelete() {
    const text = <>{__('Are you sure you want to delete')} <b>{group.group_name}</b>?</>;
    if (await deleteGroupModal.show({ text })) {
      const { error } = await deleteGroupAction({ group_id: group.group_id });
      if (!error) {
        toastSuccess(__('The group has been deleted successfully'));
        onClose({});
      }
    }
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WizardDialog.Page pageKey={PAGE_SETTINGS} component={SettingsPage} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_TRAPS} component={TrapSelectorPage} {...currentPage} />
      </Modal.Body>

      <Modal.Footer>
        <div className={classes.footer}>
          {!!group && <Button disabled={progres} variant="outline-danger" onClick={handleOnDelete}>{__('Delete Group')}</Button>}
          <div className={classes.right}>
            <SpinnerButton animated={progres} onClick={nextPage}>{nextButton}</SpinnerButton>
          </div>
        </div>
      </Modal.Footer>

      <ConfirmModalDialog {...deleteGroupModal.register()} />
    </>
  )
}, { size: 'lg', contentClassName: classes.root });

const SettingsPage = ({ registerNext, data: { group } }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({ group_name: 'required', notes: 'string' }),
    defaultValues: group,
  });

  registerNext(submit => handleSubmit(submit)());

  return (
    <>
      <Form.Group className="mb-3" controlId="group_name">
        <FloatingLabel label={__('Name')}>
          <Form.Control type="text" name="group_name" placeholder={__('Name')}
            {...register('group_name', { required: true })}
            isInvalid={!!errors.group_name}
          />
          <Form.Control.Feedback type="invalid">{__('Name is required')}</Form.Control.Feedback>
        </FloatingLabel>
      </Form.Group>

      <Form.Group className="mb-3" controlId="notes">
        <FloatingLabel label={__('Notes')}>
          <Form.Control as="textarea" style={{ height: '6rem' }} name="notes" placeholder={__('Email')} {...register('notes')} />
        </FloatingLabel>
      </Form.Group>
    </>
  )
}

const TrapSelectorPage = ({ registerNext, data: { workspace_id, group } }) => {
  const filter = group?.group_id ? { key: 'filter[group_id]', value: group.group_id } : undefined;

  const modal = useModalDialog();
  const [selectedRowIds, setSelectedRowIds] = useEffectState(Object.fromEntries((group?.traps ?? []).map(item => [item, true])), [group]);

  registerNext(async (submit) => {
    const traps = Object.keys(selectedRowIds).map(item => +item)
    if (!group?.group_id && !traps.length && !await modal.show()) { return }

    submit(traps);
  })

  return (
    <>
      <TrapSelector workspace_id={workspace_id} filter={filter} pageSize={10}
        selectedRowIds={selectedRowIds} onSelectedRowsChange={setSelectedRowIds}
      />
      <ConfirmModalDialog size="lg" variant="primary" actionTitle={__('Proceed')}
        title={__('You have not added devices to this Trap Group!')}
        text={__('Select the devices you want to add to the Trap Group in the tick boxes to the left of their names. Then save your changes. If you want to create an empty Trap Group click "Proceed".')}
        {...modal.register()}
      />
    </>
  )
}

import { useState } from 'react';
import { Form, Button, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { useSignIn, useResendUserActivation } from 'src/model/user';

import { FormContainer } from 'src/components/base/Elements';
import { validatorResolver } from 'src/utils/validator';

const STEP_LOGIN = 'login';
const STEP_RESEND = 'resend';
const STEP_RESEND_DONE = 'resend_done';

export const SignIn = () => {
  const { register, handleSubmit, formState: { errors }, getValues } = useForm({
    resolver: validatorResolver({
      email: ['required', 'email'], password: 'required',
    })
  });

  const [signInAction] = useSignIn();
  const [resendActivationAction] = useResendUserActivation();
  const [step, setStep] = useState(STEP_LOGIN);

  async function onSumbit(data) {
    const { error } = await signInAction(data);
    if (error?.code === 'email_is_not_confirmed') { setStep(STEP_RESEND) }
  }

  async function handleResendClick() {
    const email = getValues('email');
    const { error } = await resendActivationAction({ email });
    if (!error) { setStep(STEP_RESEND_DONE) }
  }

  return (
    <FormContainer>
      {step === STEP_LOGIN && <>
        <FormContainer.Header>{__('Log in')}</FormContainer.Header>

        <Form onSubmit={handleSubmit(onSumbit)} role="form" autoComplete="on">

          <Form.Group className="mb-3" controlId="email">
            <FloatingLabel label={__('Email')}>
              <Form.Control type="text" placeholder={__('Email')} name="email" autoComplete="email"
                {...register('email')}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">{__('Email format is invalid')}</Form.Control.Feedback>
            </FloatingLabel>

          </Form.Group>

          <Form.Group className="mb-3" controlId="current-password">
            <FloatingLabel label={__('Password')}>
              <Form.Control type="password" placeholder={__('Enter password')}
                name="current-password" autoComplete="current-password"
                {...register('password')}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">{__('Password is required')}</Form.Control.Feedback>
            </FloatingLabel>
          </Form.Group>

          <FormContainer.Footer>
            <Form.Group className="mb-3">
              <Button type="submit">{__('Log in')}</Button>
              <NavLink className="btn btn-link" to="/reset-password">{__('Forgot password?')}</NavLink>
            </Form.Group>

            <Form.Group className="mb-3">
              <NavLink className="btn btn-primary" to="/register">{__('Register New Account')}</NavLink>
            </Form.Group>
          </FormContainer.Footer>

        </Form>
      </>}

      {step === STEP_RESEND && <>
        <FormContainer.Header>{__('Resend activation link')}</FormContainer.Header>

        <p>{__('You have not confirmed your email address yet. This is done by clicking the activation link that we sent you by email. If you wish to receive a new activation link by email, please click the button below.')}</p>

        <FormContainer.Footer>
          <Form.Group className="mb-3">
            <Button type="button" onClick={handleResendClick}>{__('Send')}</Button>
          </Form.Group>
        </FormContainer.Footer>
      </>}

      {step === STEP_RESEND_DONE && <>
        <FormContainer.Header>{__('Resend activation link')}</FormContainer.Header>
        <p>{__('An email with a new activation link has been sent to your email address.')}</p>
      </>}

    </FormContainer>
  )
}
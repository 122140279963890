import { useMemo, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams, Link as RouterLink } from 'react-router-dom';
import { Breadcrumb, ButtonToolbar, ButtonGroup, DropdownButton, Dropdown, Button } from 'react-bootstrap';
import { TooltipControl } from 'src/components/base/Tooltip';
import { SubmitAction } from 'src/components/base/SubmitAction';
import { LargeContainer, SimpleHeader, ButtonLink, SearchField } from 'src/components/base/Elements';
import { TrapSwitcher, EventStatus, EventPriority, EventSignal, EventBattery } from 'src/components/Trap/Elements';
import { Table } from 'src/components/base/Table';
import { GroupSettings } from './GroupSettings';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { useFetchLocation } from 'src/utils/hooks';
import { useFetchTraps, useUpdateTrap } from 'src/model/traps';
import { useFetchGroup, useLastVisitedGroup } from 'src/model/groups';
import { useCurrentWorkspace } from 'src/model/workspaces';
import { isInteractOrOutsideElement } from 'src/utils/helpers';
import { formatFromISO } from 'src/utils/datefns';
import { hasPermission, PERMISSION_TRAP_EDIT, PERMISSION_GROUP_EDIT } from 'src/api/permissions';
import { ITEMS_PER_PAGE, SIM_ACTIVE, SIM_EXPIRING, SIM_BLOCKED, SIM_TERMINATED } from 'src/constants';

const TRAPS_FILTER = [
  { key: 0, title: __('All Traps') },
  { key: 1, title: __('Only Alerts') },
];

export const GroupOverview = observer(() => {
  const history = useHistory();
  const params = useParams();
  const group_id = !isNaN(Number.parseInt(params.id)) ? +params.id : undefined;
  if (group_id === undefined) { history.replace('/traps') }

  const groupSettingsDialog = useModalDialog();

  const isSpecialGroup = group_id === 0 || group_id === -1;
  useLastVisitedGroup(group_id);

  const { pageIndex, sortBy, search, filter, setFetchLocation } = useFetchLocation();
  const { data: group, mutate: mutateGroup } = useFetchGroup({ group_id: group_id === 0 ? undefined : group_id });
  const { workspace: { workspace_id, permissions } } = useCurrentWorkspace(group.workspace_id);

  const { data, mutate } = useFetchTraps({ group_id, workspace_id, pageIndex, sortBy, search, active: filter });
  const [updateTrapAction] = useUpdateTrap(mutate);

  const groupName = group_id === 0 ? __('All Traps') : group?.group_name;

  function handleFetchData({ pageIndex, sortBy }) {
    setFetchLocation({ pageIndex, sortBy, search });
  }

  function handleOnSearch(value) {
    setFetchLocation({ pageIndex: 0, sortBy, search: value, filter });
  }

  function handleOnFilter(key) {
    setFetchLocation({ pageIndex, sortBy, search, filter: key });
  }

  async function handleSwitchTrap(trap_id, is_switched_on) {
    await updateTrapAction({ trap_id, is_switched_on });
  }

  async function handleClickGroupSettings() {
    const result = await groupSettingsDialog.show({ group });
    if (result) {
      if (!result.group_id) { return history.replace('/traps') }
      mutateGroup();
      mutate();
    }
  }

  const columns = useMemo(
    () => [
      {
        accessor: 'event_priority',
        disableSortBy: true,
        Cell: ({ value }) => <EventPriority priority={value} />,
        style: { width: 22 },
      },
      {
        Header: __('Trap Name'),
        accessor: 'trap_name',
        Cell: ({ row: { original } }) => <TrapName name={original.trap_name} notes={original.notes} />,
        style: { width: '20%' },
      },
      {
        Header: __('#'),
        headerClassName: 'text-end',
        accessor: 'serial_no',
        style: { width: 140, textAlign: 'end' },
      },
      {
        Header: __('Status'),
        accessor: 'time',
        Cell: ({ row: { original } }) => <TrapsStatus item={original} />,
      },
      {
        Header: __('Battery'),
        headerClassName: 'text-end',
        accessor: 'battery',
        Cell: ({ row: { original } }) => <EventBattery battery={original.battery} voltage={original.battery_full} />,
        style: { width: 100, textAlign: 'end' },
      },
      {
        Header: __('Signal'),
        headerClassName: 'text-end',
        accessor: 'signal',
        Cell: ({ row: { original } }) => <EventSignal signal={original.signal} rssi={original.signal_raw} />,
        style: { width: 100, textAlign: 'end' },
      },
      {
        Header: __('ON/OFF'),
        headerClassName: 'text-end',
        accessor: 'is_switched_on',
        Cell: ({ row: { original } }) => <TrapSwitcher type={original.trap_type}
          value={original.is_switched_on} onChange={value => handleSwitchTrap(original.trap_id, value)}
        />,
        style: { width: 100, textAlign: 'end' },
      },
    ],
    []
  );

  const getRowProps = useCallback(({ original: { trap_id } }) => (
    {
      onClick: event => !isInteractOrOutsideElement(event) && history.push(`/trap/${trap_id}`),
      className: 'cursor-pointer',
    }
  ), []);

  return (
    <LargeContainer className="mb-4">
      <SimpleHeader className="mt-4 mb-4">
        <Breadcrumb>
          <Breadcrumb.Item linkAs={RouterLink}
            linkProps={{ to: '/traps', style: { textDecoration: 'none' } }}>
            {__('Trap Groups')}
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{groupName}</Breadcrumb.Item>
        </Breadcrumb>
      </SimpleHeader>

      <ButtonToolbar className="justify-content-between mb-5">
        <SearchField style={{ width: 350 }} value={search} onSearch={handleOnSearch} placeholder={__('Search (name, serial#, note)')} />

        <div>
          <DropdownButton className="me-2" style={{ width: 150 }} as={ButtonGroup}
            title={TRAPS_FILTER[filter ?? 0].title} variant="outline-primary" onSelect={handleOnFilter}
          >
            {TRAPS_FILTER.map(({ key, title }) => <Dropdown.Item key={key} eventKey={key}>{title}</Dropdown.Item>)}
          </DropdownButton>

          <ButtonGroup aria-label="Third group">
            <ButtonLink variant="outline-primary" to={`/groups/${group_id}/catches`}>{__('Catches')}</ButtonLink>
            {hasPermission(permissions, PERMISSION_TRAP_EDIT) &&
              <SubmitAction action={`${config.api.serverUrl}/exporttraps`} params={{ group_id }} variant="outline-primary">{__('Export Traps')}</SubmitAction>
            }
            {!isSpecialGroup && hasPermission(permissions, PERMISSION_GROUP_EDIT) && <Button variant="outline-primary" onClick={handleClickGroupSettings}>{__('Group Settings')}</Button>}
            {hasPermission(permissions, PERMISSION_TRAP_EDIT) && <ButtonLink variant="outline-primary" to={`/trap/new?group_id=${group_id}`}>{__('New Trap')}</ButtonLink>}
          </ButtonGroup>
        </div>
      </ButtonToolbar>

      <Table columns={columns} data={data.items} getRowProps={getRowProps}
        pageIndex={pageIndex} pageCount={data.pageCount} pageSize={ITEMS_PER_PAGE} sortBy={sortBy}
        fetchData={handleFetchData}
      />

      <GroupSettings {...groupSettingsDialog.register()} />
    </LargeContainer>
  )
})

const TrapName = ({ name, notes }) => (
  <TooltipControl tooltip={notes} placement="top">
    <span className="text-break text-wrap fw-bold">{name}</span>
  </TooltipControl>
)

const TrapsStatus = ({ item }) => {
  const { workspace_id, time, event_title, event_subtitle, sim_card_status, sim_card_expired_at } = item;
  const isOwnTrap = workspace_id === 0;

  return (
    <>
      {time && <>{formatFromISO(time, 'Pp')}<br /></>}
      <EventStatus title={event_title} subtitle={event_subtitle} />
      {sim_card_status !== SIM_ACTIVE &&
        <span className="text-danger">
          <br />
          {sim_card_status === SIM_TERMINATED &&
            <span>{__('Subscription expired.')} {__('Please contact customer support.')}</span>
          }

          {sim_card_status === SIM_BLOCKED &&
            <span>{isOwnTrap ? __('Subscription expired.') : __('SIM card was blocked. Contact Group Owner to unblock.')}</span>
          }

          {sim_card_status === SIM_EXPIRING &&
            <span>{isOwnTrap ? __('Subscription will expire soon.') : __('SIM card will expire soon. Contact Group Owner to prolong.')}</span>
          }
        </span>
      }
    </>
  )
}

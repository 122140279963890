import cls from 'classnames';
import { useState, useMemo } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { add, format } from 'src/utils/datefns';
import { TooltipIcon } from 'src/components/base/Tooltip';
import { useEffectState } from 'src/utils/hooks';
import { uniqueId, pick } from 'src/utils/utils';
import { toastSuccess } from 'src/utils/toast';

import { FooterRow } from 'src/components/base/Elements';
import { Card } from 'src/components/base/Card';
import { useFetchSettings, useUpdateSettings } from 'src/model/settings';

import classes from './Devices.module.scss';

const WAKEUP_EXACT = 'EXACT';
const WAKEUP_PERIODIC = 'PERIODIC';
const WAKEUP_WEEKLY = 'WEEKLY';

const DAYS = function () {
  const monday = new Date(2018, 0, 1);
  return [...new Array(7)].map((_, index) => {
    const day = add(monday, { days: index });
    return { key: format(day, 'ccc').toLowerCase(), title: format(day, 'cccc') };
  })
}()

export const Devices = () => {
  const [reset, setReset] = useState(0);
  const { data: settings, mutate } = useFetchSettings();
  const [saveSettingsAction] = useUpdateSettings(mutate);

  const [buzzer, setBuzzer] = useEffectState(settings.buzzer ?? false, [settings, reset]);
  const [wakeup, setWakeup] = useEffectState(pick(settings, [
    'wakeup_1_hours', 'wakeup_1_minutes', 'wakeup_2_hours', 'wakeup_2_minutes',
    'wakeup_repeat', 'wakeup_weekly_day', 'wakeup_weekly_time'
  ]), [settings, reset]);

  const initialTime = useMemo(() => {
    const wakeup_1_hours = wakeup.wakeup_1_hours ?? generateRandomValue(6, 11, 1);
    const wakeup_1_minutes = wakeup.wakeup_1_minutes ?? generateRandomValue(0, 60, 5);

    const wakeup_2_hours = wakeup.wakeup_2_hours ?? (wakeup_1_hours + 12);
    const wakeup_2_minutes = wakeup.wakeup_2_minutes ?? wakeup_1_minutes;

    const wakeup_weekly_day = wakeup.wakeup_weekly_day ?? DAYS[Math.floor(Math.random() * (DAYS.length - 1)) + 1].key;
    const wakeup_weekly_time = wakeup.wakeup_weekly_time ?? `${generateRandomValue(9, 19, 1)}:${generateRandomValue(0, 60, 15)}`;
    return { wakeup_1_hours, wakeup_1_minutes, wakeup_2_hours, wakeup_2_minutes, wakeup_weekly_day, wakeup_weekly_time }
  }, []);

  async function handleSaveChanges() {
    const { error } = await saveSettingsAction({ ...wakeup, buzzer });
    if (!error) { toastSuccess(__('Your changes were saved.')) }
  }

  function handleCancel() {
    setReset(reset + 1);
  }

  const wakeupType = wakeup.wakeup_weekly_day !== null ? WAKEUP_WEEKLY : (wakeup.wakeup_repeat !== null ? WAKEUP_PERIODIC : WAKEUP_EXACT);

  function handleWakeupTypeChange(event) {
    if (event.target.id === WAKEUP_EXACT) {
      setWakeup({
        wakeup_1_hours: initialTime.wakeup_1_hours, wakeup_1_minutes: initialTime.wakeup_1_minutes,
        wakeup_2_hours: null, wakeup_2_minutes: null,
        wakeup_repeat: null, wakeup_weekly_day: null, wakeup_weekly_time: null
      })
    }
    else if (event.target.id === WAKEUP_PERIODIC) {
      setWakeup({
        wakeup_1_hours: null, wakeup_1_minutes: null,
        wakeup_2_hours: null, wakeup_2_minutes: null,
        wakeup_repeat: 8, wakeup_weekly_day: null, wakeup_weekly_time: null
      });
    }
    else {
      setWakeup({
        wakeup_1_hours: null, wakeup_1_minutes: null,
        wakeup_2_hours: null, wakeup_2_minutes: null,
        wakeup_repeat: null, wakeup_weekly_day: initialTime.wakeup_weekly_day, wakeup_weekly_time: initialTime.wakeup_weekly_time
      });
    }
  }

  function handleWakeup1Change(time) {
    if (time.hours === null && wakeup.wakeup_2_hours === null) { return }
    setWakeup({ ...wakeup, wakeup_1_hours: time.hours, wakeup_1_minutes: time.minutes });
  }

  function handleWakeup2Change(time) {
    if (time.hours === null && wakeup.wakeup_1_hours === null) { return }
    setWakeup({ ...wakeup, wakeup_2_hours: time.hours, wakeup_2_minutes: time.minutes });
  }

  function handleWakeupPeriodChange(wakeup_repeat) {
    setWakeup({ ...wakeup, wakeup_repeat });
  }

  function handleWakeupWeeklyChange(time) {
    setWakeup({ ...wakeup, wakeup_weekly_day: time.day, wakeup_weekly_time: time.time });
  }

  return (
    <div className={classes.root}>
      <Row>
        <Col lg={7} md={12}>

          <Card title={
            <>
              <span>{__('Wake Up Schedule')}&nbsp;</span>
              <span className='fs-4'>
                <TooltipIcon>{__('Your device is in the field, monitoring the trap for you, waiting to be triggered and send an alarm. In order to ensure that the device is ready to report an alarm when it should, it needs to “wake up” regularly and send a status to the system. This way you will be informed in case an issue arises (e.g. batteries running low, problems in the network connection), which might impair the device’s ability to connect reliably. And otherwise, you will see that your device is still working as it should in the Trap History, when it sends an OK status. In the Notification settings you can also choose to receive a summarized status report according to the chosen Wake Up Schedule via email, SMS or push notification (on a smartphone/tablet). How often your device should check in and report a status, depends on your personal preference and possibly also local laws regarding checking intervals.')}</TooltipIcon>
              </span>
            </>
          } className={classes.options}>

            <p className="text-primary">{__('For all devices')}</p>
            <p className='fs-sm pb-2'>{__('Please note that a change in the Wake Up Schedule will only take effect after the next initially set Wake Up Time has taken place. The devices need to connect once to get the new Wake Up Schedule from the system.')}</p>

            <Card.Body>
              <Row>
                <Col sm={7}>
                  <Form.Check className="pb-4" type="radio"
                    label={__('Daily')} id={WAKEUP_EXACT}
                    checked={wakeupType === WAKEUP_EXACT} onChange={handleWakeupTypeChange}
                  />

                  <WakeUpTime className="pb-4" label={__('Wake Up #1')} disabled={wakeupType !== WAKEUP_EXACT}
                    defaultTime={{ hours: initialTime.wakeup_1_hours, minutes: initialTime.wakeup_1_minutes }} onChange={handleWakeup1Change}
                    time={{ hours: wakeup.wakeup_1_hours, minutes: wakeup.wakeup_1_minutes }}
                  />

                  <WakeUpTime label={__('Wake Up #2')} disabled={wakeupType !== WAKEUP_EXACT}
                    defaultTime={{ hours: initialTime.wakeup_2_hours, minutes: initialTime.wakeup_2_minutes }} onChange={handleWakeup2Change}
                    time={{ hours: wakeup.wakeup_2_hours, minutes: wakeup.wakeup_2_minutes }}
                  />

                </Col>

                {MINKS_BRAND === 'minkpolice' &&
                  <Col sm={5}>
                    <Form.Check className="pb-4" type="radio"
                      label={__('Periodic')} id={WAKEUP_PERIODIC}
                      checked={wakeupType === WAKEUP_PERIODIC} onChange={handleWakeupTypeChange}
                    />
                    <PeriodicTime repeat={wakeup.wakeup_repeat} onChange={handleWakeupPeriodChange} />
                  </Col>
                }

                {MINKS_BRAND === 'trapsensor' &&
                  <Col sm={5}>
                    <Form.Check className="pb-4" type="radio"
                      label={__('Weekly')} id={WAKEUP_WEEKLY}
                      checked={wakeupType === WAKEUP_WEEKLY} onChange={handleWakeupTypeChange}
                    />
                    <WeeklyTime period={{ day: wakeup.wakeup_weekly_day, time: wakeup.wakeup_weekly_time }} onChange={handleWakeupWeeklyChange} />
                  </Col>
                }
              </Row>
            </Card.Body>
          </Card>

        </Col>

        <Col lg={5} md={12}>
          <Card title={__('Device sound')}>
            <Card.Body>
              <p className="text-primary pb-2" >{__('For all devices')}</p>

              <div className="d-flex flex-row flex-nowrap align-items-center gap-col-2">
                <Form.Check type="switch" id="switch-soud">
                  <Form.Check.Input className='d-block mt-auto mb-auto' checked={buzzer} onChange={event => setBuzzer(event.target.checked)} />
                </Form.Check>
                <i className={cls('bi fs-3', { 'bi-volume-up': buzzer, 'bi-volume-mute': !buzzer })}></i>
              </div>

            </Card.Body>
          </Card>
        </Col>
      </Row>

      <FooterRow>
        <Button variant="secondary" onClick={handleCancel}>{__('Cancel')}</Button>
        <Button variant="primary" onClick={handleSaveChanges}>{__('Save Changes')}</Button>
      </FooterRow>

    </div>
  )
}

const WakeUpTime = ({ label, time, defaultTime, onChange, disabled, className }) => {
  const isEnabled = time.hours !== null && time.minutes !== null;

  function handleSwitchClick(event) {
    onChange(event.target.checked ? defaultTime : { hours: null, minutes: null });
  }

  return (
    <div className={cls('d-flex flex-row flex-nowrap align-items-baseline gap-col-2', className)}>
      <Form.Check className="pe-1"
        id={uniqueId()} disabled={disabled} type="switch" label={label}
        checked={isEnabled} onChange={handleSwitchClick} />

      <TimeSelector time={time} onChange={onChange} disabled={!isEnabled} />
    </div>
  )
}

const PeriodicTime = ({ repeat, onChange }) => {
  const periods = useMemo(() => [1, 2, 4, 6, 8], []);
  const isEnabled = repeat !== null;

  return (
    <div className="d-flex flex-row flex-nowrap align-items-baseline gap-col-2">
      <span className={cls({ 'opacity-50': !isEnabled })}>{__('Wake Up every')}</span>

      <Form.Select as="select" style={{ maxWidth: 80 }} value={repeat ?? 0} onChange={event => onChange(event.target.value)} disabled={!isEnabled}>
        {isEnabled && periods.map(p => <option key={p} value={p}>{p}</option>)}
      </Form.Select>
      <span className={cls({ 'opacity-50': !isEnabled })}>{__('hours')}</span>
    </div>
  )
}

const WeeklyTime = ({ period, onChange }) => {
  const isEnabled = !!period.day;

  const time = useMemo(() => {
    const mc = period.time?.match(/^(\d+):(\d+)$/);
    return { hours: mc && parseInt(mc[1]), minutes: mc && parseInt(mc[2]) }
  }, [period]);

  function handleChangeDay(day) {
    onChange({ day, time: period.time });
  }

  function handleChangeTime(time) {
    onChange({ day: period.day, time: `${time.hours}:${time.minutes}` });
  }

  return (
    <div className="d-flex flex-column">

      <div className="d-flex flex-row flex-nowrap align-items-baseline gap-col-2 mb-2">
        <span className={cls({ 'opacity-50': !isEnabled })}>{__('Every')}</span>

        <Form.Select as="select" value={period.day ?? 0} onChange={event => handleChangeDay(event.target.value)} disabled={!isEnabled}>
          {DAYS.map(({ key, title }) => <option key={key} value={key}>{title}</option>)}
        </Form.Select>
      </div>

      <div className="d-flex flex-row flex-nowrap align-items-baseline">
        <span className={cls({ 'opacity-50': !isEnabled }, 'pe-2')}>{__('at')}</span>
        <TimeSelector time={time} onChange={handleChangeTime} disabled={!isEnabled} />
      </div>
    </div>
  )
}

const TimeSelector = ({ time, onChange, disabled }) => {
  const { hours, minutes } = time;

  function handleHoursChange(hours) {
    onChange({ hours, minutes });
  }

  function handleMinutesChange(minutes) {
    onChange({ hours, minutes });
  }

  return (
    <div className="d-flex flex-row flex-nowrap align-items-baseline">
      <DigitalControl disabled={disabled} value={hours} max={23} onChange={handleHoursChange} />
      <span className="ps-2 pe-2">:</span>
      <DigitalControl disabled={disabled} value={minutes} max={59} onChange={handleMinutesChange} />
    </div>
  )
}

const DigitalControl = ({ value, onChange, min = 0, max, disabled }) => {
  const rawValue = value !== null ? `${value}`.padStart(2, '0') : '';

  function handleChange(event) {
    onChange(+event.target.value);
  }

  return <Form.Control className={classes.timeControl}
    type="number" min={min} max={max}
    value={rawValue} onChange={handleChange} disabled={disabled}
  />
}

function generateRandomValue(start, stop, step) {
  const max = Math.floor((stop - start) / step);
  return start + Math.floor(Math.random() * max) * step;
}
import { useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Toast } from 'react-bootstrap';
import { Header } from './Header';
import { Footer } from './Footer.brand';
import { useSystemStore } from 'src/stores';
import { useEffectState } from 'src/utils/hooks';
import { TOAST_INFO, TOAST_WARNING } from 'src/utils/toast';

import classes from './Layout.module.scss';

export const Layout = ({ children, blackTheme = false }) => (
  <div className={classes.root}>
    <Toaster />
    <Header blackTheme={blackTheme} />

    <div className={classes.body}>{children}</div>

    <Footer blackTheme={blackTheme} />
  </div>
)

const TOAST_VARIANTS = { [TOAST_INFO]: 'primary', [TOAST_WARNING]: 'warning' };

const Toaster = observer(() => {
  const { toast } = useSystemStore();
  const lastToast = useRef(toast);

  const [open, setOpen] = useEffectState(lastToast.current !== toast && !!toast.message, [toast]);
  lastToast.current = toast;

  const { variant = 'warning', message, autohide = true } = toast;
  const delay = variant !== 'info' ? 20000 : 5000;

  return (
    <div className="toast-container position-fixed" style={{ transform: 'translate(-50%, 0%)', left: '50%' }}>
      <Toast show={open} delay={delay} autohide={autohide} onClose={() => setOpen(false)} bg={TOAST_VARIANTS[variant]}>
        <Toast.Header>
          <span className="me-auto">{message}</span>
        </Toast.Header>
      </Toast>
    </div>
  )
})


import { useState, useMemo } from 'react';
import { Row, Col, Button, Modal, Form } from 'react-bootstrap';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { WizardDialog, useWizardDialog } from 'src/components/base/WizardDialog';
import { SpinnerButton, AttributeTable } from 'src/components/base/Elements';
import { BillingAddressInfo } from 'src/components/Payment/BillingAddressInfo';

import { BillingAddress } from 'src/components/Payment/BillingAddress';
import { PaymentInfo } from 'src/components/Payment/PaymentInfo';
import { SubscriptionItems, BankWarning, VbanWarning } from 'src/components/Payment/SubscriptionItems';
import { useProcessStripePayment } from 'src/components/Payment/paymentHook';

import { useAtom } from 'src/hooks/action';
import { useWorkspace } from 'src/model/workspaces';
import { useSubscriptionsCalculate, useSubscriptionsPay } from 'src/model/subscriptions';

import { PAYMENT_TYPE_BANK, PAYMENT_TYPE_SOFORT, PAYMENT_STATUS_CHARGEABLE, PAYMENT_TYPE_VBAN } from 'src/constants';

const PAGE_SUBSCRIPTIONS = 'SUBSCRIPTIONS';
const PAGE_BILLING = 'BILLING';
const PAGE_PAYMENT = 'PAYMENT';
const PAGE_CHECKOUT = 'CHECKOUT';
const PAGE_FINAL = 'FINAL';

export const SubscriptionsBilling = withModalDialog(({ onClose }) => {
  const [atomAction, progress] = useAtom();
  const { billing_details = {} } = useWorkspace();

  const [subscriptionsCalculateAction] = useSubscriptionsCalculate();
  const [processStripePaymentAction] = useProcessStripePayment();
  const [subscriptionsPayAction] = useSubscriptionsPay();

  const { currentPage, options: { title, nextButton }, nextPage } = useWizardDialog(async function* (page) {
    const { status, subscriptions } = yield page(PAGE_SUBSCRIPTIONS, { billing_details }, { title: __('Pay for trap subscriptions') });
    if (status === PAYMENT_STATUS_CHARGEABLE) { return onClose() }

    const details = yield page(PAGE_BILLING, { billing_details }, { title: __('Billing Information') });
    const source = yield page(PAGE_PAYMENT, { billing_details: { ...billing_details, ...details } }, { title: __('Payment Information'), nextButton: __('Review Order') });

    const { address: { country }, customer_type, vat } = details;
    const { data: summary } = await subscriptionsCalculateAction({ country, customer_type, vat, source_type: source.type, sim_cards_ids: subscriptions });

    yield page(PAGE_CHECKOUT, { subscriptions, details, source, summary }, { title: __('Order summary'), nextButton: source.type === PAYMENT_TYPE_SOFORT ? __('Redirect') : __('Checkout') });

    const recurring = source.recurring;
    delete source.recurring;

    const { error, data } = await atomAction(async () => {
      Object.assign(source, { billing_details: details });
      const redirect_url = window.location.href.replace(/\/payment.*$/, `/payment?redirected&type=${source.type}`);

      let result = await subscriptionsPayAction({ source, sim_cards_ids: subscriptions, recurring, redirect_url });
      if (result.error) { throw result.error }

      result = await processStripePaymentAction(result.data);
      if (result.error) { throw result.error }

      if (result.payment_intent_id) {
        const res = await subscriptionsPayAction({ payment_intent_id: result.payment_intent_id });
        if (res.error) { throw res.error }
      }
      return result;
    });

    if (error) { return onClose({ error }) }

    let message = __('Your payment has been processed, and your SIM card subscription has been renewed. We will send an invoice to your login email address shortly. If your cards were blocked prior to payment, please allow our system to update before your cards will work again. This will take up to 30 minutes.');

    if (source.type === PAYMENT_TYPE_BANK) {
      message = <BankWarning payment_code={data.payment_code} />
    }
    if (source.type === PAYMENT_TYPE_VBAN) {
      message = <VbanWarning payment_details={data.payment_details} />
    }
    yield page(PAGE_FINAL, { message }, { title: __('Payment successful!'), nextButton: __('Close') });

    onClose();
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WizardDialog.Page pageKey={PAGE_SUBSCRIPTIONS} component={SubscriptionItems} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_BILLING} component={BillingAddress} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_PAYMENT} component={PaymentInfo} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_CHECKOUT} component={OrderSummary} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_FINAL} component={FinalPage} {...currentPage} />
      </Modal.Body>

      <Modal.Footer>
        <Button disabled={progress} variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>
        <SpinnerButton animated={progress} onClick={nextPage}>{nextButton}</SpinnerButton>
      </Modal.Footer>
    </>
  )
}, { size: 'xl' });

const OrderSummary = ({ registerNext, data: { subscriptions, details, summary: { amount, plan } } }) => {
  const [confirm, setConfirm] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  async function handleSubmit(submit) {
    if (!confirm) { return setIsInvalid(!confirm) }
    submit(confirm);
  }

  registerNext(handleSubmit);

  const items = useMemo(() => {
    const currency = plan?.currency ?? 'EUR';
    return [
      { title: __('Number of trap subscriptions'), value: subscriptions?.length },
      { title: __('Price'), value: amount?.net ? `${amount.net.toFixed(2)} ${currency}` : '-' },
      { title: __('Fee'), value: amount?.fee ? `${amount.fee.toFixed(2)} ${currency}` : '-' },
      { title: __('VAT'), value: amount?.tax ? `${(amount.tax * 100).toFixed(2)} %` : '-' },
      { title: __('Total'), value: <strong>{amount?.gross ? `${amount.gross.toFixed(2)} ${currency}` : '-'}</strong> },
    ].filter(Boolean);
  }, [subscriptions, amount, plan]);

  return (
    <>
      <p>{__('Please check that all information below is correct before you confirm the payment.')}</p>

      <Row>
        <Col>
          <h4>{__('Billing Information')}</h4>
          <BillingAddressInfo details={details} />
        </Col>

        <Col>
          <h4>{__('Summary')}</h4>
          <AttributeTable items={items} />
        </Col>
      </Row>

      <strong>
        <Form.Check type={'checkbox'} id="confirm" checked={confirm} onChange={event => setConfirm(event.target.checked)} isInvalid={isInvalid}
          label={__(`By clicking the checkout button, the user commits to pay for the first year of the subscription, and thereby renounces the right to revoke the purchase.`)}
        />
      </strong>

      <p className='text-muted fs-sm pt-2'>{__('When you commit to buy, we personalize the SIM card to your subuscription, and therefore the purchase cannot be revoked. You will be able to prolong or cancel after the first year.')}</p>
    </>
  )
}

const FinalPage = ({ registerNext, data: { message } }) => {
  registerNext(submit => submit());
  return (
    <p>{message}</p>
  )
}
import cls from 'classnames';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

export const TooltipControl = ({ tooltip, children, placement = 'auto' }) => (
  <>
    {!tooltip
      ? children
      : <OverlayTrigger placement={placement} delay={{ show: 250, hide: 400 }} overlay={<Tooltip>{tooltip}</Tooltip >}>{children}</OverlayTrigger >
    }
  </>
)

export const TooltipIcon = ({ icon = 'bi-info-circle-fill', children, placement = 'auto' }) => (
  <TooltipControl placement={placement} tooltip={children}>
    <i className={cls('bi', icon)}></i>
  </TooltipControl>
)

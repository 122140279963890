import cls from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState } from 'react';
import { WorkspaceBanner } from 'src/components/base/WorkspaceBanner';
import { VerticalSplitter } from 'src/components/base/Splitter';
import { useFetchTraps, useUpdateTrap } from 'src/model/traps';
import { useCurrentWorkspace } from 'src/model/workspaces';
import { useEffectState } from 'src/utils/hooks';

import { isValidPosition } from 'src/utils/helpers';

import { SidePanel } from './SidePanel';
import { MapPanel } from './MapPanel';

import classnames from './TrapsMap.module.scss';

export const TrapsMap = observer(() => {
  const [center, setCenter] = useState();
  const [minimized, setMinimized] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const [selectedItem, setSelectedItem] = useState();
  const { workspace: { workspace_id } } = useCurrentWorkspace();

  const { data, mutate } = useFetchTraps({ workspace_id, pageSize: 1000 });
  const [updateTrapAction] = useUpdateTrap(mutate);

  const [items, setItems] = useEffectState(() => data.items.map(({ trap_id, trap_name, event_priority, latitude, longitude }, index) => ({
    index, trap_id, trap_name, event_priority,
    position: { lat: latitude, lng: longitude },
    changed: { lat: latitude, lng: longitude },
  })), [data.items]);

  function handleCancel() {
    setItems(items.map((item) => {
      item.changed = item.position;
      return item;
    }));
    setEditMode(false);
    setSelectedItem(null);
  }

  async function handleSaveChanges() {
    await Promise.all(items.filter(item => isValidPosition(item.changed) && (item.changed.lat !== item.position.lat || item.changed.lng !== item.position.lng))
      .map(({ trap_id, changed }) => updateTrapAction({ trap_id, latitude: changed.lat, longitude: changed.lng }))
    );

    setEditMode(false);
    setSelectedItem(null);

    await mutate();
  }

  function handleDropItem(dropped) {
    Object.assign(dropped, { changed: center });
    setItems([...items]);
    setSelectedItem(dropped);
  }

  return (
    <div>
      <WorkspaceBanner />

      <div className={cls('d-flex flex-row flex-grow-1', classnames.root)}>

        <div className={cls(classnames.page, { 'd-none': minimized })}>
          <SidePanel items={items}
            selectedItem={selectedItem} onSelectItem={setSelectedItem}
            editMode={editMode} onEditMode={setEditMode} onDropItem={handleDropItem}
            onCancel={handleCancel} onSubmit={handleSaveChanges}
          />
        </div>

        <VerticalSplitter onClick={() => setMinimized(!minimized)} />

        <MapPanel items={items} editMode={editMode} onCenterChanged={setCenter}
          selectedItem={selectedItem} onSelectItem={setSelectedItem}
        />

      </div>

    </div>
  )
})

import { useMemo } from 'react';
import { fetchTraps, fetchMaptraps, createTrap, editTraps, resetTrapSim, deleteTrap, fetchStatsAlarms } from 'src/api';
import { fetchHistory, createTrapEvent, updateTrapEvent, deleteTrapEvent } from 'src/api';

import { useSWR } from 'src/utils/swr';
import { useAction } from 'src/hooks/action';
import { useSystemStore } from 'src/stores';

import { ITEMS_PER_PAGE } from 'src/constants';

export function useFetchTraps({ workspace_id, group_id, pageIndex, pageSize = ITEMS_PER_PAGE, sortBy, search, active, filter }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [], pageCount: 0 }), []);

  return useSWR(['/traps', workspace_id, group_id, pageIndex, pageSize, sortBy?.id, sortBy?.desc, search, active, filter?.key, filter?.value],
    () => fetchTraps({ workspace_id, group_id, pageIndex, pageSize, sortBy, search, active, filter }, token),
    { defaultValue }
  );
}

export function useFetchMapTraps() {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [] }), []);

  return useSWR(['/maptraps'],
    () => fetchMaptraps({}, token),
    { defaultValue }
  );
}

export function useFetchTrap({ trap_id }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({}), []);

  const fetcher = async () => {
    const { items } = await fetchTraps({ trap_id }, token);
    return items[0] ?? {};
  }
  return useSWR(trap_id !== undefined ? ['/trap', trap_id] : null, fetcher, { defaultValue });
}

export function useFetchTrapHistory({ trap_id, filter, offset }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [], pageCount: 0 }), []);

  return useSWR(trap_id !== undefined ? ['/trap/history', trap_id, offset, filter] : null,
    () => fetchHistory({ trap_id, offset, event_name: filter }, token),
    { defaultValue, disableLaggy: true }
  );
}

export function useFetchStatsAlarms({ trap_id, time_from, time_to }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ([]), []);

  return useSWR(trap_id !== undefined ? ['statsAlarms', trap_id, time_from, time_to] : null,
    () => fetchStatsAlarms({ trap_id, time_from, time_to }, token),
    { defaultValue }
  );
}


export function useUpdateTrap(mutate) {
  const { token } = useSystemStore();
  return useAction(data => data.trap_id ? editTraps(data, token) : createTrap(data, token), mutate);
}

export function useDeleteTrap(mutate) {
  const { token } = useSystemStore();
  return useAction(data => deleteTrap(data, token), mutate);
}
export function useCreateOrUpdateTrapEvent(mutate) {
  const { token } = useSystemStore();
  return useAction(data => data.history_id ? updateTrapEvent(data, token) : createTrapEvent(data, token), mutate);
}

export function useDeleteTrapEvent(mutate) {
  const { token } = useSystemStore();
  return useAction(data => deleteTrapEvent(data, token), mutate);
}

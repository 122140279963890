import { useMemo } from 'react';
import { TabPages } from 'src/components/base/TabPages';
import { LargeContainer } from 'src/components/base/Elements';
import { useWorkspace } from 'src/model/workspaces';

import { Notification } from './Notification';
import { Devices } from './Devices';
import { Subscriptions } from './Subscriptions';
import { Invoices } from './Invoices';
import { Account } from './Account';

export const Settings = () => {
  const { enable_payments } = useWorkspace();

  const items = useMemo(() => [
    { title: __('Notifications'), to: '/settings/notification', component: Notification },
    { title: __('Devices'), to: '/settings/wakeup', component: Devices },
    enable_payments && { title: __('Subscription'), to: '/settings/subscriptions', component: Subscriptions },
    enable_payments && { title: __('Payments'), to: '/settings/invoices', component: Invoices },
    { title: __('Account'), to: '/settings/account', component: Account },
  ].filter(Boolean), [enable_payments]);

  return (
    <LargeContainer>
      <TabPages items={items} />
    </LargeContainer>
  )
}

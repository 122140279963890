import loadable from '@loadable/component';
import { Switch, Route, Redirect } from 'react-router-dom';
import { WorkspaceBanner } from 'src/components/base/WorkspaceBanner';
import { Groups } from './Groups';
import { GroupOverview } from './GroupOverview';

const GroupCatches = loadable(() => import('./GroupCatches'), { resolveComponent: ({ GroupCatches }) => GroupCatches });

export default () => (
  <div>
    <WorkspaceBanner />

    <Switch>
      <Route path='/traps/:id' component={GroupOverview} />
      <Route path="/groups/:id/catches" component={GroupCatches} />
      <Route path="/traps" component={Groups} />
      <Redirect to='/traps' />
    </Switch>
  </div>
)

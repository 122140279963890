import loadable from '@loadable/component';
import { Switch, Route } from 'react-router-dom';
import { WorkspaceBanner } from 'src/components/base/WorkspaceBanner';
import { TrapOverview } from './TrapOverview';
import { TrapSettings } from './TrapSettings';

const Histogram = loadable(() => import('./Histogram'), { resolveComponent: ({ Histogram }) => Histogram });

export const Trap = () => {
  return (
    <div>
      <WorkspaceBanner />

      <Switch>
        <Route path='/trap/new' component={TrapSettings} />
        <Route path='/trap/:id/edit' component={TrapSettings} />
        <Route path='/trap/:id/histogram' component={Histogram} />
        <Route path='/trap/:id' component={TrapOverview} />
      </Switch>
    </div>
  )
}

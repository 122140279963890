import { useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SettingsForm } from 'src/components/base/SettingsForm';
import { validatorResolver } from 'src/utils/validator';
import { omit } from 'src/utils/utils';
import { useSignUp } from 'src/model/user';

export const Register = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({
      user_name: 'required', email: ['required', 'email'],
      password: ['required', 'password'], password2: ['required', { equal_to_field: 'password' }],
      accept: ['required', { eq: true }],
    })
  });

  const [registerAction] = useSignUp();
  const [success, setSuccess] = useState(false);

  async function handleSubmitForm(data) {
    const { error } = await registerAction(omit(data, ['accept', 'password2']));
    if (!error) { setSuccess(true) }
  }

  const title = success ? __('Confirm New Account') : __('Register New Account');
  const url = config.general.promoSite.replace('{lang}', config.lang);
  const text = __('I\'ve read and accept the {terms} and {policy}.')
    .replace('{terms}', `<a target="_blank" href="${url}terms">${__('terms and conditions')}</a>`)
    .replace('{policy}', `<a target="_blank" href="${url}policy">${__('privacy policy')}</a>`);

  return (
    <SettingsForm title={title} submitTitle={__('Register')} autoComplete="on" hideActions={success} onSubmit={handleSubmit(handleSubmitForm)}>
      <SettingsForm.Tab>
        {!success &&
          <>
            <Form.Group className="mb-3" controlId="user_name">
              <FloatingLabel label={__('Name')}>
                <Form.Control type="text" name="user_name" placeholder={__('Name')}
                  {...register('user_name', { required: true })}
                  isInvalid={!!errors.user_name}
                />
                <Form.Control.Feedback type="invalid">{__('Name is required')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="email">
              <FloatingLabel label={__('Email')}>
                <Form.Control type="text" name="email" placeholder={__('Email')}
                  {...register('email')}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{__('Email format is invalid')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="password">
              <FloatingLabel label={__('Password (minimum 6 characters)')}>
                <Form.Control type="password" name="new-password" placeholder={__('Password (minimum 6 characters)')}
                  {...register('password')}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">{__('Minimum 6 characters')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-4" controlId="password2">
              <FloatingLabel label={__('Repeat Password')}>
                <Form.Control type="password" name="new-password" placeholder={__('Repeat Password')}
                  {...register('password2')}
                  isInvalid={!!errors.password2}
                />
                <Form.Control.Feedback type="invalid">{__('Passwords do not match')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group className="mb-3" controlId="accept">
              <Form.Check custom type={'checkbox'} label={`Check this custom`}>
                <Form.Check.Input type={'checkbox'} {...register('accept')} isInvalid={!!errors.accept} />
                <Form.Check.Label dangerouslySetInnerHTML={{ __html: text }} />
                <Form.Control.Feedback type="invalid">{__('You must agree with our Terms & Conditions before registering.')}</Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
          </>
        }

        {success &&
          <p>{__('We have sent an email to you to confirm the registered email address. After you have clicked the confirmation link in this email, you can proceed to login to your account.')}</p>
        }
      </SettingsForm.Tab>
    </SettingsForm>
  )
}
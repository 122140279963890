// import './wdyr';
import { createRoot } from 'react-dom/client';
import * as datefns from 'src/utils/datefns';
import { App } from './app';

const root = createRoot(document.getElementById('app-container'));
root.render(<App />);

// Write preffered language for current domain
const domain = window.location.hostname.split('.').slice(-2);
document.cookie = `preferred_lang=${config.lang};domain=.${domain.join('.')};path=/;expires=${datefns.add(new Date(), { years: 1 }).toUTCString()}`;

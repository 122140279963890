export const PERMISSION_GROUP_VIEW = 'group.view';
export const PERMISSION_GROUP_EDIT = 'group.edit';
export const PERMISSION_USER_VIEW = 'user.view';
export const PERMISSION_USER_EDIT = 'user.edit';

export const PERMISSION_TRAP_EDIT = 'trap.edit';
export const PERMISSION_TRAP_EDIT_CATCH = 'trap.edit.catch';
export const PERMISSION_TRAP_EDIT_MAP = 'trap.edit.map';
export const PERMISSION_TRAP_EDIT_SWAP = 'trap.edit.swap';

export const PERMISSION_SETTINGS_EDIT = 'settings.edit';
export const PERMISSION_SETTINGS_EDIT_USER = 'settings.edit.user';

export function hasPermission(permissions, value) {
  if (!permissions) { return false }
  if (Array.isArray(permissions)) {
    return permissions.includes(value);
  }
  return permissions[value];
}
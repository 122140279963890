import { useState } from 'react';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SettingsForm } from 'src/components/base/SettingsForm';
import { validatorResolver } from 'src/utils/validator';
import { useResetPassword } from 'src/model/user';

export const ResetPassword = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({ email: ['required', 'email'] })
  });

  const [resetPasswordAction] = useResetPassword();
  const [success, setSuccess] = useState(false);

  async function handleSubmitForm(data) {
    const { error } = await resetPasswordAction(data);
    if (!error) { setSuccess(true) }
  }

  return (
    <SettingsForm title={__('Reset Password')} submitTitle={__('Submit')} autoComplete="on" hideActions={success} onSubmit={handleSubmit(handleSubmitForm)}>
      <SettingsForm.Tab>
        {!success &&
          <>
            <p>{__('Please enter the email address that you use to log in to your account. We will send you an email with instructions on how to reset your password.')}</p>

            <Form.Group className="mb-3" controlId="email">
              <FloatingLabel label={__('Email')}>
                <Form.Control type="text" name="email" placeholder={__('Email')}
                  {...register('email')}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">{__('Email format is invalid')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

          </>
        }

        {success &&
          <p>{__('We have sent instructions on how to reset your password to your email address.')}</p>
        }
      </SettingsForm.Tab>
    </SettingsForm>
  )
}
import { useMemo, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';
import { SpinnerButton } from 'src/components/base/Elements';
import { useFetchLocation } from 'src/utils/hooks';
import { formatFromISO } from 'src/utils/datefns';
import { useFetchInvoices, useRequestInvoice } from 'src/model/invoices';

import { ITEMS_PER_PAGE } from 'src/constants';

const INVOICE_STATUSES = { paid: __('Paid'), pending: __('Pending'), canceled: __('Failed') };

export const Invoices = () => {
  const { pageIndex, sortBy, setFetchLocation } = useFetchLocation();
  const { data } = useFetchInvoices({ pageIndex, pageSize: ITEMS_PER_PAGE, sortBy });

  function handleFetchData({ pageIndex, sortBy }) {
    setFetchLocation({ pageIndex, sortBy });
  }

  const columns = useMemo(
    () => [
      {
        Header: __('Invoice Number'),
        accessor: 'name',
      },
      {
        Header: __('Created'),
        accessor: 'created_at',
        Cell: ({ value }) => formatFromISO(value, 'PPP'),
      },
      {
        Header: __('Status'),
        accessor: 'status',
        Cell: ({ value }) => INVOICE_STATUSES[value] ?? value,
      },
      {
        Header: __('Download'),
        accessor: 'invoice_id',
        Cell: ({ row: { original } }) => <DownloadCell invoice_id={original.invoice_id} service={original.service} status={original.status} />,
        style: { width: '15%', height: '3.3rem' },
      },
    ],
    []
  )

  return (
    <Row>
      <Col cx={'auto'}>
        <Table columns={columns} data={data.items}
          pageIndex={pageIndex} pageCount={data.pageCount} pageSize={ITEMS_PER_PAGE} sortBy={sortBy}
          fetchData={handleFetchData}
          tableOptions={{ disableSortBy: true }}
        />
      </Col>
    </Row>
  )
}

const DownloadCell = ({ invoice_id, service, status }) => {
  if (status !== 'paid' && status !== 'pending') {
    return (<></>)
  }

  const [url, setUrl] = useState();
  const [requestInvoiceAction, progress] = useRequestInvoice();

  async function handleOnClick() {
    const { data } = await requestInvoiceAction({ invoice_id });
    if (data?.url) { setUrl(data.url) }
  }

  return (
    <>
      {url && <a target="_blank" href={url}>{__('Download')}</a>}
      {!url && <SpinnerButton animated={progress} onClick={handleOnClick}>{__('Request Document')}</SpinnerButton>}
    </>
  )
}
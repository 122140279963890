import { Button, Modal, Form, Col, Row } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { DatePickerControl } from 'src/components/base/DatePicker';
import { parseISO, formatISO } from 'src/utils/datefns';
import { validatorResolver } from 'src/utils/validator';
import { useCreateOrUpdateTrapEvent } from 'src/model/traps';

const MODE_RUN_THROUGH = 'mode_run_through';
const MODE_CATCHING = 'mode_catching';

export const EventRotationStickDialog = withModalDialog(({ trap_id, event, mutate, onClose }) => {
  const { handleSubmit, register, control, formState: { errors } } = useForm({
    resolver: validatorResolver({ event_name: 'required', time: 'required' }),
    defaultValues: { time: event?.time ? parseISO(event?.time) : new Date(), event_name: event?.event_name ?? MODE_RUN_THROUGH },
  });

  const history_id = event?.history_id;
  const [createTrapEventAction] = useCreateOrUpdateTrapEvent();

  async function handleSubmitForm(data) {
    await createTrapEventAction({
      type: data.event_name, trap_id, history_id,
      time: formatISO(data.time),
      data: { notes: data.notes },
    });
    mutate();
    onClose();
  }

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} role="form">
      <Modal.Header closeButton>
        <Modal.Title>{__('Run-through Mode')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="time">
          <Controller name="time" control={control} isInvalid={!!errors.time}
            render={({ field }) => <DatePickerControl {...field} label={__('Time')} showTimeInput dateFormat="Pp" disabled={!!history_id} />}
          />
          <Form.Control.Feedback type="invalid">{__('Wrong date')}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group as={Row}>
          <Col>
            <Form.Check className="pb-4" type="radio" id={MODE_RUN_THROUGH}
              label={__('Blocked')} {...register('event_name')} value={MODE_RUN_THROUGH}
            />
          </Col>
          <Col>
            <Form.Check className="pb-4" type="radio" id={MODE_CATCHING}
              label={__('Ready to Catch')} {...register('event_name')} value={MODE_CATCHING}
            />
          </Col>
        </Form.Group>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>
        <Button variant="primary" type="submit">{__('Save Changes')}</Button>
      </Modal.Footer>

    </Form>
  )
}, { size: 'md' })

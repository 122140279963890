import { useLocation, useHistory } from 'react-router-dom';
import { Form, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { SettingsForm } from 'src/components/base/SettingsForm';
import { validatorResolver } from 'src/utils/validator';
import { parseQueryString } from 'src/utils/helpers';
import { toastSuccess } from 'src/utils/toast';
import { useChangePassword, useConfirmUser } from 'src/model/user';

export const ChangePassword = () => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({
      password: ['required', 'password'], password2: ['required', { equal_to_field: 'password' }],
    })
  });

  const history = useHistory();
  const { pathname, search = {} } = useLocation();
  const { key } = parseQueryString(search);

  const [changePasswordAction] = useChangePassword();
  const [activateInvitationAction] = useConfirmUser();

  const title = pathname === '/change-password' ? __('Change Password') : __('Activate User');

  async function handleSubmitForm(data) {
    const action = pathname === '/activate-invitation' ? activateInvitationAction : changePasswordAction;
    const { error } = await action({ key, password: data.password });

    if (!error) {
      toastSuccess(__('The password was changed successfully'));
      history.replace('/traps');
    }
  }

  return (
    <SettingsForm title={title} autoComplete="on" submitTitle={__('Submit')} onSubmit={handleSubmit(handleSubmitForm)}>
      <SettingsForm.Tab>

        <Form.Group className="mb-3" controlId="password">
          <FloatingLabel label={__('Password (minimum 6 characters)')}>
            <Form.Control type="password" name="new-password" placeholder={__('Password (minimum 6 characters)')}
              {...register('password')} isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{__('Minimum 6 characters')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-4" controlId="password2">
          <FloatingLabel label={__('Repeat Password')}>
            <Form.Control type="password" name="new-password" placeholder={__('Repeat Password')}
              {...register('password2')} isInvalid={!!errors.password2}
            />
            <Form.Control.Feedback type="invalid">{__('Passwords do not match')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

      </SettingsForm.Tab>
    </SettingsForm>
  )
}

import cls from 'classnames';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Container, Row, Col, Button, Spinner, InputGroup, FormControl, Nav, Table } from 'react-bootstrap';
import { useEffectState } from 'src/utils/hooks';
import { substitute } from 'src/utils/helpers';
import classes from './Elements.module.scss';

export const LargeContainer = ({ children, className }) => (
  <Container className={className} fluid>
    <Col lg={{ span: 10, offset: 1 }} md={{ span: 'auto' }}>
      {children}
    </Col>
  </Container>
)

export const FormContainer = ({ children }) => (
  <Col md={{ span: 6, offset: 3 }} className="pt-5 pb-5">
    {children}
  </Col>
)

FormContainer.Header = ({ children }) => <h2 className="pb-3">{children}</h2>;
FormContainer.Footer = ({ children }) => <div className="pt-3">{children}</div>;

export const FooterRow = ({ children }) => (
  <Row>
    <div className="d-flex flex-row justify-content-end gap-col-3 pt-3">
      {children}
    </div>
  </Row>
)

export const SpinnerButton = ({ animated = false, children, ...props }) => (
  <Button {...props} disabled={animated}>
    {animated && <Spinner className="me-2" as="span" animation="border" size="sm" role="status" aria-hidden="true" />}
    {children}
  </Button>
)

const FancyLink = forwardRef(({ navigate, ...props }, ref) => <Button ref={ref} {...props} />)
export const ButtonLink = props => <RouterLink {...props} component={FancyLink} />

export const SimpleHeader = ({ children, className }) => <h2 className={className}>{children}</h2>

export const SearchField = ({ value, onSearch, placeholder, className, style }) => {
  const [text, setText] = useEffectState(value, [value]);

  function handleOnSearch() {
    onSearch(text);
  }

  function handleKeyDown() {
    if (event.keyCode === 13) {
      event.preventDefault(), onSearch(text);
    }
  }

  function handleOnClear() {
    setText('');
    if (!!value) { onSearch('') }
  }

  return (
    <InputGroup className={className} style={style}>
      <FormControl value={text} onChange={event => setText(event.target.value)} onKeyDown={handleKeyDown} placeholder={placeholder} />
      {text && <Button variant="outline-primary" onClick={handleOnClear}><i className="bi bi-x-lg"></i></Button>}
      <Button variant="primary" onClick={handleOnSearch}><i className="bi bi-search"></i></Button>
    </InputGroup>
  )
}

export const Tabs = ({ items, activeKey, onSelect, className }) => (
  <div className={cls(classes.tabs, className)}>
    <Nav variant="pills" activeKey={activeKey} onSelect={onSelect} className={classes.tabsContainer}>
      {items.map(({ title, isInvalid, ...props }, index) => (
        <Nav.Item key={`tab_${index}`}>
          <Nav.Link {...props}><TabTitle title={title} isInvalid={isInvalid} /></Nav.Link>
        </Nav.Item>
      ))}
    </Nav>
  </div>
)

const TabTitle = ({ title, isInvalid }) => (
  <span>
    {title}
    {isInvalid && <span>&nbsp;<i className="text-danger bi bi-exclamation-triangle"></i></span>}
  </span>
)

export const Pane = ({ children, className }) => (
  <div className={cls(classes.pane, className)}>
    {children}
  </div>
)


export const AttributeTable = ({ items }) => (
  <Table striped borderless>
    <tbody>
      {items.map(({ title, value }) => (
        <tr key={title}>
          <th className='text-muted'>{title}</th>
          <td><span className='float-end'>{value}</span></td>
        </tr>
      ))}
    </tbody>
  </Table>
)

export const ExternalLink = ({ title, href, className }) => (
  <a className={cls(className, 'text-decoration-none')} href={substitute(href, { lang: config.lang })} target='_blank'>{title}</a>
)

export const Copyright = ({ className }) => {
  const year = (new Date()).getFullYear();
  const content = __('© ${year} Alert House Aps. All Rights Reserved.').replace('${year}', year);
  return <span className={className}>{content}</span>;
}
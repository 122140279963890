import { useMemo } from 'react';
import { fetchGroups, createGroup, updateGroup, deleteGroup } from 'src/api';

import { useSWR } from 'src/utils/swr';
import { useAction } from 'src/hooks/action';
import { useSystemStore } from 'src/stores';

import { ITEMS_PER_PAGE } from 'src/constants';

export function useFetchGroups({ workspace_id, pageIndex, pageSize = ITEMS_PER_PAGE, sortBy, search, filter }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({
    items: [], pageCount: 0, traps_active_count: 0, traps_count: 0, my_traps_active_count: 0, my_traps_count: 0, my_traps_group: {},
  }), []);

  return useSWR(['/groups', workspace_id, pageIndex, pageSize, sortBy.id, sortBy.desc, search, filter],
    () => fetchGroups({ workspace_id, pageIndex, pageSize, sortBy, search, filter }, token),
    { defaultValue }
  );
}

export function useFetchGroup({ group_id }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({}), []);

  const fetcher = async () => {
    const { items } = await fetchGroups({ group_id }, token);
    return items[0] ?? {};
  }
  return useSWR(group_id !== undefined ? ['/group', group_id] : null, fetcher, { defaultValue });
}

export function useUpdateGroup(mutate) {
  const { token } = useSystemStore();
  return useAction(data => data.group_id ? updateGroup(data, token) : createGroup(data, token), mutate);
}

export function useDeleteGroup(mutate) {
  const { token } = useSystemStore();
  return useAction(data => deleteGroup(data, token), mutate);
}

export function useLastVisitedGroup(id) {
  const systemStore = useSystemStore();

  if (id !== undefined) {
    systemStore.setLastVisitedGroup(id !== false ? id : undefined);
  }

  return systemStore.lastVisitedGroup;
}

import { useMemo } from 'react';
import { fetchSimCards, simcardsPay, simcardsCalculate, updateSimCard } from 'src/api';

import { useSWR } from 'src/utils/swr';
import { useAction } from 'src/hooks/action';
import { useSystemStore } from 'src/stores';

import { ITEMS_PER_PAGE } from 'src/constants';

export function useFetchSubscriptions({ is_payment_required, pageIndex, pageSize = ITEMS_PER_PAGE, sortBy }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [], total_count: 0, payment: {} }), []);

  return useSWR(['/subscriptions', is_payment_required, pageIndex, pageSize, sortBy.id, sortBy.desc],
    () => fetchSimCards({ is_payment_required, pageIndex, pageSize, sortBy }, token),
    { defaultValue }
  );
}

export function useSubscriptionsCalculate() {
  const { token } = useSystemStore();
  return useAction(data => simcardsCalculate(data, token));
}

export function useSubscriptionsPay(mutate) {
  const systemStore = useSystemStore();
  return useAction(async (data) => {
    const result = await simcardsPay(data, systemStore.token);
    await systemStore.refreshWorkspace();
    return result;
  }, mutate);
}

export function useUpdateSubscription(mutate) {
  const { token } = useSystemStore();
  return useAction(data => updateSimCard(data, token), mutate);
}
import { useMemo } from 'react';
import { fetchUserSettings, saveUserSettings, fetchTimeZones, resendEmailConfirmation } from 'src/api';
import { fetchSMS, updateSMS, deleteSMS, fetchWebhook, saveWebhook } from 'src/api';

import { useAction } from 'src/hooks/action';
import { useSystemStore } from 'src/stores';
import { useSWR } from 'src/utils/swr';

export function useFetchSettings() {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({}), []);

  return useSWR(['/settings'], () => fetchUserSettings(token), { defaultValue });
}

export function useUpdateSettings(mutate) {
  const { token } = useSystemStore();
  return useAction(async (data) => mutate(saveUserSettings(data, token), false));
}

export function useFetchTimeZones() {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ([]), []);

  return useSWR(['/timezones'], () => fetchTimeZones(token), { defaultValue });
}

export function useFetchSMS() {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({}), []);

  return useSWR(['/sms'], () => fetchSMS(token), { defaultValue });
}

export function useEnableSMS(mutate) {
  const systemStore = useSystemStore();
  return useAction(async ({ enable, ...data }) => {
    const result = await (enable ? updateSMS(data, systemStore.token) : deleteSMS(data, systemStore.token));
    await systemStore.refreshWorkspace();
    return result;
  }, mutate);
}

export function useResendEmailConfirmation(mutate) {
  const { token } = useSystemStore();
  return useAction(data => resendEmailConfirmation(data, token), mutate);
}

export function useFetchWebhook() {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({}), []);

  return useSWR(['/webhook'], () => fetchWebhook(token), { defaultValue });
}

export function useSaveWebhook(mutate) {
  const { token } = useSystemStore();
  return useAction(async (data) => mutate(saveWebhook(data, token), false));
}

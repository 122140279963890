import cls from 'classnames';
import { useState, useMemo, useRef, Fragment } from 'react';
import { useParams, NavLink, useHistory } from 'react-router-dom';
import { ButtonToolbar, ButtonGroup, DropdownButton, Dropdown, Button, Alert } from 'react-bootstrap';
import { MapView, Marker, withLoadedMap } from 'src/components/base/MapView';

import { LargeContainer, ButtonLink, Tabs } from 'src/components/base/Elements';
import { TrapSwitcher, EventStatus, EventPriority, EventSignal, EventBattery } from 'src/components/Trap/Elements';
import { EventCatchDialog } from './EventCatchDialog';
import { EventBatteryDialog } from './EventBatteryDialog';
import { EventOtherDialog } from './EventOtherDialog';
import { EventRotationStickDialog } from './EventRotationStickDialog';
import { useModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { useFetchLocation, useFormatLocalization } from 'src/utils/hooks';
import { isValidPosition } from 'src/utils/helpers';
import { formatFromISO } from 'src/utils/datefns';
import { useFetchTrap, useUpdateTrap, useFetchTrapHistory, useDeleteTrapEvent } from 'src/model/traps';
import { EVENTS_DELETABLE, hasModule } from './utils';
import { SIM_ACTIVE, SIM_EXPIRING, SIM_BLOCKED, SIM_TERMINATED } from 'src/constants';

import classes from './TrapOverview.module.scss';

export const TrapOverview = () => {
  const history = useHistory();

  const params = useParams();
  const trap_id = !isNaN(Number.parseInt(params.id)) ? +params.id : undefined;
  if (trap_id === undefined) { history.replace('/traps/0') }

  const deleteModal = useModalDialog();
  const eventCatchModal = useModalDialog();
  const eventBatteryModal = useModalDialog();
  const eventBaitModal = useModalDialog();
  const eventRotationStickModal = useModalDialog();

  const [offset, setOffset] = useState(0);
  const { filter, setFetchLocation } = useFetchLocation();

  const { data: trap, mutate } = useFetchTrap({ trap_id });
  const { data: events, isLoading, isValidating, mutate: mutateEvents } = useFetchTrapHistory({ trap_id, filter, offset });
  const { records, sections } = useGroupedEvent(events);

  const [updateTrapAction] = useUpdateTrap(mutate);
  const [deleteTrapEventAction] = useDeleteTrapEvent(mutateEvents);

  const isModule = hasModule(trap.trap_type);
  const position = { lat: trap.latitude, lng: trap.longitude };

  const eventActions = useMemo(() => [
    { key: 'catch', title: __('Register Catch') },
    isModule && { key: 'change_battery', title: __('Battery Change') },
    { key: 'trap_cleaned', title: __('Trap Cleaned') },
    { key: 'bait_changed', title: __('Bait Added/Replaced') },
    ['minkpolice', 'tilt'].includes(trap.trap_type) && { key: 'run_through', title: __('Run-through Mode') },
    { key: 'other', title: __('Other Event') },
  ].filter(Boolean), [isModule, trap.trap_type]);

  const eventFilters = useMemo(() => [
    { key: 'all', title: __('All Events') },
    { key: 'alarms', title: __('Only Alarm Events') },
    isModule && { key: 'change_battery', title: __('Battery Change') },
    { key: 'trap_cleaned', title: __('Trap Cleaned') },
    { key: 'bait_changed', title: __('Bait Added/Replaced') },
    ['minkpolice', 'tilt'].includes(trap.trap_type) && { key: 'run_through', title: __('Run-through Mode') },
    { key: 'other', title: __('Other Event') },
  ].filter(Boolean), [isModule, trap.trap_type]);

  function handleFilterEvent(filter) {
    setOffset(0);
    setFetchLocation({ filter });
  }

  async function handleSwitchTrap(is_switched_on) {
    await updateTrapAction({ trap_id: trap.trap_id, is_switched_on });
    mutateEvents();
    setOffset(0);
  }

  async function handleRegisterEvent(key) {
    if (key === 'change_battery') {
      const battery_type = trap.battery_type !== 'default' ? trap.battery_type : trap.available_battery_types[0];
      await eventBatteryModal.show({ trap_id: trap.trap_id, battery_type, available_battery_types: trap.available_battery_types, mutate: mutateEvents });
    }
    else if (key === 'catch') {
      const { latitude, longitude } = trap;
      await eventCatchModal.show({ trap_id, event: { latitude, longitude }, mutate: mutateEvents });
    }
    else if (key === 'run_through') {
      const { latitude, longitude } = trap;
      await eventRotationStickModal.show({ trap_id, event: { latitude, longitude }, mutate: mutateEvents });
    }
    else if (['bait_changed', 'trap_cleaned', 'other'].includes(key)) {
      const items = {
        bait_changed: { title: __('Bait Added/Replaced'), notes: false },
        trap_cleaned: { title: __('Trap Cleaned'), notes: false },
        other: { title: __('Other Event'), notes: true }
      };
      await eventBaitModal.show({ ...items[key], type: key, trap_id, mutate: mutateEvents });
    }
  }

  async function handleClickAction(event) {
    if (['bait_changed', 'other'].includes(event.event_name)) {
      const title = event.event_name === 'bait_changed' ? __('Bait Added/Replaced') : __('Other Event');
      await eventBaitModal.show({ title, type: event.event_name, trap_id, event, mutate: mutateEvents });
    }
    else if (EVENTS_DELETABLE[event.event_name]) {
      const text = __('Do you want to delete this Event?');
      if (await deleteModal.show({ text })) {
        await deleteTrapEventAction({ history_id: event.history_id });
      }
    }
    else {
      const latitude = event.latitude ?? trap.latitude;
      const longitude = event.longitude ?? trap.longitude;
      await eventCatchModal.show({ trap_id, event: { ...event, latitude, longitude }, mutate: mutateEvents });
    }
  }

  function handleShowMore() {
    setOffset(records.length);
  }

  return (
    <LargeContainer className={classes.root}>

      <div className={classes.mapContainer}>
        <PreviewMap position={position} />

        <div className={classes.mapHeader}>
          <div className="w-100 h-100 ps-2 pe-2 d-flex flex-row justify-content-between align-items-center">
            <div className="inline-block lh-1">
              <div className="fs-3 fw-bold">{trap.trap_name}</div>
              <TrapSubtitle trap={trap} />
            </div>

            <div>
              {MINKS_BRAND === 'minkpolice' &&
                <ButtonLink variant="primary" to={`/trap/${trap_id}/histogram`}>{__('Activity Diagram')}</ButtonLink>
              }
              <ButtonLink className='ms-2' variant="primary" to={`/trap/${trap_id}/edit`}>{__('Trap Settings')}</ButtonLink>
            </div>
          </div>
        </div>
      </div>

      <ButtonToolbar className="pt-4 pb-4 justify-content-between align-items-center">

        <div className="d-flex justify-content-between align-items-center">
          {/* <TrapSwitcher className="pe-3" type={trap.trap_type} value={trap.is_switched_on ?? false} onChange={value => handleSwitchTrap(value)} /> */}

          <DropdownButton style={{ width: 150 }} as={ButtonGroup} title={__('Register New Event')} variant="outline-primary" onSelect={handleRegisterEvent}>
            {eventActions.map(({ key, title }) => <Dropdown.Item key={key} eventKey={key}>{title}</Dropdown.Item>)}
          </DropdownButton>
        </div>

        <div className="d-flex justify-content-between align-items-center">
          <DropdownButton style={{ width: 150 }} as={ButtonGroup} title={eventFilters.filter(i => (filter || 'all') == i.key)?.pop()?.title} variant="outline-primary" onSelect={handleFilterEvent}>
            {eventFilters.map(({ key, title }) => <Dropdown.Item key={key} eventKey={key} active={filter == key}>{title}</Dropdown.Item>)}
          </DropdownButton>
        </div>

        <div />
      </ButtonToolbar>

      {trap.sim_card_status && trap.sim_card_status !== SIM_ACTIVE &&
        <div className="mb-4">
          <SubscriptionAlert status={trap.sim_card_status} isOwner={trap.workspace_id === 0} />
        </div>
      }

      <div className="mb-4">
        <table className="table table-hover">

          <thead>
            <tr>
              <th style={{ width: 22 }}></th>
              <th style={{ width: 80 }}>{__('Time')}</th>
              <th>{__('Status')}</th>
              <th style={{ width: 150, textAlign: 'end' }}>{__('Temperature')},&nbsp;°C</th>
              <th style={{ width: 100, textAlign: 'end' }}>{__('Battery')}</th>
              <th style={{ width: 100, textAlign: 'end' }}>{__('Signal')}</th>
              <th style={{ width: 22 }}></th>
            </tr>
          </thead>

          <tbody>
            {sections.map(({ date, items }) => (
              <Fragment key={date}>
                <tr aria-label="row-header">
                  <td aria-label="row-header"></td>
                  <td colSpan="8" aria-label="row-header">{formatFromISO(date, 'eeee, MMMM dd, yyyy')}</td>
                </tr>

                {items.map(item => <EventRow key={item.history_id} item={item} onActionClick={handleClickAction} />)}
              </Fragment>
            ))}
            {!isLoading && !isValidating && !records?.length && (
              <tr>
                <td colSpan="8" className={classes.textAlignCenter}>{__('No results for the selected filter.')}</td>
              </tr>
            )}
          </tbody>
        </table>

        <Button className={cls('mb-4 float-end', { 'invisible': records.length >= events.total_count })} onClick={handleShowMore}>{__('Show More')}</Button>
      </div>

      <EventCatchDialog {...eventCatchModal.register()} />
      <EventBatteryDialog {...eventBatteryModal.register()} />
      <EventOtherDialog {...eventBaitModal.register()} />
      <EventRotationStickDialog {...eventRotationStickModal.register()} />
      <ConfirmModalDialog {...deleteModal.register()} />

    </LargeContainer>
  )
}

const TrapSubtitle = ({ trap: { trap_type, serial_no, wakeup, sim_card_expiry } }) => {
  if (!hasModule(trap_type)) { return null }

  const items = [`${__('Serial Number')}: ${serial_no || '—'}`];
  if (sim_card_expiry) { items.push(`${__('Subscripton Expiry')}: ${formatFromISO(sim_card_expiry, 'PP')}`) }
  if (wakeup) { items.push(`${__('Next Wake Up')}: ${formatFromISO(wakeup, 'eeee, HH:mm')}`) }

  return (<span>{items.join(' | ')}</span>)
}

const EventRow = ({ item, onActionClick }) => {
  return (
    <tr className="utl-show-hover-container">
      <td><EventPriority priority={item.event_priority} /></td>
      <td>{formatFromISO(item.time, 'HH:mm')}</td>
      <td><EventStatus title={item.event_title} subtitle={item.event_subtitle} /></td>
      <td className="text-end">{item.temperature && item.temperature}</td>
      <td className="text-end"><EventBattery battery={item.battery} voltage={item.battery_full} /></td>
      <td className="text-end"><EventSignal signal={item.signal} rssi={item.signal_raw} /></td>
      <td className="position-relative"><ActionButton item={item} onActionClick={onActionClick} /></td>
    </tr>
  )
}

const ActionButton = ({ item, onActionClick }) => {
  const isDeletable = EVENTS_DELETABLE[item.event_name];
  if (!['tilt_alarm', 'trap_activated'].includes(item.event_name) && !isDeletable) { return null }

  let variant = 'danger'; //isDeletable && !['bait_changed', 'other'].includes(item.event_name) ? 'danger' : 'primary';
  let title = __('Delete Event');

  if (['tilt_alarm', 'trap_activated', 'bait_changed', 'other'].includes(item.event_name)) {
    variant = 'primary';
    title = ['tilt_alarm', 'trap_activated'].includes(item.event_name) && !(item.animal || item.animal_other) ? __('Register new Catch') : __('Edit');
  }
  return <Button className={classes.actionButton} variant={variant} onClick={() => onActionClick(item)}>{title}</Button>
}

const SubscriptionAlert = ({ status, isOwner }) => {
  const terminatedText = useFormatLocalization(__('Please contact customer support {link}.'), {
    link: `<a className="alert-link" href="mailto:${config.general.supportEmail}">${__('here')}</a>`
  });

  return (
    <Alert variant={status === SIM_ACTIVE ? 'warning' : 'danger'}>
      {status === SIM_TERMINATED &&
        <><span>{__('Subscription expired.')}</span> <span className={classes.dangerLink} dangerouslySetInnerHTML={{ __html: terminatedText }} /></>
      }

      {status === SIM_BLOCKED &&
        <>
          {isOwner
            ? <span>{__('Subscription expired.')}&nbsp;<NavLink to={'/settings/subscriptions'} className="alert-link">{__(`Click here to renew.`)}</NavLink></span>
            : <span>{__('SIM card was blocked. Contact Group Owner to unblock.')}</span>
          }
        </>
      }

      {status === SIM_EXPIRING &&
        <>
          {isOwner
            ? <span>{__('Subscription will expire soon.')}&nbsp;<NavLink to={'/settings/subscriptions'} className="alert-link">{__(`Click here to prolong.`)}</NavLink></span>
            : <span>{__('SIM card will expire soon. Contact Group Owner to prolong.')}</span>
          }
        </>
      }
    </Alert>
  )
}

const PreviewMap = withLoadedMap(({ position }) => (
  <MapView center={position} zoom={14} options={{ zoomControlOptions: { position: 3 } }}>
    {isValidPosition(position) && <Marker position={position} />}
  </MapView>
))

function useGroupedEvent(events) {
  const records = useRef([]);
  if (events.offset === 0) {
    records.current = events.items;
  }
  else if (records.current.length === events.offset) {
    records.current = [...records.current, ...events.items];
  }
  return useMemo(() => ({
    records: records.current,
    sections: records.current.reduce((items, item) => {
      const date = formatFromISO(item.time, 'yyyy-MM-dd');

      let subitem = items.length && items[items.length - 1];
      if (!subitem || subitem.date !== date) {
        subitem = { date, items: [] };
        items.push(subitem);
      }

      subitem.items.push(item);
      return items;
    }, [])
  }), [records.current]);
}

import { Button, Modal, Form } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { WizardDialog, useWizardDialog } from 'src/components/base/WizardDialog';
import { SpinnerButton } from 'src/components/base/Elements';
import { validatorResolver } from 'src/utils/validator';
import { BillingAddress } from 'src/components/Payment/BillingAddress';
import { PaymentInfo } from 'src/components/Payment/PaymentInfo';
import { BillingAddressInfo } from 'src/components/Payment/BillingAddressInfo';
import { useProcessStripePayment } from 'src/components/Payment/paymentHook';
import { useWorkspace } from 'src/model/workspaces';
import { useEnableSMS } from 'src/model/settings';
import { useAtom } from 'src/hooks/action';

import { PAYMENT_TYPE_CARD, PAYMENT_TYPE_BANK } from 'src/constants';

const PAGE_INFO = 'INFO';
const PAGE_BILLING = 'BILLING';
const PAGE_PAYMENT = 'PAYMENT';
const PAGE_CHECKOUT = 'CHECKOUT';

export const NotificationBilling = withModalDialog(({ onClose }) => {
  const [atomAction, progress] = useAtom();
  const { billing_details } = useWorkspace();

  const [enableSMSAction] = useEnableSMS();
  const [processStripePaymentAction] = useProcessStripePayment();

  const { currentPage, options: { title, nextButton }, nextPage } = useWizardDialog(async function* (page) {
    yield page(PAGE_INFO, {}, { title: __('Confirmation') });
    const details = yield page(PAGE_BILLING, { billing_details }, { title: __('Billing Information') });

    const source = yield page(PAGE_PAYMENT, { billing_details: details }, { title: __('Payment Information') });
    yield page(PAGE_CHECKOUT, { details, source }, { title: __('Order summary'), nextButton: __('Checkout') });

    await atomAction(async () => {
      Object.assign(source, { billing_details: details });
      const { data, error } = await enableSMSAction({ enable: true, source });
      if (error) { return }

      const result = await processStripePaymentAction(data);
      if (result.error) { throw new Error(result.error) }

      if (result.payment_intent_id) {
        await enableSMSAction({ enable: true, payment_intent_id: result.payment_intent_id });
      }
    });

    onClose();
  });

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <WizardDialog.Page pageKey={PAGE_INFO} component={FirstPage} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_BILLING} component={BillingAddress} {...currentPage} />
        <WizardDialog.Page pageKey={PAGE_PAYMENT} component={PaymentInfo} {...currentPage} allowedTypes={[PAYMENT_TYPE_CARD, PAYMENT_TYPE_BANK]} />
        <WizardDialog.Page pageKey={PAGE_CHECKOUT} component={ConfirmationForm} {...currentPage} />
      </Modal.Body>

      <Modal.Footer>
        <Button disabled={progress} variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>
        <SpinnerButton animated={progress} onClick={nextPage}>{nextButton}</SpinnerButton>
      </Modal.Footer>
    </>

  )
}, { size: 'lg' });

export const DisableNotificationBilling = withModalDialog(({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({ password: 'required' })
  });

  const [enableSMSAction, progress] = useEnableSMS();

  async function handleSubmitForm({ password }) {
    await enableSMSAction({ enable: false, password });
    onClose();
  }

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} role="form">
      <Modal.Header>
        <Modal.Title>{__('Deactivate Text Messaging')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="password">
          <Form.FloatingLabel label={__('Password')}>
            <Form.Control type="password" name="current-password" placeholder={__('Enter password')}
              {...register('password')} isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{__('Minimum 6 characters')}</Form.Control.Feedback>
          </Form.FloatingLabel>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton animated={progress} variant="danger" type="submit">{__('Confirm')}</SpinnerButton>
      </Modal.Footer>
    </Form>
  )
});

const FirstPage = ({ registerNext }) => {
  registerNext(submit => submit({}));

  return <div>
    {__('If you confirm your order, you will receive SMS text messages from MinkPolice according to your settings in the “Notifications” tab to all inserted phone numbers. Please check your settings to receive text messages to the numbers you want to inform by SMS text messages. The SMS text messaging function will be invoiced according to actual consumption with a price of 0,09€/SMS. We will send you an invoice every 6 months or as soon as you reach the 60€ payment limit.')}
  </div>
}

const ConfirmationForm = ({ registerNext, data: { details } }) => {
  registerNext(submit => submit({}));
  return (
    <>
      <BillingAddressInfo details={details} />
    </>
  )
}
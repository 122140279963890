import { observer } from 'mobx-react-lite';
import { Alert } from 'react-bootstrap';
import { useFormatLocalization } from 'src/utils/hooks';
import { useCurrentWorkspace } from 'src/model/workspaces';

import classes from './WorkspaceBanner.module.scss';

export const WorkspaceBanner = observer(() => {
  const { workspace: { workspace_id, owner_email }, setCurrentWorkspace } = useCurrentWorkspace();
  const title = useFormatLocalization(__('You are viewing as {email}.'), { email: owner_email }, [owner_email]);

  function handleClick(event) {
    event.preventDefault();
    setCurrentWorkspace();
  }

  if (!workspace_id) { return null }

  return (
    <Alert variant="warning" className={classes.root}>
      <span>{title}</span>&nbsp;
      <a href="#" className="alert-link" onClick={handleClick}>{__('Revert to own profile view.')}</a>
    </Alert>
  )
})
import { useMemo, useCallback, useState } from 'react';
import { Form, ButtonToolbar } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';
import { SearchField } from 'src/components/base/Elements';

import { useFetchTraps } from 'src/model/traps';
import { ITEMS_PER_PAGE } from 'src/constants';

export const TrapSelector = ({ workspace_id, filter, selectedRowIds, onSelectedRowsChange, pageSize = ITEMS_PER_PAGE }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [sortBy, setSortBy] = useState({});
  const [search, setSearch] = useState('');

  const { data } = useFetchTraps({ workspace_id, pageIndex, pageSize, sortBy, filter, search });

  function handleFetchData({ pageIndex, sortBy }) {
    setPageIndex(pageIndex);
    setSortBy(sortBy);
  }

  function handleOnSearch(value) {
    setPageIndex(0);
    setSearch(value);
  }

  const columns = useMemo(
    () => [
      {
        Header: __('Selection'),
        accessor: 'group_id',
        disableSortBy: !filter,
        Cell: ({ row }) => <Form.Check type='checkbox' {...row.getToggleRowSelectedProps()} indeterminate="false" />
      },
      {
        Header: __('Trap'),
        accessor: 'trap_name',
      },
      {
        Header: __('Notes'),
        accessor: 'notes',
      },
    ],
    []
  );

  const getRowProps = useCallback(({ toggleRowSelected }) => (
    {
      onClick: () => toggleRowSelected(),
      className: 'cursor-pointer',
    }
  ), []);

  const getRowId = useCallback(({ trap_id }) => trap_id, [])

  return (
    <>
      <ButtonToolbar className="justify-content-between mb-3">
        <SearchField style={{ width: 350 }} value={search} onSearch={handleOnSearch} placeholder={__('Search (name, serial#, note)')} />
      </ButtonToolbar>

      <Table columns={columns} data={data.items} getRowProps={getRowProps}
        pageIndex={pageIndex} pageCount={data.pageCount} pageSize={pageSize} sortBy={sortBy}
        fetchData={handleFetchData}
        selectedRowIds={selectedRowIds} onSelectedRowsChange={onSelectedRowsChange}
        tableOptions={{ getRowId }}
      />
    </>
  )
}

import { runInAction, makeAutoObservable } from 'mobx';
import { fetchUser, fetchWorkspaces, fetchWorkspace, updateWorkspace } from 'src/api';
import { reflectAction } from 'src/utils/helpers';
class SystemStore {

  requestCount = 0;

  token = undefined;
  user = {};
  currentWorkspace = undefined;
  workspace = {};
  workspaces = [];

  lastVisitedGroup = 0;

  toast = {};

  get isAuthorised() {
    return !!this.token;
  }

  get isRequest() {
    return this.requestCount > 0;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async initilize(token) {
    if (!token) { return runInAction(() => this.token = token) }

    const [user, workspace, workspaces] = await Promise.all([fetchUser(token), fetchWorkspace({}, token), fetchWorkspaces({}, token)]);
    runInAction(() => {
      this.user = user;
      this.workspace = workspace;
      this.workspaces = workspaces;
      this.token = token;
    })
  }

  clean() {
    this.token = '';
    this.user = {};
    this.workspace = {};
    this.workspaces = [];
  }

  setCurrentWorkspace(id) {
    this.currentWorkspace = id;
  }

  async refreshWorkspaces() {
    const workspaces = await fetchWorkspaces({}, this.token);

    runInAction(() => {
      this.workspaces = workspaces;
    })
  }

  async refreshWorkspace() {
    const workspace = await fetchWorkspace({}, this.token);

    runInAction(() => {
      this.workspace = workspace;
    })
  }

  async updateWorkspace(data) {
    await updateWorkspace(data, this.token);
    const workspace = await fetchWorkspace({}, this.token);

    runInAction(() => {
      this.workspace = workspace;
    })
  }

  setToast(toast) {
    this.toast = toast;
  }

  setLastVisitedGroup(id) {
    this.lastVisitedGroup = id;
  }

  startRequest() {
    ++this.requestCount;
  }

  stopRequest() {
    if (this.requestCount > 0) {
      --this.requestCount;
    }
  }
}

export const systemStore = new SystemStore();
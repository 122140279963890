import qs from 'qs';
import { useMemo, useCallback, useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { substitute } from 'src/utils/helpers';

export function useEffectState(initialState, deps) {
  const mountRef = useRef(false);
  const [value, setValue] = useState(initialState);

  useEffect(() => {
    mountRef.current && setValue(typeof initialState === 'function' ? initialState() : initialState);
    mountRef.current = true;
  }, deps);
  return [value, setValue];
}

export function useUpdateEffect(effect, deps) {
  const mountRef = useRef(false);

  return useEffect(() => {
    const mounted = mountRef.current;
    mountRef.current = true;
    return mounted ? effect() : () => { };
  }, deps);
}

export function useFetchLocation() {
  const { location, push } = useHistory();
  const { p, a, d, s, f } = qs.parse(location.search, { ignoreQueryPrefix: true });

  const setFetchLocation = ({ pageIndex, sortBy, search, filter }) => {
    const query = {
      p: pageIndex > 0 ? pageIndex + 1 : undefined,
      a: sortBy?.desc === false ? sortBy?.id : undefined,
      d: sortBy?.desc === true ? sortBy?.id : undefined,
      s: !!search ? search : undefined,
      f: !!filter ? filter : undefined,
    };

    if (query.p != p || query.a != a || query.d != d || query.s !== s || query.f !== f) {
      push(`${location.pathname}${qs.stringify(query, { addQueryPrefix: true })}`);
    }
  }

  return useMemo(() => {
    const pageIndex = p ? (+p - 1) : 0;
    const sortBy = (a || d) ? { id: a || d, desc: !!d } : {};
    return { pageIndex, sortBy, search: s, filter: f, setFetchLocation };
  }, [p, a, d, s, f]);
}

export function useReplaceHistory(location) {
  const { replace } = useHistory();
  return useCallback(override => replace(override || location), [location]);
}

export function useFormatLocalization(text, value, deps) {
  return useMemo(() => substitute(text, value), deps ?? []);
}
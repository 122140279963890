import { useState, useEffect, useRef } from 'react';
import { Row, Col, Form, Modal, Button, FloatingLabel } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FooterRow, SpinnerButton } from 'src/components/base/Elements';
import { Card } from 'src/components/base/Card';
import { validatorResolver } from 'src/utils/validator';
import { useEffectState, useUpdateEffect } from 'src/utils/hooks';
import { useModalDialog, withModalDialog } from 'src/components/base/ModalDialog';
import { BillingAddress } from 'src/components/Payment/BillingAddress';
import { toastSuccess } from 'src/utils/toast';
import { useSystemStore } from 'src/stores';
import { useUpdateUser, useDeleteAccount } from 'src/model/user';
import { useWorkspace, useUpdateWorkspace } from 'src/model/workspaces';
import { useFetchSettings, useUpdateSettings, useFetchTimeZones, useFetchWebhook, useSaveWebhook } from 'src/model/settings';
import { useCountries } from 'src/hooks/countries';

export const Account = () => {
  const { user } = useSystemStore();
  const [reset, setReset] = useState(0);

  const { billing_details = {} } = useWorkspace();
  const { data: settings, mutate } = useFetchSettings();
  const [saveSettingsAction] = useUpdateSettings(mutate);

  const { data: timezones } = useFetchTimeZones();
  const [timezone, setTimezone] = useEffectState(settings?.timezone, [settings, reset]);

  const changeNameDialog = useModalDialog();
  const changeEmailDialog = useModalDialog();
  const changePasswordDialog = useModalDialog();
  const deleteAccountDialog = useModalDialog();
  const billingDialog = useModalDialog();
  const integrationDialog = useModalDialog();

  async function handleChangeNameClick() {
    await changeNameDialog.show();
  }

  async function handleChangeEmailClick() {
    await changeEmailDialog.show();
  }

  async function handleChangePasswordClick() {
    await changePasswordDialog.show();
  }

  async function handleDeleteAccountClick() {
    await deleteAccountDialog.show();
  }

  async function handleBillingInfoClick() {
    await billingDialog.show();
  }

  async function handleIntegrationClick() {
    await integrationDialog.show();
  }

  async function handleSaveChanges() {
    const { error } = await saveSettingsAction({ timezone });
    if (!error) { toastSuccess(__('Your changes were saved.')) }
  }

  function handleCancel() {
    setReset(reset + 1);
  }

  return (
    <>
      <Row>
        <Col lg={6} md={12}>
          <Card title={__('User Account Settings')}>
            <div className='d-flex flex-row justify-content-between align-items-baseline'>
              <p className='text-truncate'>{__('Account Name')}: {user?.user_name ?? '-'}</p>
              <Button onClick={handleChangeNameClick}>{__('Edit')}</Button>
            </div>

            <div className='d-flex flex-row justify-content-between align-items-baseline'>
              <p>{__('Account Email Address')}: {user?.email ?? '-'}</p>
              <Button onClick={handleChangeEmailClick}>{__('Edit')}</Button>
            </div>

            <div className="mt-3 mb-4">
              <FloatingLabel label={__('Time Zone')}>
                <Form.Select as="select" value={timezone} onChange={event => setTimezone(event.target.value)}>
                  {timezones.map(({ key, title }) => <option key={key} value={key}>{title} {key}</option>)}
                </Form.Select>
              </FloatingLabel>
            </div>

            <div className='d-flex flex-row gap-col-3'>
              <Button onClick={handleChangePasswordClick}>{__('Change Password')}</Button>
              <Button variant="danger" onClick={handleDeleteAccountClick}>{__('Delete Account')}</Button>
            </div>
          </Card>
        </Col>

        <Col lg={6} md={12}>
          <Card title={__('Billing Information')}>
            {billing_details.hasOwnProperty('name') && <BillingInfo details={billing_details} className='mb-3' />}
            <Button className='w-min' onClick={handleBillingInfoClick}>{__('Edit')}</Button>
          </Card>

          <Card title={__('Integrations')}>
            <Button className='w-min' onClick={handleIntegrationClick}>{__('Edit')}</Button>
          </Card>
        </Col>
      </Row>

      <FooterRow>
        <Button variant="secondary" onClick={handleCancel}>{__('Cancel')}</Button>
        <Button variant="primary" onClick={handleSaveChanges}>{__('Save Changes')}</Button>
      </FooterRow>

      <ChangeNameDialog {...changeNameDialog.register()} />
      <ChangeEmailDialog {...changeEmailDialog.register()} />
      <ChangePasswordDialog {...changePasswordDialog.register()} />
      <DeleteAccountDialog  {...deleteAccountDialog.register()} />
      <BillingInfoDialog {...billingDialog.register()} />
      <IntegrationDialog {...integrationDialog.register()} />
    </>
  )
}


const ChangeNameDialog = withModalDialog(({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({
      user_name: 'required',
    })
  });

  const [updateUserAction] = useUpdateUser();

  async function onSubmit(data) {
    const { error, data: result } = await updateUserAction(data);
    if (error) { return }

    toastSuccess(result?.text ?? __('Account name was succesfully changed'));
    onClose(true);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} role="form">

      <Modal.Header closeButton>
        <Modal.Title>{__('Change Account Name')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="new-user-name">
          <FloatingLabel label={__('New Account Name')}>
            <Form.Control type="text" name="new-user-name" placeholder={__('New Account Name')}
              {...register('user_name')} isInvalid={!!errors.user_name}
            />
            <Form.Control.Feedback type="invalid">{__('Account name format is invalid')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton variant="primary" type="submit">{__('Save Changes')}</SpinnerButton>
      </Modal.Footer>
    </Form>
  )
});

const ChangeEmailDialog = withModalDialog(({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({
      email: ['required', 'email'], password: 'required',
    })
  });

  const [updateUserAction] = useUpdateUser();

  async function onSubmit(data) {
    const { error, data: result } = await updateUserAction(data);
    if (error) { return }

    toastSuccess(result?.text ?? __('Login email address was succesfully changed'));
    onClose(true);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} role="form">

      <Modal.Header closeButton>
        <Modal.Title>{__('Change Account Email Address')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{__('Please enter your password to confirm that you wish to change your login email address. After entering a new account email address, you will receive an email with a confirmation link.')}</p>

        <Form.Group className="mb-3" controlId="new-email">
          <FloatingLabel label={__('New Account Email Address')}>
            <Form.Control type="text" name="new-email" placeholder={__('New Account Email Address')}
              {...register('email')} isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">{__('Email format is invalid')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-3" controlId="current-password">
          <FloatingLabel label={__('Password')}>
            <Form.Control type="password" placeholder={__('Enter password')}
              name="current-password" autoComplete="current-password"
              {...register('password')} isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{__('Password is required')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton variant="primary" type="submit">{__('Save Changes')}</SpinnerButton>
      </Modal.Footer>
    </Form>
  )
});

const ChangePasswordDialog = withModalDialog(({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({
      password: ['required', 'password'], password_new: ['required', 'password'], password_new2: ['required', { equal_to_field: 'password_new' }],
    })
  });

  const [updateUserAction] = useUpdateUser();

  async function onSubmit(data) {
    const { error } = await updateUserAction({ password: data.password, password_new: data.password_new });
    if (!error) {
      toastSuccess(__('Password was succesfully changed'));
      onClose(true);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} role="form">

      <Modal.Header closeButton>
        <Modal.Title>{__('Change Password')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="current-password">
          <FloatingLabel label={__('Current password')}>
            <Form.Control type="password" placeholder={__('Current password')}
              name="current-password" autoComplete="current-password"
              {...register('password')} isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{__('Password is required')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-4" controlId="password_new">
          <FloatingLabel label={__('New Password (minimum 6 characters)')}>
            <Form.Control type="password" name="new-password" placeholder={__('New Password (minimum 6 characters)')}
              {...register('password_new')} isInvalid={!!errors.password_new}
            />
            <Form.Control.Feedback type="invalid">{__('Minimum 6 characters')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

        <Form.Group className="mb-4" controlId="password_new2">
          <FloatingLabel label={__('Repeat Password')}>
            <Form.Control type="password" name="new-password" placeholder={__('Repeat Password')}
              {...register('password_new2')} isInvalid={!!errors.password_new2}
            />
            <Form.Control.Feedback type="invalid">{__('Passwords do not match')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton variant="primary" type="submit">{__('Save Changes')}</SpinnerButton>
      </Modal.Footer>
    </Form>
  )
});

const DeleteAccountDialog = withModalDialog(({ onClose }) => {
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: validatorResolver({ password: ['required', 'password'] })
  });

  const [deleteAccountAction] = useDeleteAccount();

  async function onSubmit(data) {
    await deleteAccountAction(data);
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} role="form">

      <Modal.Header closeButton>
        <Modal.Title>{__('Delete Account - Attention!')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <p>{__(`By deleting the account you will loose all your data. This operation can not be undone. Enter your password to confirm that it is your account and you really want to delete your account.`)}</p>

        <Form.Group className="mb-3" controlId="current-password">
          <FloatingLabel label={__('Password')}>
            <Form.Control type="password" placeholder={__('Password')}
              name="current-password" autoComplete="current-password"
              {...register('password')} isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">{__('Password is required')}</Form.Control.Feedback>
          </FloatingLabel>
        </Form.Group>
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton variant="danger" type="submit">{__('Delete Account')}</SpinnerButton>
      </Modal.Footer>
    </Form>
  )
});

const WEBHOOK_NONE = 'none';
const WEBHOOK_REVIERWELT = 'revierwelt';
const WEBHOOK_WEBHOOK = 'webhook';

const IntegrationDialog = withModalDialog(({ onClose }) => {
  const { register, handleSubmit, formState: { errors }, ...form } = useForm({
    resolver: validatorResolver({
      type: { one_of: [WEBHOOK_NONE, WEBHOOK_REVIERWELT, WEBHOOK_WEBHOOK] },
      url: [{ required_if: { type: WEBHOOK_WEBHOOK } }, 'url'],
    }),
    defaultValues: { type: WEBHOOK_NONE },
  });

  const { data, mutate } = useFetchWebhook();
  const [saveWebhookAction] = useSaveWebhook(mutate);

  const type = form.watch('type');
  useEffect(() => { form.reset(data) }, [data]);
  useUpdateEffect(() => { type !== WEBHOOK_WEBHOOK && form.setValue('url', '') }, [type]);

  async function onSubmit(data) {
    const { error } = await saveWebhookAction(data);
    if (!error) {
      toastSuccess(__('Your changes were saved.'));
      onClose(true);
    }
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)} role="form">
      <Modal.Header closeButton>
        <Modal.Title>{__('Integrations')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>

        <Form.Group className='pb-3'>
          <Form.Check type="radio" value={WEBHOOK_NONE} label={__('None')} id={WEBHOOK_NONE} {...register('type')} />
        </Form.Group>

        {MINKS_BRAND === 'minkpolice' &&
          <Form.Group className='pb-3'>
            <Form.Check type="radio" value={WEBHOOK_REVIERWELT} label={__('Revierwelt')} id={WEBHOOK_REVIERWELT} {...register('type')} />
          </Form.Group>
        }

        {/* <div className='d-flex flex-row justify-content-between align-items-baseline'>
          <Form.Group className='me-4'>
            <Form.Check type="radio" value={WEBHOOK_WEBHOOK}
              label={`${__('Other')}:`} id={WEBHOOK_WEBHOOK}
              {...register('type')}
            />
          </Form.Group>

          <Form.Group className="w-100" controlId="url">
            <Form.Control type="text" name="webhook" placeholder={__('Enter webhook here')}
              {...register('url')} isInvalid={!!errors.url} disabled={type !== WEBHOOK_WEBHOOK}
            />
            <Form.Control.Feedback type="invalid">{__('Valid url is required (https://domainname/path)')}</Form.Control.Feedback>
          </Form.Group>
        </div> */}

      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton variant="primary" type="submit">{__('Save Changes')}</SpinnerButton>
      </Modal.Footer>
    </Form>
  )
}, { size: 'lg' });

const BillingInfo = ({ className, details }) => {
  const TYPES = { private: __('Private'), company: __('Company') };
  const { allCountries, iso2Lookup } = useCountries();

  const { name, address, customer_type, vat } = details;
  return (
    <div className={className}>
      <div>{name}</div>
      <div>{address?.line1}</div>
      <div>{address?.city}</div>
      <div>{allCountries[iso2Lookup[address.country]]?.name}</div>
      <div className='mt-3'>{TYPES[customer_type]} {vat && <span>{__('VAT number')} {vat}</span>}</div>
    </div>
  )
}

const BillingInfoDialog = withModalDialog(({ onClose }) => {
  const submitRef = useRef();
  const { billing_details = {} } = useWorkspace();
  const [updateWorkspaceAction, progress] = useUpdateWorkspace();

  function registerNext(submit) {
    submitRef.current = submit;
  }

  function handleSubmitClick() {
    submitRef.current(async (billing_details) => {
      const { error } = await updateWorkspaceAction({ billing_details });
      if (!error) { onClose() }
    });
  }

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>{__('Billing Information')}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <BillingAddress registerNext={registerNext} data={{ billing_details }} />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>{__('Cancel')}</Button>
        <SpinnerButton animated={progress} variant="primary" onClick={handleSubmitClick}>{__('Submit')}</SpinnerButton>
      </Modal.Footer>
    </>
  )
}, { size: 'lg' });

export const PriceCell = ({ item: { sim_ccid, current_sim_subscription_type, payment_plan_parameters } }) => {
  if (!sim_ccid && current_sim_subscription_type === 'MP10') { return null }

  const symbols = { eur: '€', usd: '$' };
  const { cost = 0, currency = 'eur' } = payment_plan_parameters || {};

  return <span>{cost}{symbols[currency] || currency}</span>;
}

export const SubscriptionStatus = ({ status, is_paused }) => {
  return { active: __('Active'), blocked: __('Expired'), paused: __('Paused') }[is_paused ? 'paused' : status];
}
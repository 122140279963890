import { useStripe } from '@stripe/react-stripe-js';
import { sleep } from 'src/utils/helpers';
import { PAYMENT_TYPE_BANK, PAYMENT_TYPE_CARD, PAYMENT_TYPE_SEPA, PAYMENT_TYPE_SOFORT, PAYMENT_TYPE_VBAN } from 'src/constants';

export function useProcessStripePayment() {
  const stripe = useStripe();

  return [
    async ({ type, ...data }) => {
      const result = {};

      if (type === PAYMENT_TYPE_CARD) {
        const { payment_intent: { status, client_secret } } = data;

        if (status === 'requires_action') {
          const { error, paymentIntent } = await stripe.handleCardAction(client_secret);
          Object.assign(result, { error, payment_intent_id: paymentIntent?.id });
        }
      }
      else if (type === PAYMENT_TYPE_SOFORT) {
        window.location = data.redirect_url;
        await sleep(10000);
      }
      else if (type === PAYMENT_TYPE_BANK) {
        Object.assign(result, { payment_code: data.payment_code });
      }
      else if (type === PAYMENT_TYPE_SEPA) {
        console.log(data);
        Object.assign(result, { payment_details: data.payment_details });
      }
      else if (type === PAYMENT_TYPE_VBAN) {
        Object.assign(result, { payment_details: data.payment_details });
      }
      return result;
    }
  ]
}
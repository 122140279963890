import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { withModalDialog } from 'src/components/base/ModalDialog';
import { MapView, Marker, withLoadedMap } from 'src/components/base/MapView';
import { isValidPosition } from 'src/utils/helpers';
import { useEffectState } from 'src/utils/hooks';

import marker from 'assets/svg/marker_with-module_2x.svg';
import classes from './LocationDialog.module.scss';

export const LocationDialog = withLoadedMap(withModalDialog(({ location, title, onClose }) => {
  const [position, setPosition] = useEffectState(isValidPosition(location) ? location : config.map.currentLocation, [location?.lat, location?.lng]);
  const options = useMemo(() => ({
    mapTypeControlOptions: {
      position: google.maps.ControlPosition.RIGHT_BOTTOM
    }
  }), []);

  return (
    <section className={classes.root}>
      <MapView zoom={10} center={location} options={options}>
        <Marker position={position} icon={{ url: marker }} draggable={true}
          onDrag={({ latLng }) => setPosition({ lat: +latLng.lat().toFixed(7), lng: +latLng.lng().toFixed(7) })}
        />
      </MapView>

      <div className={classes.controlPanel}>
        <div className="d-flex flex-row justify-content-between align-items-center w-100">
          <div className="d-flex flex-column text-light">
            <span className="fs-4 fw-bold ">{__('Edit Location')}</span>
            <span className="lh-1">{title}</span>
          </div>
          <div className="d-flex flex-column fs-5 align-items-end text-light">
            <span>{__('Latitude')}: {position.lat}</span>
            <span>{__('Longitude')}: {position.lng}</span>
          </div>
          <div>
            <Button variant="secondary" className="me-2" onClick={() => onClose()}>{__('Cancel')}</Button>
            <Button onClick={() => onClose(position)}>{__('Save Changes')}</Button>
          </div>
        </div>
      </div>
    </section>
  )
}, { fullscreen: true, animation: false }))
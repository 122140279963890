import { useRef, useState } from 'react';

export function useWizardDialog(generator, defs = { nextButton: __('Next') }) {
  const submitRef = useRef();
  const [state, setCurrentState] = useState({ activePageKey: null, registerNext: null, data: {}, options: defs });

  function page(pageKey, data, options) {
    submitRef.current = null;
    setCurrentState({
      activePageKey: pageKey,
      data, options: { ...defs, ...options },
      registerNext: (submit) => {
        submitRef.current = submit;
      }
    });
  }

  const generatorRef = useRef();
  if (!generatorRef.current) {
    generatorRef.current = generator(page);
    generatorRef.current.next();
  }

  const { options, ...currentPage } = state;
  return {
    currentPage, options,
    nextPage: () => submitRef.current && generatorRef.current && submitRef.current((result) => generatorRef.current.next(result)),
  };
}

export const WizardDialog = {};

WizardDialog.Page = ({ activePageKey, pageKey, component: Component, ...props }) => activePageKey === pageKey && <Component {...props} />;

import { forwardRef } from 'react';
import cls from 'classnames';
import PhoneInput2 from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';
import './PhoneInput.module.scss';

export const PhoneInput = forwardRef((props, ref) => (
  <PhoneInput2
    country={'de'} ref={ref}
    preferredCountries={config.general.preferredCountries}
    containerClass={cls({ 'is-invalid': props.isInvalid })}
    inputClass={cls({ 'is-invalid': props.isInvalid })}
    {...props}
  />
))

import cls from 'classnames';
import { Pane } from 'src/components/base/Elements';

export const Card = ({ title, children, className }) => (
  <Pane className={cls('p-4 mb-4', className)}>
    <h2 className="mb-4">{title}</h2>
    {children}
  </Pane>
)

Card.Body = ({ children, className }) => (
  <div className={className}>
    {children}
  </div>
)

import { useMemo } from 'react';
import { fetchCatches, createCatch, saveCatch, deleteCatch, fetchCatchStats } from 'src/api';

import { useSWR } from 'src/utils/swr';
import { useAction } from 'src/hooks/action';
import { useSystemStore } from 'src/stores';

export function useFetchCatches({ pageIndex, sortBy }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [], pageCount: 0 }), []);

  return useSWR(['/catches', pageIndex, sortBy.id, sortBy.desc],
    () => fetchCatches({ pageIndex, sortBy }, token),
    { defaultValue }
  );
}

export function useFetchCatcheStats({ group_id, time_from, time_to }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ([]), []);

  return useSWR(['/catchesstats', group_id, time_from, time_to],
    () => fetchCatchStats({ group_id, time_from, time_to }, token),
    { defaultValue }
  );
}

export function useCreateOrUpdateCatch(mutate) {
  const { token } = useSystemStore();
  return useAction(data => data.history_id ? saveCatch(data, token) : createCatch(data, token), mutate);
}

export function useDeleteCatch(mutate) {
  const { token } = useSystemStore();
  return useAction(data => deleteCatch(data, token), mutate);
}


import { useMemo, useCallback, forwardRef } from 'react';
import { GoogleMap, Marker, MarkerClusterer, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
import { isValidPosition } from 'src/utils/helpers';

const styles = [
  {
    featureType: 'poi',
    elementType: 'labels',
    stylers: [{ visibility: 'off' }]
  },
  {
    featureType: 'transit',
    stylers: [{ visibility: 'off' }]
  }
]

export const MapView = forwardRef(({ zoom = 10, center: initialCenter, children, options, ...props }, ref) => {
  const center = useMemo(() => isValidPosition(initialCenter) ? initialCenter : config.map.currentLocation, [initialCenter?.lat, initialCenter?.lng]);

  const onLoad = useCallback(function callback(map) {
    if (ref) { ref.current = map }
  }, []);

  const onUnmount = useCallback(function callback(map) {
    if (ref) { ref.current = null }
  }, []);

  const mapOptions = useMemo(() => ({ ...options, styles: styles, gestureHandling: 'greedy' }), [options]);
  const mapContainerStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

  return (
    <GoogleMap
      zoom={zoom} center={center}
      options={mapOptions}
      mapContainerStyle={mapContainerStyle}
      onLoad={onLoad} onUnmount={onUnmount} {...props}
    >
      {children}
    </GoogleMap>
  )
})

export const MarkerSymbols = {
  normal: {
    path: 0, // google.maps.SymbolPath.CIRCLE
    fillColor: '#639840',
    fillOpacity: 1,
    strokeColor: '#3e5c2a',
    strokeWeight: 2,
    scale: 6
  },
  alarm: {
    path: 0, // google.maps.SymbolPath.CIRCLE
    fillColor: '#ff8000',
    fillOpacity: 1,
    strokeColor: '#aa5602',
    strokeWeight: 2,
    scale: 6
  },
  high: {
    path: 0, // google.maps.SymbolPath.CIRCLE
    fillColor: '#ff8000',
    strokeColor: '#ff8000',
    fillOpacity: 0,
    strokeWeight: 6,
    scale: 6
  },
}

export function withLoadedMap(WrappedComponent) {
  return forwardRef((props, ref) => {
    const { isLoaded } = useJsApiLoader({ id: 'google-map-script', googleMapsApiKey: config.map.key, language: config.lang });
    return isLoaded && <WrappedComponent ref={ref} {...props} />;
  })
}

export { Marker, MarkerClusterer, InfoWindow };
import { useState, useMemo, memo, useCallback, useEffect, useRef, forwardRef } from 'react';
import { NavLink, generatePath } from 'react-router-dom';
import { MapView, Marker, MarkerClusterer, InfoWindow, MarkerSymbols, withLoadedMap } from 'src/components/base/MapView';
import { isValidPosition } from 'src/utils/helpers';
import { EventPriority } from 'src/components/Trap/Elements';

import classnames from './MapPanel.module.scss';

export const MapPanel = withLoadedMap(({ items, editMode, selectedItem, onSelectItem, onCenterChanged }) => {
  const mapRef = useRef();
  const [selected, setSelected] = useState();
  const [draggable, setDraggable] = useState();

  const clustersVersion = useRef(0);
  const getClusterKey = ({ lat, lng }) => `${lat.toFixed(5)}/${lng.toFixed(5)}`;
  const clusters = useMemo(() => items.reduce((clusters, item) => {
    if (isValidPosition(item.changed)) {
      const key = getClusterKey(item.changed);
      const items = clusters.get(key) || (clusters.set(key, []), clusters.get(key));
      items.push(item);
    }
    return clusters;
  }, new Map()), [items, clustersVersion.current]);

  const { markers, bounds } = useMemo(() => {
    let bounds = new google.maps.LatLngBounds();

    const markers = items.reduce((markers, item) => {
      if (isValidPosition(item.changed)) {
        bounds.extend(item.changed);
        markers.push(item);
      }
      return markers;
    }, []);
    return { markers, bounds };
  }, [items]);

  useEffect(() => { mapRef.current && mapRef.current.fitBounds(bounds) }, [mapRef.current, items.length > 0]);

  useEffect(() => {
    if (!mapRef.current) { return }

    if (selectedItem && isValidPosition(selectedItem.changed)) {
      if (!selected || selected[0] !== selectedItem) {
        setSelected([selectedItem]);
        mapRef.current.setCenter(selectedItem.changed);
      }
    }
    else {
      setSelected(null);
    }
  }, [mapRef.current, selectedItem]);

  const handleCenterChanged = useCallback(() => {
    if (!mapRef.current || !onCenterChanged) { return }

    const center = mapRef.current.getCenter();
    onCenterChanged({ lat: center.lat(), lng: center.lng() });
  }, [mapRef.current, onCenterChanged]);

  const handleMarkerDrag = useCallback((item, position) => {
    item.changed = position;
    setSelected([item]);
    setDraggable(position);
  }, [])

  const handleMarkerDragEnd = useCallback((item, position) => {
    item.changed = position;
    clustersVersion.current++;
    setDraggable(null);
  }, [])

  const handleMarkerClick = useCallback((item) => {
    setSelected(clusters.get(getClusterKey(item.changed)));
    onSelectItem(item);
  }, [clusters])

  const handleCloseInfo = useCallback(() => {
    setSelected(null);
  }, [])

  return (
    <div className="w-100 h100 position-relative">
      <MapView ref={mapRef} zoom={8} onCenterChanged={handleCenterChanged}>
        {selected && <TrapsInfo items={selected} onClose={handleCloseInfo} />}

        <ItemClusterer markers={markers} editMode={editMode}
          onMarkerDragStart={handleMarkerDrag} onMarkerDrag={handleMarkerDrag}
          onMarkerDragEnd={handleMarkerDragEnd} onMarkerClick={handleMarkerClick}
        />
      </MapView>

      {draggable && <LocationInfo position={draggable} />}
    </div>
  )
})

const ItemClusterer = memo(({ markers, editMode, onMarkerDragStart, onMarkerDrag, onMarkerDragEnd, onMarkerClick }) => (
  <MarkerClusterer averageCenter enableRetinaIcons gridSize={50} maxZoom={18} zoomOnClick={true}>
    {
      (clusterer) => markers.map((item) => (
        <Marker
          key={item.trap_id}
          item={item}
          clusterer={clusterer}
          position={item.changed}
          icon={MarkerSymbols[item.event_priority] || MarkerSymbols.normal}
          onDragStart={e => onMarkerDragStart(item, { lat: e.latLng.lat(), lng: e.latLng.lng() })}
          onDrag={e => onMarkerDrag(item, { lat: e.latLng.lat(), lng: e.latLng.lng() })}
          onDragEnd={e => onMarkerDragEnd(item, { lat: e.latLng.lat(), lng: e.latLng.lng() })}
          onClick={() => onMarkerClick(item)}
          draggable={editMode}
        />
      ))
    }
  </MarkerClusterer>
))

const TrapsInfo = ({ items, onClose }) => {
  return (
    <InfoWindow onCloseClick={onClose}
      position={items[0].changed} options={{ pixelOffset: new google.maps.Size(0, -8) }}
    >
      <table>
        <tbody>
          {
            items.map(({ trap_id, trap_name, event_priority }) => (
              <tr key={trap_id}>
                <td style={{ width: '16px' }}><EventPriority priority={event_priority} /></td>
                <td><NavLink to={generatePath('/trap/:trap_id', { trap_id })}>{trap_name}</NavLink></td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </InfoWindow>
  )
}

const LocationInfo = ({ position }) => (
  <div className={classnames.locationInfo}>
    <div className="d-flex flex-column justify-content-center align-items-end">
      <span>{`${__('Latitude')}: ${position.lat.toFixed(5)}`}</span>
      <span>{`${__('Longitude')}: ${position.lng.toFixed(5)}`}</span>
    </div>
  </div>
)

import cls from 'classnames';
import { observer } from 'mobx-react-lite';
import { useState, useMemo, useRef } from 'react';
import { Row, Col, Form, InputGroup, Button, FormControl } from 'react-bootstrap';
import { FooterRow } from 'src/components/base/Elements';
import { Card } from 'src/components/base/Card';
import { TooltipIcon, TooltipControl } from 'src/components/base/Tooltip';
import { useModalDialog } from 'src/components/base/ModalDialog';
import { PhoneInput } from 'src/components/base/PhoneInput';
import { NotificationBilling, DisableNotificationBilling } from './NotificationBilling';
import { useEffectState } from 'src/utils/hooks';
import { Validator } from 'src/utils/validator';
import { uniqueId } from 'src/utils/utils';
import { toastSuccess } from 'src/utils/toast';
import { formatFromISO } from 'src/utils/datefns';
import { useWorkspace } from 'src/model/workspaces';
import { useFetchSettings, useUpdateSettings, useResendEmailConfirmation, useFetchSMS } from 'src/model/settings';

import classes from './Notification.module.scss';

export const Notification = observer(() => {
  const [reset, setReset] = useState(0);
  const { enable_sms_recurring_payments = false } = useWorkspace();

  const { data: settings, mutate } = useFetchSettings();
  const [saveSettingsAction] = useUpdateSettings(mutate);

  const billingDialog = useModalDialog();
  const disableBillingDialog = useModalDialog();

  const [language, setLanguage] = useEffectState(() => settings.notifications_language ?? 'en', [settings, reset]);
  const [notifications, setNotifications] = useEffectState(() => settings.notifications ?? { my: { alert: {}, report: {} }, shared: { alert: {}, report: {} } }, [settings, reset]);

  const [emails, setEmails] = useEffectState(() => (settings.mails ?? []).map(({ mail_id: id, email: value, status }) => ({ id, value, status })), [settings, reset]);
  const [phones, setPhones] = useEffectState(() => (settings.phones ?? []).map(({ phone_id: id, phone: value }) => ({ id, value })), [settings, reset]);

  if (!settings.notifications) { return null }

  async function handleSaveChanges() {
    const { error } = await saveSettingsAction({
      notifications, notifications_language: language, mails: emails.map(({ value }) => value), phones: phones.map(({ value }) => value),
    });
    if (!error) { toastSuccess(__('Your changes were saved.')) }
  }

  function handleCancel() {
    setReset(reset + 1);
  }

  function handleNotificationsChange(type, key, options) {
    setNotifications({ ...notifications, [type]: { ...notifications[type], [key]: options } });
  }

  async function handleSMSEnableClick() {
    await (enable_sms_recurring_payments ? disableBillingDialog.show() : billingDialog.show());
  }

  return (
    <div className={classes.root}>
      <Row>
        <Col lg={6} md={12}>

          <Card title={__('Notification Options')} className={classes.options}>
            <Card.Body className={classes.optionsBody}>
              <div className={cls(classes.tableHeader, 'bg-primary')}>
                <span></span><span>Email</span><span>SMS</span>
              </div>

              <NotificationTable title={__('My Traps')}>
                <NotificationTable.NotificationRow title={__('Alarm')} className="border-bottom"
                  options={notifications.my.alert} disableSMS={!enable_sms_recurring_payments}
                  onChange={options => handleNotificationsChange('my', 'alert', options)}
                />

                <NotificationTable.NotificationRow title={__('Status')}
                  tooltip={__('Status notifications for your own devices will be sent according to your devices’ Wake Up Schedule, which can be reviewed and adjusted in the "Devices" tab.')}
                  options={notifications.my.report} disableSMS={!enable_sms_recurring_payments}
                  onChange={options => handleNotificationsChange('my', 'report', options)}
                />
              </NotificationTable>

              <NotificationTable title={__('Traps shared with me')}>
                <NotificationTable.NotificationRow title={__('Alarm')} className="border-bottom"
                  options={notifications.shared.alert} disableSMS={!enable_sms_recurring_payments}
                  onChange={options => handleNotificationsChange('shared', 'alert', options)}
                />

                <NotificationTable.NotificationRow title={__('Status')}
                  tooltip={__('Status notifications for devices shared with you through the User function will be sent at the Wake Up times the Group Owner set. You will receive a separate notification per inviting Group Owner.')}
                  options={notifications.shared.report} disableSMS={!enable_sms_recurring_payments}
                  onChange={options => handleNotificationsChange('shared', 'report', options)}
                />
              </NotificationTable>

            </Card.Body>
          </Card>
        </Col>

        <Col lg={6} md={12}>

          <Card title={__('Recipients')}>
            <div className="mb-4">
              <h5 className="title">{__('Email')}</h5>
              <EmailsSection items={emails} onChange={setEmails} />
            </div>

            <div>
              <h5 className="title d-flex align-items-center">
                <span>{__('SMS (incurring extra costs)')}</span>
                <Form.Check className="ps-5" type="switch" id="switch-sms">
                  <Form.Check.Input className='d-block mt-auto mb-auto' type={'checkbox'} checked={enable_sms_recurring_payments} onChange={handleSMSEnableClick} />
                </Form.Check>
              </h5>

              {!enable_sms_recurring_payments && <span className="text-muted">{__('SMS not activated')}</span>}
              {enable_sms_recurring_payments && <PhonesSection items={phones} onChange={setPhones} />}

              {enable_sms_recurring_payments && <SMSDetails />}

              <NotificationBilling {...billingDialog.register()} />
              <DisableNotificationBilling {...disableBillingDialog.register()} />

            </div>
          </Card>

          <Card title={__('Notification Language')}>
            <Form.Group controlId="groupLanguages">
              <Form.Select as="select" value={language} onChange={event => setLanguage(event.target.value)}>
                {Object.keys(settings.available_languages).map(key => <option key={key} value={key}>{settings.available_languages[key]}</option>)}
              </Form.Select>
            </Form.Group>
          </Card>

        </Col>
      </Row>

      <FooterRow>
        <Button variant="secondary" onClick={handleCancel}>{__('Cancel')}</Button>
        <Button variant="primary" onClick={handleSaveChanges}>{__('Save Changes')}</Button>
      </FooterRow>
    </div>
  )
})

const NotificationTable = ({ title, children }) => (
  <div className={classes.table}>
    <div className="title mb-3">{title}</div>
    <div>{children}</div>
  </div>
)

NotificationTable.NotificationRow = ({ title, tooltip, options, disableSMS, onChange, className }) => {
  function handleOnChange(key, value) {
    onChange({ ...options, [key]: value });
  }

  return (
    <div className={cls('pt-2 pb-2', classes.tableRow, className)}>
      <span className={cls('fs-6 d-flex flex-row align-items-baseline gap-col-2', classes.tableRowHeader)}>
        <span>{title}</span>
        {tooltip && <TooltipIcon>{tooltip}</TooltipIcon>}
      </span>

      <Form.Check type="checkbox" checked={options.email} onChange={event => handleOnChange('email', event.target.checked)} />
      <Form.Check type="checkbox" checked={!disableSMS && options.sms} disabled={disableSMS}
        onChange={event => handleOnChange('sms', event.target.checked)} />
    </div>
  )
}

const EmailsSection = ({ items, onChange }) => {
  const validate = useMemo(() => {
    const validator = new Validator({ value: ['required', 'email'] });
    return (value) => validator.validate({ value }) !== false;
  }, []);

  const [resendEmailConfirmationAction] = useResendEmailConfirmation();

  async function handleReactivateClick(email_id) {
    const { error } = await resendEmailConfirmationAction({ email_id });
    if (!error) { toastSuccess(__('A confirmation link has been sent.')) }
  }

  return (
    <NotificationCollection items={items} onChange={onChange} validate={validate}
      render={({ item: { id, value, status, isInvalid }, handleOnBlur, handleOnChange }) => (
        <>
          <FormControl value={value} isInvalid={isInvalid}
            type="text" placeholder={__('Email address')}
            onBlur={handleOnBlur} onChange={event => handleOnChange(event.target.value)}
          />
          {status === 'new' &&
            <TooltipControl tooltip={__('Resend confirmation link')}>
              <Button onClick={() => handleReactivateClick(id)}><i className="bi bi-arrow-clockwise"></i></Button>
            </TooltipControl>
          }
        </>
      )}
    />
  )
}

const PhonesSection = ({ items, onChange }) => (
  <NotificationCollection items={items} onChange={onChange}
    render={({ item: { value }, handleOnBlur, handleOnChange }) => (
      <PhoneInput value={value} onChange={handleOnChange} onBlur={handleOnBlur} />
    )}
  />
)

const NotificationCollection = ({ items, onChange, render, validate, max = 10 }) => {
  const lastItemKey = useRef(null);
  const [values, setValues] = useEffectState(() => initializeItems(items), [items]);

  function handleOnChange(index, value) {
    if (validate && values[index].isInvalid === true && validate(value)) {
      values[index].isInvalid = false;
    }
    setValues(values.map((item, i) => i !== index ? item : { ...item, value }));
  }

  function handleOnBlur() {
    onChange(validateItems(values));
  }

  function handleRemove(index) {
    values.splice(index, 1);
    onChange(validateItems(values));
  }

  function validateItems(items) {
    return items.reduce((items, item) => {
      if (!item.value) { return items }

      if (items.length < max) {
        items.push({ ...item, key: item.key ?? uniqueId(), isInvalid: !!validate && !validate(item.value) });
      }
      return items;
    }, []);
  }

  function initializeItems(items) {
    const values = validateItems(items);

    const item = values.length < max && { key: uniqueId(), value: '' };
    lastItemKey.current = item?.key;

    item && values.push(item);
    return values;
  }

  return (
    <div className={classes.destinations}>
      {values.map((item, index) => (
        <InputGroup key={item.key} className="flex-nowrap">
          {render({ item, handleOnChange: (value) => handleOnChange(index, value), handleOnBlur })}
          {item.key !== lastItemKey.current &&
            <InputGroup.Text onClick={() => handleRemove(index)}>
              <i className="bi bi-x-circle-fill pe-auto"></i>
            </InputGroup.Text>
          }
        </InputGroup>
      ))}
    </div>
  )
}

const SMSDetails = () => {
  const { data } = useFetchSMS();
  const { count = 0, from } = data?.sms ?? {};

  return (
    <div className='d-flex flex-column justify-content-center align-items-center mt-4'>
      <span className='fw-bold'>{count}</span>
      <span className='fs-sm'>{__('SMS sent since')}&nbsp;{formatFromISO(from, 'PP')}</span>
    </div>
  )
}
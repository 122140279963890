import { useMemo } from 'react';
import { AttributeTable } from 'src/components/base/Elements';
import { useCountries } from 'src/hooks/countries';

const TYPES = { private: __('Private'), company: __('Company') };

export const BillingAddressInfo = ({ details }) => {
  const { name, email, address, phone, customer_type, vat } = details;
  const { allCountries, iso2Lookup } = useCountries();

  const items = useMemo(() => [
    { title: __('Name / company'), value: name },
    { title: __('Email'), value: email },
    { title: __('Street and number'), value: address?.line1 },
    { title: __('City'), value: address?.city },
    { title: __('Country'), value: allCountries[iso2Lookup[address.country]]?.name },
    { title: __('Phone'), value: phone },
    { title: __('Customer Type'), value: TYPES[customer_type] },
    vat && { title: __('VAT number'), value: vat },
  ].filter(Boolean), [name, email, address, phone, customer_type, vat, allCountries]);

  return <AttributeTable items={items} />;
}
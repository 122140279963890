import { Button, Modal, Form, FloatingLabel } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { useModalDialog, withModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { DatePickerControl } from 'src/components/base/DatePicker';
import { parseISO, formatISO } from 'src/utils/datefns';
import { validatorResolver } from 'src/utils/validator';
import { useCreateOrUpdateTrapEvent, useDeleteTrapEvent } from 'src/model/traps';

export const EventOtherDialog = withModalDialog(({ title, notes = true, type, trap_id, event, mutate, onClose }) => {
  const { handleSubmit, register, control, formState: { errors } } = useForm({
    resolver: validatorResolver({ notes: notes ? 'required': 'string', time: 'required' }),
    defaultValues: { time: event?.time ? parseISO(event?.time) : new Date(), notes: event?.notes },
  });

  const history_id = event?.history_id;
  const [createTrapEventAction] = useCreateOrUpdateTrapEvent();
  const [deleteTrapEventAction] = useDeleteTrapEvent();

  const deleteModal = useModalDialog();

  async function handleSubmitForm(data) {
     await createTrapEventAction({
      type: type, trap_id, history_id,
      time: formatISO(data.time),
      data: { notes: data.notes },
    });
    mutate();
    onClose();
  }

  async function handleDeleteClick() {
    if (await deleteModal.show({ text: __('Do you want to delete this Event?')})) {
      await deleteTrapEventAction({ history_id });
      mutate();
      onClose({});
    }
  }

  return (
    <Form onSubmit={handleSubmit(handleSubmitForm)} role="form">
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form.Group className="mb-3" controlId="time">
          <Controller name="time" control={control} isInvalid={!!errors.time}
            render={({ field }) => <DatePickerControl {...field} label={__('Time')} showTimeInput dateFormat="Pp" disabled={!!history_id} />}
          />
          <Form.Control.Feedback type="invalid">{__('Wrong date')}</Form.Control.Feedback>
        </Form.Group>

        <Form.Group className="mb-3" controlId="notes">
          <FloatingLabel label={__('Notes')}>
            <Form.Control as="textarea" style={{ height: '6rem' }} name="notes" placeholder={__('Notes')}
              {...register('notes')} isInvalid={!!errors.notes} />
            <Form.Control.Feedback type="invalid">{__('Notes is required')}</Form.Control.Feedback>

          </FloatingLabel>
        </Form.Group>

      </Modal.Body>

      <Modal.Footer>
        {history_id && <Button variant="danger" onClick={handleDeleteClick}>{__('Delete Event')}</Button>}
        <div className="flex-fill" />
        <Button variant="secondary" onClick={() => onClose()}>{__('Cancel')}</Button>
        <Button variant="primary" type="submit">{__('Save Changes')}</Button>
      </Modal.Footer>

      <ConfirmModalDialog {...deleteModal.register()} />
    </Form>
  )
}, { size: 'lg' })

import { useRef, useEffect, useCallback } from 'react';
import useSWROrg from 'swr';

function defaultValue(useSWRNext) {
  return (key, fetcher, config) => {
    const swr = useSWRNext(key, fetcher, config);
    const dataOrLaggyData = swr.data ?? config.defaultValue;
    return Object.assign({}, swr, { data: dataOrLaggyData });
  }
}

function laggy(useSWRNext) {
  return (key, fetcher, config) => {
    const laggyDataRef = useRef();
    const swr = useSWRNext(key, fetcher, config);

    // useEffect(() => {
    if (!config.disableLaggy && swr.data !== undefined) {
      laggyDataRef.current = swr.data
    }
    // }, [swr.data]);

    const resetLaggy = useCallback(() => {
      laggyDataRef.current = undefined;
    }, [])

    const dataOrLaggyData = swr.data === undefined ? laggyDataRef.current : swr.data
    const isLagging = swr.data === undefined && laggyDataRef.current !== undefined

    return Object.assign({}, swr, {
      data: dataOrLaggyData,
      isLagging,
      resetLaggy,
    })
  }
}


export function useSWR(key, fetcher, options) {

  return useSWROrg(key, fetcher, { revalidateOnFocus: false, ...options, use: [laggy, defaultValue] });

}
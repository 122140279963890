import { HashRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { PrivateRoute } from 'src/components/base/PrivateRoute';
import { ErrorPage } from 'src/components/base/ErrorPage';

import { Layout } from './components/layout/Layout';

import { Register } from './components/User/Register';
import { ActivateUser } from './components/User/ActivateUser';

import { Settings } from './components/Settings';
import { ResetPassword } from './components/User/ResetPassword';
import { ChangePassword } from './components/User/ChangePassword';

import { Trap } from './components/Trap';
import { TrapsMap } from './components/TrapsMap/TrapsMap';
import { Catches } from './components/Catches/Catches';
import { Help } from './components/Help';

import Groups from './components/Groups';
import UsersContainer from 'src/components/Users';
import { StoreProvider } from 'src/stores';

import 'assets/styles/app.scss';

const stripePromise = loadStripe(config.stripe.apiKey);

export const App = () => {
  // const blackTheme = _.get(this.props, 'auth.email', '').endsWith('terminixuk.com');
  return (
    <StoreProvider>
      <Elements stripe={stripePromise} locale={config.lang}>
        <Router>
          <Layout>
            <Switch>
              <PrivateRoute exact path="/" component={Groups} />
              <PrivateRoute path="/traps" component={Groups} />
              <PrivateRoute path="/groups" component={Groups} />
              <PrivateRoute path="/trap" component={Trap} />

              <PrivateRoute path="/users" component={UsersContainer} />
              <PrivateRoute path="/map" component={TrapsMap} />
              <PrivateRoute path="/catches" component={Catches} />
              <PrivateRoute path="/settings" component={Settings} />

              <Route path="/register" component={Register} />
              <Route path="/reset-password" component={ResetPassword} />
              <Route path="/activate-invitation" component={ChangePassword} />
              <Route path="/change-password" component={ChangePassword} />
              <Route path="/activate" component={ActivateUser} />
              <Route path="/activate-email" component={ActivateUser} />
              <Route path='/loginas' component={ActivateUser} />

              <PrivateRoute path='/help' component={Help} />
              <Route path="/404" render={() => <ErrorPage code={404} />} />
              <Redirect to="/404" />
            </Switch>
          </Layout>
        </Router>
      </Elements>
    </StoreProvider>
  )
}
import { Col, Row, Form, FloatingLabel } from 'react-bootstrap';
import { useForm, Controller } from 'react-hook-form';
import { PhoneInput } from 'src/components/base/PhoneInput';
import { CountrySelector } from 'src/components/base/CountrySelector';
import { validatorResolver } from 'src/utils/validator';
import { useUpdateEffect } from 'src/utils/hooks';
import classes from './BillingAddress.module.scss';

const CLIENT_TYPE_PRIVATE = 'private';
const CLIENT_TYPE_COMPANY = 'company';

export const BillingAddress = ({ registerNext, data: { billing_details } }) => {
  const { handleSubmit, register, watch, control, setValue, formState: { errors } } = useForm({
    resolver: validatorResolver({
      name: 'required', phone: ['phone', 'required'],
      address_line1: ['string', 'required'], address_line2: ['string'],
      city: ['string', 'required'], country: ['string', 'required'],
      customer_type: ['required', { one_of: [CLIENT_TYPE_PRIVATE, CLIENT_TYPE_COMPANY] }],
      vat: { required_if: { customer_type: CLIENT_TYPE_COMPANY } },
    }),
    defaultValues: {
      ...billing_details,
      country: billing_details?.address?.country ?? '', city: billing_details?.address?.city ?? '',
      address_line1: billing_details?.address?.line1, address_line2: billing_details?.address?.line2,
      customer_type: billing_details?.customer_type ?? '',
    }
  });

  registerNext(submit => handleSubmit((data) => {
    const { name, phone, address_line1: line1, address_line2: line2, city, country, customer_type, vat } = data;
    submit({ name, phone, address: { line1, line2, city, country }, customer_type, vat });
  })());

  const customerType = watch('customer_type');
  useUpdateEffect(() => { if (customerType !== CLIENT_TYPE_COMPANY) { setValue('vat', '') } }, [customerType]);

  return (
    <Form>
      <Row>
        <Col md={12}>
          <p>{__('Please fill in or edit your billing information in the form below and confirm it, to save it to your account.')}</p>

          <Row>
            <Form.Group as={Col} className="mb-3" controlId="name">
              <FloatingLabel label={__('Full name or company name')}>
                <Form.Control type="text" name="name" placeholder={__('Full name or company name')}
                  {...register('name')} isInvalid={!!errors.name}
                />
                <Form.Control.Feedback type="invalid">{__('Please enter your name or company name')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="phone">
              <Controller name="phone" control={control}
                render={({ field }) => <PhoneInput {...field} className={classes.phoneContainer} isInvalid={!!errors.phone} />}
              />
              <Form.Control.Feedback type="invalid">{__('Please enter a valid phone number')}</Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" controlId="address_line1">
              <FloatingLabel label={__('Street and number')}>
                <Form.Control type="text" name="address_line1" placeholder={__('Street and number')}
                  {...register('address_line1')} isInvalid={!!errors.address_line1}
                />
                <Form.Control.Feedback type="invalid">{__('Please enter your street and number')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="address_line2">
              <FloatingLabel label={__('Additional address information')}>
                <Form.Control type="text" name="address_line2" placeholder={__('Additional address information')}
                  {...register('address_line2')} isInvalid={!!errors.address_line2}
                />
              </FloatingLabel>
            </Form.Group>
          </Row>

          <Row>
            <Form.Group as={Col} className="mb-3" controlId="city">
              <FloatingLabel label={__('City')}>
                <Form.Control type="text" name="city" placeholder={__('City')}
                  {...register('city')} isInvalid={!!errors.city}
                />
                <Form.Control.Feedback type="invalid">{__('Please enter your city')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="country">
              <FloatingLabel label={__('Country')}>
                <Controller name="country" control={control}
                  render={({ field }) => <CountrySelector {...field} isInvalid={!!errors.country} />}
                />
                <Form.Control.Feedback type="invalid">{__('Please select a country')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>

          <Row >
            <Form.Group as={Col} className="mb-3" controlId="customer_type">
              <FloatingLabel label={__('Customer Type')}>
                <Form.Select aria-label={__('Customer Type')} {...register('customer_type')} isInvalid={!!errors.customer_type}>
                  <option value="" disabled>{__('Select customer type')}</option>
                  <option value={CLIENT_TYPE_PRIVATE}>{__('Private')}</option>
                  <option value={CLIENT_TYPE_COMPANY}>{__('Company')}</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{__('Please choose a customer type, so that we can calculate your taxes correctly')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>

            <Form.Group as={Col} className="mb-3" controlId="vat">
              <FloatingLabel label={__('VAT number')}>
                <Form.Control type="text" name="vat" placeholder={__('VAT number')} disabled={customerType !== CLIENT_TYPE_COMPANY}
                  {...register('vat')} isInvalid={!!errors.vat}
                />
                <Form.Control.Feedback type="invalid">{__('The entered VAT number is invalid. Please check your information')}</Form.Control.Feedback>
              </FloatingLabel>
            </Form.Group>
          </Row>
        </Col>
      </Row>
    </Form>
  )
}

import { useState, useMemo, useCallback } from 'react';
import { Form } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';

import { TooltipIcon } from 'src/components/base/Tooltip';
import { PriceCell } from './Elements';

import { SubscriptionStatus } from 'src/components/Payment/Elements';
import { useFetchSubscriptions } from 'src/model/subscriptions';
import { formatFromISO } from 'src/utils/datefns';
import { useEffectState } from 'src/utils/hooks';

import { PAYMENT_TYPE_BANK, PAYMENT_TYPE_VBAN, PAYMENT_STATUS_CHARGEABLE } from 'src/constants';

const ITEMS_PER_PAGE = 20;

export const SubscriptionItems = ({ registerNext }) => {
  const [pageIndex, setPageIndex] = useState(0);
  const [sortBy, setSortBy] = useState({});

  const { data } = useFetchSubscriptions({ pageIndex: 0, sortBy: {}, pageSize: 10000, is_payment_required: true });
  const [selectedRowIds, setSelectedRowIds] = useEffectState(() => Object.fromEntries(data.items.map(({ id, payment_plan_parameters }) => [id, payment_plan_parameters?.cost])), [data]);
  const totals = useMemo(() => `${Object.values(selectedRowIds).reduce((total, value) => total + value, 0)} EUR`, [selectedRowIds]);

  const currentData = useMemo(() => ({
    items: data.items.slice(pageIndex * ITEMS_PER_PAGE, pageIndex * ITEMS_PER_PAGE + ITEMS_PER_PAGE),
    pageCount: Math.ceil(data.items.length / ITEMS_PER_PAGE),
  }), [data, pageIndex]);

  function handleFetchData({ pageIndex, sortBy }) {
    setPageIndex(pageIndex);
    setSortBy(sortBy);
  }

  registerNext((submit) => {
    if (data.payment?.status === PAYMENT_STATUS_CHARGEABLE) { return submit({ status: data.payment?.status }) }

    const ids = Object.keys(selectedRowIds);
    ids.length && submit({ subscriptions: ids.map(item => +item) })
  });

  const columns = useMemo(
    () => [
      {
        Header: __('Selected'),
        accessor: 'id',
        Cell: ({ row }) => <Form.Check type='checkbox' {...row.getToggleRowSelectedProps()} indeterminate="false" />
      },
      {
        Header: __('Trap'),
        accessor: 'name',
      },
      {
        Header: __('CCID'),
        accessor: 'sim_ccid',
        style: { width: 190 },
      },
      {
        Header: __('Expiry Date'),
        headerClassName: 'text-end',
        accessor: 'expired_at',
        Cell: ({ value }) => formatFromISO(value, 'PPP'),
        style: { width: 150, textAlign: 'end' },
      },

      {
        Header: __('Status'),
        Cell: ({ row: { original } }) => <SubscriptionStatus status={original.status} is_paused={original.is_paused} />,
        accessor: 'status',
      },
      {
        Header: __('Price ex. VAT'),
        headerClassName: 'text-end',
        accessor: 'price',
        Cell: ({ row: { original } }) => <PriceCell item={original} />,
        style: { width: 130, textAlign: 'end' },
      },
    ],
    []
  );

  const getRowId = useCallback(({ id }) => id, [])
  const getRowProps = useCallback(({ toggleRowSelected }) => (
    {
      onClick: () => toggleRowSelected(),
      className: 'cursor-pointer',
    }
  ), []);

  if (data.payment?.status === PAYMENT_STATUS_CHARGEABLE) { return <PaymentWarning payment={data.payment} /> }

  return (
    <>
      <p>
        {__('The SIM cards in the list below have at some point been registered in your MinkPolice devices. Next to each SIM card, you can see which trap is currently using the SIM card. If no trap name is shown, this means that the trap is no longer registered to your profile, or the trap is now using another SIM card.')}<br />
        {__('If you do not wish to pay for a certain SIM card, make sure that the box to the left of the SIM card is unticked.')}
      </p>

      <Table columns={columns} data={currentData.items} getRowProps={getRowProps}
        pageIndex={pageIndex} pageCount={currentData.pageCount} pageSize={ITEMS_PER_PAGE} sortBy={sortBy}
        fetchData={handleFetchData}
        selectedRowIds={selectedRowIds} onSelectedRowsChange={setSelectedRowIds}
        tableOptions={{ getRowId, disableSortBy: true }}
        selectRow={({ payment_plan_parameters }) => payment_plan_parameters?.cost}
      />

      <p>
        <span className='text-muted'>{__('Total (excl. VAT)')}:&nbsp;</span><strong>{totals}</strong>&nbsp;<TooltipIcon>{__('Please note that this amount is calculated without VAT.')}<br />{__('For private users, VAT percentage depends on your country of residence, and it will be calculated for you on the next page. As a company user you can enter your VAT number on the following page in order to use the reverse charge method.')}</TooltipIcon>
      </p>

      <p>{__('In order to pay for a SIM card, please select it in the tick box next to the trap, then click “Next” button in order to proceed to checkout.')}</p>
    </>
  )
}

const PaymentWarning = ({ payment: { type, bank, vban } }) => {
  return (
    <div>
      <p>{__('Your previous payment is still pending. It is not possible to initiate a new payment until the previous payment has either gone through or has been terminated. Please try again later!')}</p>
      {type === PAYMENT_TYPE_BANK && <BankWarning payment_code={bank.payment_code} />}
      {type === PAYMENT_TYPE_VBAN && <VbanWarning payment_details={vban.payment_details} />}
    </div>
  )
}

export const BankWarning = ({ payment_code }) => (
  <>
    <h4>{__('Important payment instruction')}</h4>
    <p>{__('We have received your request to prolong your MinkPolice SIM card(s). Please transfer the outstanding amount onto the following account, so that we can confirm the renewal of your subscription.')}</p>
    <p>{__('The amount to be paid can be found in the invoice, which is attached to an e-mail we have just sent to you.')}</p>
    <p>IBAN: DE44215106001000611661<br />BIC: SYBKDE22</p>

    <p dangerouslySetInnerHTML={{ __html: __('On the bank transfer, please write {code} so we will be able to find your payment.').replace('{code}', `<b>${payment_code}</b>`) }}></p>
  </>
)

export const VbanWarning = ({ payment_details }) => {
  const payment_data = payment_details.financial_addresses[0];
  return (
    <>
    <h4>{__('Important payment instruction')}</h4>
    <p>{__('We have received your request to prolong your MinkPolice SIM card(s). Please transfer the outstanding amount onto the following account, so that we can confirm the renewal of your subscription.')}</p>
    <p>{__('The amount to be paid can be found in the invoice, which is attached to an e-mail we have just sent to you.')}</p>
    <p>IBAN: {payment_data.iban.iban}<br />BIC: {payment_data.iban.bic}</p>
    <p>{__('Supported networks')}: {payment_data.supported_networks.map((item) => (
      <span><b>{item.toUpperCase()}</b></span>
    ))}</p>

    <p dangerouslySetInnerHTML={{ __html: __('On the bank transfer, please use the reference code {code}. It is mandatory.').replace('{code}', `<b>${payment_details.reference}</b>`) }}></p>
    </>
  )
}

import { useMemo } from 'react';
import { fetchInvoices, requestInvoice } from 'src/api';

import { useSWR } from 'src/utils/swr';
import { useAction } from 'src/hooks/action';
import { useSystemStore } from 'src/stores';

export function useFetchInvoices({ pageIndex, pageSize = ITEMS_PER_PAGE }) {
  const { token } = useSystemStore();
  const defaultValue = useMemo(() => ({ items: [], pageCount: 0 }), []);

  return useSWR(['/invoices', pageIndex, pageSize],
    () => fetchInvoices({ pageIndex, pageSize }, token),
    { defaultValue }
  );
}

export function useRequestInvoice() {
  const { token } = useSystemStore();
  return useAction(data => requestInvoice(data, token));
}
import { systemStore } from 'src/stores';

export const TOAST_WARNING = 'warning';
export const TOAST_INFO = 'info';

export function toastError(message) {
  systemStore.setToast({ message, variant: TOAST_WARNING });
}

export function toastSuccess(message) {
  systemStore.setToast({ message, variant: TOAST_INFO });
}
import { useLocation, useHistory } from 'react-router-dom';
import { parseQueryString } from 'src/utils/helpers';
import { toastSuccess } from 'src/utils/toast';
import { useActivateUser } from 'src/model/user';

const MESSAGES = {
  '/activate': __('Email address has successfully been validated.'),
  '/activate-email': __('Email address has successfully been validated.'),
};

export const ActivateUser = () => {
  const history = useHistory();
  const { pathname, search = {} } = useLocation();
  const { key, redirectTo = '/' } = parseQueryString(search);

  const success = useActivateUser(key, pathname);
  if (success) {
    MESSAGES[pathname] && toastSuccess(MESSAGES[pathname]);
    history.replace(redirectTo);
  }

  return (
    <div className="d-flex justify-content-center align-items-center flex-fill">
      <h2>{__('Confirmation')}...</h2>
    </div>
  )
}
import { useRef } from 'react';
import { useSystemStore } from 'src/stores';
import { Button } from 'react-bootstrap';

export const SubmitAction = ({ action, target, params = [], children, ...props }) => {
  const formRef = useRef();
  const { token } = useSystemStore();

  return (
    <Button type="submit" onClick={() => formRef.current.submit()} {...props}>
      {children}

      <form ref={formRef} action={action} target={target} method="post">
        <input type="hidden" name="Authorization" value={`Bearer ${token}`} />
        <input type="hidden" name="DomainID" value={config.api.domainId} />
        <input type="hidden" name="language" value={config.lang} />
        {Object.entries(params).map(([name, value]) => <input type="hidden" key={name} name={name} value={value} />)}
      </form>
    </Button>
  )
}
import { useState, useEffect, createContext, useContext } from 'react';
import { reaction } from 'mobx'
import localForage from 'localforage';
import { systemStore } from './systemStore';

const TOKEN_KEY = 'token_v1';
const storeContext = createContext();

export function StoreProvider({ children }) {
  const store = useInitializeStore();
  if (!store) { return null }

  return <storeContext.Provider value={store}>{children}</storeContext.Provider>
}

export function useSystemStore() {
  const store = useContext(storeContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('You have forgot to use StoreProvider, shame on you.')
  }
  return store;
}

export { systemStore };

function useInitializeStore() {
  const [store, setStore] = useState();

  useEffect(() => {
    localForage.getItem(TOKEN_KEY).then(token => systemStore.initilize(token))
      .catch(() => localForage.removeItem(TOKEN_KEY))
      .finally(() => setStore(systemStore));
  }, []);

  return store;
}

reaction(() => systemStore.token, async (token) => {
  await localForage.setItem(TOKEN_KEY, token);
});

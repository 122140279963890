import { Children } from 'react';
import { Col, Form, Button, Tab } from 'react-bootstrap';
import { useModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { LargeContainer, SimpleHeader, Tabs } from 'src/components/base/Elements';
import classes from './SettingsForm.module.scss';

export const SettingsForm = ({ title, onSubmit, onCancel, onDelete, submitTitle = __('Save Changes'), deleteTitle = __('Delete'), hideActions, children, ...props }) => {
  const modal = useModalDialog();

  const key = index => `index_${index}`;
  const items = Children.toArray(children);

  async function handleOnDelete() {
    onDelete(modal);
  }

  return (
    <LargeContainer className="pt-5 pb-5">
      <Col md={{ span: 8, offset: 2 }}>
        <Form onSubmit={onSubmit} role="form" {...props}>
          <div>
            <SimpleHeader className="pb-4">{title}</SimpleHeader>

            <Tab.Container defaultActiveKey={key(0)}>
              {items.length > 1 && <Tabs className="pb-4" items={items.map(({ props: { title, isInvalid } }, index) => ({ title, isInvalid, eventKey: key(index) }))} />}

              <Tab.Content className="pb-4">
                {items.map((item, index) => (<Tab.Pane key={key(index)} eventKey={key(index)}>{item}</Tab.Pane>))}
              </Tab.Content>
            </Tab.Container>

          </div>

          {!hideActions &&
            <div className={classes.footer}>
              {onDelete && <Button variant="outline-danger" onClick={handleOnDelete}>{deleteTitle}</Button>}
              <div className={classes.right}>
                {onCancel && <Button variant="outline-primary" onClick={() => onCancel()}>{__('Cancel')}</Button>}
                {<Button type="submit">{submitTitle}</Button>}
              </div>
            </div>
          }

          <ConfirmModalDialog {...modal.register()} />
        </Form>
      </Col>
    </LargeContainer>
  )
}

SettingsForm.Tab = ({ children }) => <div>{children}</div>
SettingsForm.Tab.defaultProps = {
  __TYPE: 'Tab',
};

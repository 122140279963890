export const OTHER_ANIMAL = 'other';

export const TRAP_MODULE = 'module';
export const TRAP_SIMPLE = 'simple';
export const TRAP_TILT = 'tilt';

export const SERIAL_MIN_LENGTH = 5;
export const PASSWORD_MIN_LENGTH = 6;

export const ITEMS_PER_PAGE = 25;

export const PAYMENT_TYPE_BANK = 'bank';
export const PAYMENT_TYPE_CARD = 'card';
export const PAYMENT_TYPE_SEPA = 'sepa_debit';
export const PAYMENT_TYPE_SOFORT = 'sofort';
export const PAYMENT_TYPE_VBAN = 'vban';

export const PAYMENT_STATUS_CHARGEABLE = 'chargeable';

export const BATTERY_DEFAULT = 'default';

export const SIM_ACTIVE = 'active';
export const SIM_EXPIRING = 'expiring';
export const SIM_BLOCKED = 'blocked';
export const SIM_TERMINATED = 'terminated';

import { useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';
import { LargeContainer, SimpleHeader } from 'src/components/base/Elements';
import { SubmitAction } from 'src/components/base/SubmitAction';
import { useModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { useFetchLocation } from 'src/utils/hooks';
import { formatFromISO } from 'src/utils/datefns';
import { useWorkspace } from 'src/model/workspaces';
import { useFetchCatches, useDeleteCatch } from 'src/model/catches';

import classes from './Catches.module.scss';

const PAGE_SIZE = 50;
const AGES = Object.fromEntries(config.catch.ages.map(({ type, title }) => [type.toLowerCase(), title]));
const GENDERS = Object.fromEntries(config.catch.genders.map(({ type, title }) => [type.toLowerCase(), title]));

export const Catches = () => {
  const workspace = useWorkspace();
  const animals = useMemo(() => Object.fromEntries((workspace.animals ?? []).map(({ type, title }) => [type.toLowerCase(), title])), [workspace.animals]);

  const modal = useModalDialog();
  const { pageIndex, sortBy, setFetchLocation } = useFetchLocation();

  const { data, mutate } = useFetchCatches({ pageIndex, sortBy });
  const [deleteCatchAction] = useDeleteCatch(mutate);

  function handleFetchData({ pageIndex, sortBy }) {
    setFetchLocation({ pageIndex, sortBy });
  }

  async function handleDeleteItem({ catch_id, trap_name }) {
    const text = <span>{__('Are you sure you want to delete')} <b>{trap_name}</b>?</span>;
    if (await modal.show({ text })) {
      deleteCatchAction({ catch_id });
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: __('Trap'),
        accessor: 'trap_name',
        className: 'fw-bold',
        style: { width: '15%' },
      },
      {
        Header: __('Type'),
        accessor: 'trap_type',
        style: { width: '10%' },
      },
      {
        Header: __('Date/Time'),
        accessor: 'time',
        Cell: ({ value }) => formatFromISO(value, 'Pp'),
        style: { width: '10%' },
      },
      {
        Header: __('Animal'),
        accessor: 'animal',
        Cell: ({ value }) => animals[value] ?? value,
        style: { width: '10%' },
      },
      {
        Header: __('Age'),
        accessor: 'age',
        Cell: ({ value }) => AGES[value] ?? value,
        style: { width: '10%' },
      },
      {
        Header: __('Sex'),
        accessor: 'sex',
        Cell: ({ value }) => GENDERS[value] ?? value,
        style: { width: '10%' },
      },
      {
        Header: __('Bait'),
        accessor: 'bait',
        style: { width: '10%' },
      },
      {
        Header: __('Location'),
        accessor: 'coordinates',
        style: { width: '10%' },
      },
      {
        Header: __('Comment'),
        accessor: 'notes',
        Cell: ({ row: { original } }) => <LastCell item={original} onDelete={handleDeleteItem} />,
        style: { width: '15%', position: 'relative' },
      },
    ],
    []
  )

  return (
    <LargeContainer className={classes.root}>
      <div className="mt-4 mb-5 d-flex justify-content-between align-items-baseline">
        <SimpleHeader>{__('Catches')}</SimpleHeader>
        <SubmitAction action={`${config.api.serverUrl}/exportcatches`}>{__('Export All to CSV')}</SubmitAction>
      </div>

      <Table columns={columns} data={data.items}
        pageIndex={pageIndex} pageCount={data.pageCount} pageSize={PAGE_SIZE} sortBy={sortBy}
        fetchData={handleFetchData}
      />

      <ConfirmModalDialog {...modal.register()} />
    </LargeContainer>
  )
}

const LastCell = ({ item, onDelete }) => (
  <>
    <span>{item.notes}</span>
    <Button className={classes.deleteButton} variant="danger" onClick={() => onDelete(item)}>{__('Delete')}</Button>
  </>
);
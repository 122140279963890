import { useMemo, useCallback } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { Table } from 'src/components/base/Table';
import { Pane } from 'src/components/base/Elements';
import { useModalDialog, ConfirmModalDialog } from 'src/components/base/ModalDialog';
import { useCurrentWorkspace, useFetchWorkspaces, useConfirmWorkspace, useLeaveWorkspace } from 'src/model/workspaces';

const ROLES = Object.fromEntries(config.roles.map(({ role, title }) => [role.toLowerCase(), title]));

export const Workspaces = () => {
  const workspaces = useFetchWorkspaces();
  const { setCurrentWorkspace } = useCurrentWorkspace();
  const [confirmWorkspaceAction] = useConfirmWorkspace();
  const [leaveWorkspaceAction] = useLeaveWorkspace();

  const modal = useModalDialog();

  function handleViewAsClick({ workspace_id }) {
    setCurrentWorkspace(workspace_id);
  }

  async function handleActionClick({ workspace_id, is_confirmed }) {
    const text = is_confirmed ? __('Are you sure you want to leave workspace?') : __('Are you sure you want to confirm the invitation?');
    const actionTitle = is_confirmed ? __('Leave') : __('Confirm');
    const variant = is_confirmed ? 'danger' : 'primary';

    if (await modal.show({ text, actionTitle, variant })) {
      await (is_confirmed ? leaveWorkspaceAction({ workspace_id }) : confirmWorkspaceAction({ workspace_id, is_confirmed: true }));
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: __('Group Owner'),
        accessor: 'owner_name',
      },
      {
        Header: __('Email'),
        accessor: 'owner_email',
      },
      {
        Header: __('Role'),
        accessor: 'role',
        Cell: ({ value }) => ROLES[value] ?? value,
      },
      {
        Header: __('Status'),
        accessor: 'is_confirmed',
        Cell: ({ value }) => value ? __('Confirmed') : __('Pending'),
      },
      {
        Header: __('Action'),
        headerClassName: 'text-end',
        Cell: ({ row: { original } }) => <ActionCell item={original} onViewAsClick={handleViewAsClick} onActionClick={handleActionClick} />,
        style: { width: '15%', textAlign: 'end' },
      },
    ],
    []
  )

  const getRowId = useCallback(({ workspace_id }) => workspace_id, [])

  return (
    <Row>
      <Col cx={'auto'}>
        <Pane className="mb-3">{__('Below is a list of users who have invited you to see or edit their trap groups.')}</Pane>

        <Table columns={columns} data={workspaces} tableOptions={{ getRowId, disableSortBy: true }} pageIndex={0} pageCount={1} />
      </Col>
      <ConfirmModalDialog {...modal.register()} />
    </Row>
  )
}

const ActionCell = ({ item, onViewAsClick, onActionClick }) => (
  <>
    {MINKS_BRAND === 'trapsensor' && item.is_confirmed &&
      <Button className="me-1" variant="primary" onClick={() => onViewAsClick(item)}>{__('View As')}</Button>
    }
    <Button variant={item.is_confirmed ? 'outline-danger' : 'primary'} onClick={() => onActionClick(item)}>{item.is_confirmed ? __('Leave') : __('Confirm')}</Button>
  </>
)
